(function () {
    var mainServices = window.alleanza.mainServices || {};
  
    mainServices.init = function () {
        if ($(".main-services-swiper-container").length) {
            $('.main-services').each(function (index, element) {
                $(this).addClass('three-' + index);
                $(".three-" + index + " .swiper-pagination").addClass("three-pag-" + index);
                $(".three-" + index + " .swiper-button-next.s9next").addClass("s9next-" + index);
                $(".three-" + index + " .swiper-button-prev.s9prev").addClass("s9prev-" + index);
                $(".three-" + index + " .swiper-button-next.s2next").addClass("s9next-" + index);
                $(".three-" + index + " .swiper-button-prev.s2prev").addClass("s9prev-" + index);
                window.generali.mainServices.mainServicesSwiper = new Swiper(".three-" + index + " .main-services-swiper-container", {
                slidesPerView: 3,
                spaceBetween: 24,
                watchOverflow: true,
                updateOnWindowResize: true,
                centerInsufficientSlides: true,
                observer: true,
                observeParents: true,
                pagination: {
                    el: ".swiper-pagination.three-pag-" + index,
                    dynamicBullets: true,
                    clickable: true,
                },
                navigation: {
                    nextEl: ".swiper-button-next.s9next-"+index,
                    prevEl: ".swiper-button-prev.s9prev-"+index,
                },
                breakpoints: {
                    989: {
                    slidesPerView: 2,
                    },
                    767: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    },
                },
                });        
                var swiperItems = $(".three-" + index + " .swiper-container .swiper-slide");
                var next = $(".swiper--arrow--nav .swiper-button-next.s9next-"+index);
                var prev = $(".swiper--arrow--nav .swiper-button-prev.s9prev-"+index);
                if (swiperItems.length <= 3) {
                    next.addClass("hidden");
                    prev.addClass("hidden");
                }
            }); 
        }
      if ($(".fast-cards-swiper-container").length) {
        $('.main-services').each(function (index, element) {
          $(this).addClass('three-' + index);
          $(".three-" + index + " .swiper-pagination").addClass("three-pag-" + index);
          $(".three-" + index + " .swiper-button-next.s9next").addClass("s9next-" + index);
          $(".three-" + index + " .swiper-button-prev.s9prev").addClass("s9prev-" + index);
          $(".three-" + index + " .swiper-button-next.s2next").addClass("s9next-" + index);
          $(".three-" + index + " .swiper-button-prev.s2prev").addClass("s9prev-" + index);
          window.alleanza.mainServices.mainServicesSwiper = new Swiper(".three-" + index + " .fast-cards-swiper-container", {
            slidesPerView: 3,
            spaceBetween: 24,
            watchOverflow: true,
            updateOnWindowResize: true,
            centerInsufficientSlides: true,
            centeredSlides: window.innerWidth <= 375,
            observer: true,
            observeParents: true,
            pagination: {
              el: ".swiper-pagination.three-pag-" + index,
              dynamicBullets: true,
              clickable: true
            },
            navigation: {
              nextEl: ".swiper-button-next.s9next-" + index,
              prevEl: ".swiper-button-prev.s9prev-" + index
            },
            breakpoints: {
                989: {
                    slidesPerView: 3,
                  },
                  767: {
                    slidesPerView: 2.3,
                  },
                  0: {
                    slidesPerView:"auto",
                    spaceBetween:8,
                  }
            //   989: {
            //     slidesPerView: 3
            //   },
            //   368: {
            //     slidesPerView: 2.3,
            //   },
            //   0: {
            //     slidesPerView: "auto",
            //     spaceBetween: 8
            //   } // 649: {
              //   slidesPerView: "auto",
              //   spaceBetween: 10,
              //   centeredSlides: false,
              // },
              // 578: {
              //   slidesPerView: "auto",
              //   spaceBetween: 10,
              //   centeredSlides: false,
              // },
              // 495: {
              //   slidesPerView: "auto",
              //   spaceBetween: 10,
              //   centeredSlides: false,
              // },
              // 440: {
              //   slidesPerView: "auto",
              //   spaceBetween: 6,
              //   centeredSlides: true,
              // },
              // 374: {
              //   slidesPerView: "auto",
              //   spaceBetween: 6,
              //   centeredSlides: true,
              // },
  
            }
          });
          var swiperItems = $(".three-" + index + " .swiper-container .swiper-slide");
          var next = $(".swiper--arrow--nav .swiper-button-next.s9next-" + index);
          var prev = $(".swiper--arrow--nav .swiper-button-prev.s9prev-" + index);
  
          if (swiperItems.length <= 3) {
            next.addClass("hidden");
            prev.addClass("hidden");
          }
        });
      }
    };
  
    window.alleanza.mainServices = mainServices;
  })();