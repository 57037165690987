(function () {
    // Start Due card visibili in desktop carousel cross selling medium
    $(".slider-medium-card").hide();
    let twoCardsCarousel = window.alleanza.twoCardsCarousel || {};
    twoCardsCarousel.init = () => {
        if ($(".slider-medium-card .swiper-container").length) {
            $('.slider-medium-card ').each(function (index, element) {
                $(this).addClass('two' + index);
                $(".two" + index + " .swiper-pagination").addClass("two-pag-" + index);
                $(".two" + index + " .s3next-m").addClass("s3next-2" + index);
                $(".two" + index + " .s3prev-m").addClass("s3prev-2" + index);
                let swiperItems = $(".two" + index + " .swiper-container .swiper-slide");
                const eigthSectionSwiper = new Swiper(".two" + index + " .swiper-container", {
                    slidesPerView: "auto",
                    spaceBetween: 12,
                    centeredSlides: window.innerWidth <= 375,
                    watchOverflow: true,
                    observer: true,
                    updateOnWindowResize: true,
                    centerInsufficientSlides: true,
                    observeParents: true,
                    speed: 1000,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".s3next-m.s3next-2" + index,
                        prevEl: ".s3prev-m.s3prev-2" + index,
                    },
                    breakpoints: {
                        1439: {
                            slidesPerView: 2,
                            spaceBetween: 25,
                            centeredSlides: false,
                        },
                        989: {
                            slidesPerView: 2,
                            spaceBetween: 26, //12 Zeplin
                            centeredSlides: false,
                        },
                        767: {
                            slidesPerView: "auto",
                            spaceBetween: 36,
                            centeredSlides: false,
                        },

                        374: {
                            slidesPerView: 'auto',
                            spaceBetween: 12,
                            centeredSlides: true,
                        },
                    },
                });

                // SPGI-1314 - Aggiunto if per nascondere le freccie del carosello
                var next = $(".slider-medium-card.two" + index + " .swiper--arrow--nav .s3next-m.s3next-2" + index)
                var prev = $(".slider-medium-card.two" + index + " .swiper--arrow--nav .s3prev-m.s3prev-2" + index)
                if (swiperItems.length != 0 && swiperItems.length <= 2) {
                    next.addClass("hidden");
                    prev.addClass("hidden");
                }
            });
        }
        $(".slider-medium-card").show();
    };
    // End Due card visibili in desktop carousel cross selling medium

    window.alleanza.twoCardsCarousel = twoCardsCarousel;
})();
