// Start Module: alleanza YT
(function () {
    let alleanzaYouTube = window.alleanza.alleanzaYouTube || {};

    alleanzaYouTube.init = () => {
        if ($(".player-wrap-youtube").length) {
            let tag = document.createElement('script');
            // YT STATES: unstartedVideo = -1; endedVideo = 0; playingVideo = 1; pausedVideo = 2; bufferingVideo = 3; cuedVideo = 5;

            tag.src = "https://www.youtube.com/iframe_api";
            let firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            window.onYouTubeIframeAPIReady = function () {
                $(".player-wrap-youtube").each(function () {
                    let isBannerRound = $(".hero-banner-round").find(this).length == 1;
                    let isVerticalAccordion = $(".vertical-accordion").find(this).length == 1;
                    let playerId = $(this).find(".youtubePlayer").attr("id");
                    let videoId = $(this).find(".youtubePlayer").attr("data-video");
                    let player = new YT.Player(playerId, {
                        videoId: videoId,
                        playerVars: {
                            modestbranding: true,
                            rel: 0,
                            iv_load_policy: 3,
                            cc_load_policy: 1,
                        },
                        events: {
                            'onReady': onPlayerReady,
                            // 'onPlaybackQualityChange': onPlayerPlaybackQualityChange,
                            'onStateChange': onPlayerStateChange,
                            'onError': onPlayerError
                        }
                    });
                    
                    // quando il player è pronto
                    function onPlayerReady(event) {
                        event.target.setVolume(100);
                        if (event.data == YT.PlayerState.BUFFERING) {
                            event.target.setPlaybackQuality('hd720');
                        }
                    };

                    // carousel media: se cambio card il video va in pause
                    window.alleanza.carouselsMedia.forEach(function (element) {
                        element.on("slideChange", function () {
                            try {
                                player.pauseVideo();
                            } catch (err) {
                                // console.log("Catch:", err);
                            }
                        });
                    });

                    // stop video a cambio card vertical accordion
                    $('.vertical-accordion .va-header').on('click', function () {
                        try {
                            player.pauseVideo();
                        } catch (err) {
                            // console.log("Catch:", err);
                        }
                    });

                    $(".vertical-accordion .player-img").on("click", function () {
                        let video = $(this).closest(".vertical-accordion").find(".player-video");
                        video.removeClass("hidden");
                        $(this).addClass("hidden");
                        player.playVideo();
                    });

                    // sollevato errore del player
                    function onPlayerError(event) {
                        let code = event.data + "";
                        switch (code) {
                            case "2":
                                console.log("Error code: " + code, "- The request contains an invalid parameter value. For example, this error occurs if you specify a video ID that does not have 11 characters, or if the video ID contains invalid characters, such as exclamation points or asterisks.");
                                break;
                            case "5":
                                console.log("Error code: " + code, "- The requested content cannot be played in an HTML5 player or another error related to the HTML5 player has occurred.");
                                break;
                            case "100":
                                console.log("Error code: " + code, "- The video requested was not found. This error occurs when a video has been removed (for any reason) or has been marked as private.");
                                break;
                            case "101":
                                console.log("Error code: " + code, "- The owner of the requested video does not allow it to be played in embedded players.");
                                break;
                            case "150":
                                console.log("Error code: " + code, "- This error is the same as 101. It's just a 101 error in disguise! Error 101: The owner of the requested video does not allow it to be played in embedded players.");
                                break;
                            default:
                                console.log("Error code: " + code, "- Error: YouTube player.")
                        }
                    }

                    // quando lo stato del video cambia
                    function onPlayerStateChange(event) {
                        let stateFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
                        if (isBannerRound) {
                            // if (event.data == YT.PlayerState.PLAYING && !firstPlay && !stateFullScreen) {
                            if (event.data == YT.PlayerState.PLAYING && !stateFullScreen) {
                                player.setVolume(100);
                                onFullScreen();
                                // } else if (event.data == YT.PlayerState.PAUSED && firstPlay) {
                            } else if (event.data == YT.PlayerState.PAUSED) {
                                // firstPlay = false;
                                // if (!stateFullScreen) {
                                //     onFullScreen();
                                //     player.setVolume(100);
                                //     player.playVideo();
                                // }
                            }
                        }
                        if (isVerticalAccordion) {
                            if (event.data == YT.PlayerState.PLAYING) {
                                player.setVolume(100);
                                onFullScreen();
                            } else if (event.data == YT.PlayerState.PAUSED) {
                                let content = event.target.f.closest(".va-hidden-content");
                                $(content).find(".player-video").addClass("hidden");
                                $(content).find(".player-img").removeClass("hidden");
                            }
                        }
                    };

                    // stop video se fuori inquadratura
                    $(window).on("scroll", function () {
                        let top_of_element = $(".player-wrap-youtube #" + playerId).offset().top;
                        let bottom_of_element = $(".player-wrap-youtube #" + playerId).offset().top + $(".player-wrap-youtube").outerHeight();
                        let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                        let top_of_screen = $(window).scrollTop();

                        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                            // Vedo
                            // if (isBannerRound && firstPlay) {
                            //     try {
                            //         player.setVolume(0);
                            //         player.playVideo();
                            //     } catch (err) {
                            //         // console.log(err)
                            //     }
                            // }
                        } else {
                            // Non vedo
                            try {
                                player.pauseVideo();
                            } catch (err) {
                                // console.log(err)
                            }
                        }
                    });

                    // video in fullscreen
                    function onFullScreen(id) {
                        let e;
                        if (!id) {
                            e = document.getElementById(playerId);
                        } else {
                            e = document.getElementById(id);
                        }
                        if (e.requestFullscreen) {
                            e.requestFullscreen();
                        } else if (e.webkitRequestFullscreen) {
                            e.webkitRequestFullscreen();
                        } else if (e.mozRequestFullScreen) {
                            e.mozRequestFullScreen();
                        } else if (e.msRequestFullscreen) {
                            e.msRequestFullscreen();
                        }
                    }

                    // se il video dell'hero banner esce da fullscreen va in pausa
                    $(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function (e) {
                        if (isBannerRound || isVerticalAccordion) {
                            let stateFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
                            if (!stateFullScreen) {
                                player.pauseVideo();
                            }
                        }
                    });
                });
            };
        };
    };

    window.alleanza.alleanzaYouTube = alleanzaYouTube;
})();
