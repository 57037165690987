(function () {
    // Start tre card visibili in desktop Carousel cross selling small
    $('.slider-card-small ').hide();
    let smallCardCarousel = window.alleanza.smallCardCarousel || {};
    smallCardCarousel.init = () => {
        setTimeout(function(){
            if ($(".thank-you-page-pip .slider-card-small .swiper-container").length) {
                $('.thank-you-page-pip .slider-card-small ').each(function (index, element) {
                    $(this).addClass('three' + index);
                    $(".thank-you-page-pip .three" + index + " .swiper-pagination").addClass("three-pag-" + index);
                    $(".thank-you-page-pip .three" + index + " .s3next-s").addClass("s3next-3" + index);
                    $(".thank-you-page-pip .three" + index + " .s3prev-s").addClass("s3prev-3" + index);
                    let swiperItems = $(".thank-you-page-pip .three" + index + " .swiper-container .swiper-slide");
                    const eigthSectionSwiper = new Swiper(".thank-you-page-pip .three" + index + " .swiper-container", {
                        slidesPerView: 3,
                        spaceBetween: 16 ,
                        centeredSlides: window.innerWidth <= 375,
                        watchOverflow: true,
                        updateOnWindowResize: true,
                        centerInsufficientSlides: true,
                        observer: true,
                        observeParents: true,
                        speed: 1000,
                        pagination: {
                            el: ".swiper-pagination.three-pag-" + index,
                            clickable: true,
                        },
                        navigation: {
                            nextEl: ".s3next-s.s3next-3" + index,
                            prevEl: ".s3prev-s.s3prev-3" + index,
                        },
                        breakpoints: {
                            1439: {
                                slidesPerView: 3,
                                spaceBetween: 16, //34,
                                centeredSlides: false,
                            },
                            989: {
                                slidesPerView: 3,
                                spaceBetween: 22,
                                centeredSlides: false,
                            },
                            767: {
                                slidesPerView: 3,
                                spaceBetween: 22,
                                centeredSlides: false,
                            },
                            374: {
                                slidesPerView: 1,
                                spaceBetween: 22,
                                centeredSlides: false,
                            },
                        },
                    });
    
                    // SPGI-1314 - Aggiunto if per nascondere le freccie del carosello
                    var next = $(".slider-card-small.three" + index + " .swiper--arrow--nav .s3next-s.s3next-3" + index);
                    var prev = $(".slider-card-small.three" + index + " .swiper--arrow--nav .s3prev-s.s3prev-3" + index);
                    if (swiperItems.length != 0 && swiperItems.length <= 3) {
                        next.addClass("hidden");
                        prev.addClass("hidden");
                    }
                });
            }
    
            if ($(".slider-card-small .swiper-container").length && $(".thank-you-page-pip").length == 0) {
                $('.slider-card-small ').each(function (index, element) {
                    $(this).addClass('three' + index);
                    $(".three" + index + " .swiper-pagination").addClass("three-pag-" + index);
                    $(".three" + index + " .s3next-s").addClass("s3next-3" + index);
                    $(".three" + index + " .s3prev-s").addClass("s3prev-3" + index);
                    let swiperItems = $(".three" + index + " .swiper-container .swiper-slide");
                    const eigthSectionSwiper = new Swiper(".three" + index + " .swiper-container", {
                        slidesPerView: "auto",
                        spaceBetween: 12,
                        centeredSlides: window.innerWidth <= 375,
                        watchOverflow: true,
                        updateOnWindowResize: true,
                        centerInsufficientSlides: true,
                        observer: true,
                        observeParents: true,
                        speed: 1000,
                        pagination: {
                            el: ".swiper-pagination.three-pag-" + index,
                            clickable: true,
                        },
                        navigation: {
                            nextEl: ".s3next-s.s3next-3" + index,
                            prevEl: ".s3prev-s.s3prev-3" + index,
                        },
                        breakpoints: {
                            1439: {
                                slidesPerView: 3,
                                spaceBetween: 24, //34,
                                centeredSlides: false,
                            },
                            989: {
                                slidesPerView: 3,
                                spaceBetween: 22,
                                centeredSlides: false,
                            },
                            767: {
                                slidesPerView: 3,
                                spaceBetween: 22,
                                centeredSlides: false,
                            },
    
                            374: {
                                slidesPerView: 'auto',
                                spaceBetween: 12,
                                centeredSlides: true,
                            },
                        },
                    });
    
                    // SPGI-1314 - Aggiunto if per nascondere le freccie del carosello
                    var next = $(".slider-card-small.three" + index + " .swiper--arrow--nav .s3next-s.s3next-3" + index);
                    var prev = $(".slider-card-small.three" + index + " .swiper--arrow--nav .s3prev-s.s3prev-3" + index);
                    if (swiperItems.length != 0 && swiperItems.length <= 3) {
                        next.addClass("hidden");
                        prev.addClass("hidden");
                    }
                });
            }
            $('.slider-card-small ').show();
        }, 10);
    };
    // End tre card visibili in desktop Carousel cross selling small

    window.alleanza.smallCardCarousel = smallCardCarousel;
})();
