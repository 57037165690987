if (!window.alleanza) window.alleanza = {};
var util = window.alleanza.util || {};
//  Pip step 3
 $(".button-card-pip-step-3").on("click", function(e){
  setTimeout(() => {
    $(this).parents(".alleanza-accordion").find(".card").each(function(){
      if ($(this).find("div").first().attr("aria-expanded") == "true") {
        $(this).find(".toggle-accordion-sign").addClass("open");
      } else {
        $(this).find(".toggle-accordion-sign").removeClass("open");
      }
    })
  }, 20);
 })
 //Pip step 3
//  $(".button-card-pip-step-3").on("click", function(e){
//   if($(this).parent().hasClass("collapsed")){
//     $(this).find(".toggle-accordion-sign").addClass("open");
//   }else{
//     $(this).find(".toggle-accordion-sign").removeClass("open");
//   }
//  })
//New contatta agenzia Input
$(".new-contatta-agenzia .input-container.input-agenzia input").on("keyup",function(e){
  if($(this).val()){
    $(this).parent().find(".clear-link").removeClass("hidden");
  }else{
    $(this).parent().find(".clear-link").addClass("hidden");
  }
})

$(".new-contatta-agenzia .input-container.input-agenzia .clear-link").on("click", function(e){
  e.preventDefault();
  $(this).parent().find("input").val("");
  $(this).addClass("hidden");
  $(this).parent().find("label").css("display", "block");
})


$(".new-contatta-agenzia .input-container input").on("keyup",function(e){
  removeErrorOnKeyUp($(this));
});

function removeErrorOnKeyUp(element){
  element.parents(".alleanza-contatta-agenzie-input-container").removeClass("error");
  element.parents(".alleanza-contatta-agenzie-input-container").find(".wrong-info-2").removeClass("show");
}


$(".new-contatta-agenzia .input-container input").on("focusout",function(e){
  checkControlloNewContattaAgenzia($(this), false, true);
});

$(".new-contatta-agenzia .input-container input").on("keyup",function(e){
  var checkControlli=true;
  $(".new-contatta-agenzia .input-container input:not(#modal-input):not(#modal-input-no-facilities)").each(function(e){
    if (!checkControlloNewContattaAgenzia($(this), true, false)){
      checkControlli = false;
    }
  });
  if (checkControlli){
    $(".new-contatta-agenzia #inviaRichiesta").addClass("btn-alleanza-orange");
  } else {
    $(".new-contatta-agenzia #inviaRichiesta").removeClass("btn-alleanza-orange");
  }
});

function checkControlliNewContattaAgenzia(){
  $(".new-contatta-agenzia .input-container input:not(#modal-input):not(#modal-input-no-facilities)").each(function(e){
    checkControlloNewContattaAgenzia($(this), true, true);
  });
}

function checkControlloNewContattaAgenzia(element, checkblankinput, applyVisualError){
  var checkControlli=true;
  if(checkblankinput || element.parents(".alleanza-contatta-agenzie-input-container.error").length>0){
    if(element.val()==''){
      if (applyVisualError) {
        element.parents(".alleanza-contatta-agenzie-input-container").addClass("error");
      }
      checkControlli=false;
    }else{
      if (applyVisualError) {
        element.parents(".alleanza-contatta-agenzie-input-container").removeClass("error");
      }
    }
  }

  if(element.attr("id")=="mailNewContattaAgenzie"){
    if(checkEmailNewContattaAgenzia(element.val()) || element.val().length == 0){
      if ((element.val().length > 0 || !checkblankinput) && applyVisualError){
        element.parents(".alleanza-contatta-agenzie-input-container").removeClass("error");
      }
      if (applyVisualError) {
        element.parents(".alleanza-contatta-agenzie-input-container").find(".wrong-info-2").removeClass("show");
      }   
    }else{
      if (applyVisualError) {
        element.parents(".alleanza-contatta-agenzie-input-container").addClass("error");
        element.parents(".alleanza-contatta-agenzie-input-container").find(".wrong-info-2").addClass("show");
      }
      checkControlli=false;
    }
  }

  if(element.attr("id")=="cfNewContattaAgenzie"){
    if(checkCfNewContattaAgenzia(element.val()) || element.val().length == 0){
      if ((element.val().length > 0 || !checkblankinput) && applyVisualError ){
        element.parents(".alleanza-contatta-agenzie-input-container").removeClass("error");
      }
      if(applyVisualError){
        element.parents(".alleanza-contatta-agenzie-input-container").find(".wrong-info-2").removeClass("show");
      }      
    }else{
      if(applyVisualError){
        element.parents(".alleanza-contatta-agenzie-input-container").addClass("error");
        element.parents(".alleanza-contatta-agenzie-input-container").find(".wrong-info-2").addClass("show");
      }
      checkControlli=false;
    }
  }
  return checkControlli;
}

function checkEmailNewContattaAgenzia(email){
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return email.match(mailformat)
}

function checkCfNewContattaAgenzia(cf){
  // let cfformat = /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;
  // return cf.match(cfformat)
  return alleanza.validationcf.check(cf);

}

$(".new-contatta-agenzia #inviaRichiesta").on("click", function(e){
  checkControlliNewContattaAgenzia();
});


//Banner-agenzie-input
$(".banner-agenzie-input").on("focusin", function(e) {
  $(this).find("label").css("display", "none");
});

$(".banner-agenzie-input input").on("keyup", function(e){
  if($(this).val()){
    $(this).parent().find(".clear-link").css("display", "block");
  }else{
    $(this).parent().find(".clear-link").css("display", "none");
  }
});
$(".banner-agenzie-input").on("focusout", function(e) {
  if(!$(this).find("input").val()){
    $(this).find("label").css("display", "block");
  }  
});

$(".banner-agenzie-input .clear-link").on("click", function(){
  $(this).parent().find("input").val("");
  $(this).css("display", "none");
  $(this).parent().find("label").css("display", "block");
});

//Form Reclami
$('.formReclami-step-1 .btn--send-email').on('click', function (e) {
  $(".btn--send-email").addClass("hidden");
  $(".assistenza-form-reclamo__step-2").removeClass("hidden");
  $(".reclamo-campi-obbligatori").removeClass("hidden");
});

$('.alleanza-text-input-textarea, textarea').on('focus blur', function (e) {
  $(this).parents('.alleanza-text-input-textarea').toggleClass('is-focused', (e.type === 'focus' || $(this).val().length > 0));
}).trigger('blur');

let datebirth = $('.section-assistenza .birth-date input, .assistenza-form-reclamo .birth-date input');
let placeholder_date = "gg/mm/aaaa"
datebirth.focus(function () {
  datebirth.attr("placeholder", placeholder_date);
});
datebirth.focusout(function () {
  datebirth.attr("placeholder", "")
});

datebirth.on("keyup", function (event) {
  window.alleanza.util.addSlashDate(event);
});

datebirth.on("change", function (e) {
  checkDataNascita($(this));
});

function checkDataNascita(elem) {
  let val = elem.val();
  let RE = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
  let dt = val.split("/");
  let isMandatory = elem.parent().find("label.font-paragraph-3").text().indexOf("*") != -1;
  if(!isMandatory && elem.val()==""){
    $(".birth-date .message").addClass("hidden");
    $(".birth-date .alleanza-text-input").removeClass("validation-error");  
    return true;
  }
  if (!RE.test(val)) {
    $(".birth-date .message").removeClass("hidden");
    $(".birth-date .alleanza-text-input").addClass("validation-error");
    $(".birth-date.alleanza-text-input input").removeClass("is-dirty");
    elem.val("");
    return false;
  } else if (dt[0] > 31) {
    $(".birth-date .message").removeClass("hidden");
    $(".birth-date .alleanza-text-input").addClass("validation-error");
    $(".birth-date.alleanza-text-input input").removeClass("is-dirty");
    elem.val("");
    return false;
  } else if (dt[1] > 12) {
    $(".birth-date .message").removeClass("hidden");
    $(".birth-date .alleanza-text-input").addClass("validation-error");
    $(".birth-date.alleanza-text-input input").removeClass("is-dirty");
    elem.val("");
    return false;
  }
  let birthday = new Date(dt[1] + "/" + dt[0] + "/" + dt[2]);
  let today = new Date();
  let years = (today.getFullYear() - birthday.getFullYear());
  if (today.getMonth() < birthday.getMonth() ||
    today.getMonth() == birthday.getMonth() && today.getDate() < birthday.getDate()) {
    years--;
  }
  if (years < 18 || years > 110) {
    $(".birth-date .message").removeClass("hidden");
    $(".birth-date .alleanza-text-input").addClass("validation-error");
    $(".birth-date.alleanza-text-input input").removeClass("is-dirty");
    elem.val("");
    return false;
  }
  $(".birth-date .message").addClass("hidden");
  $(".birth-date .alleanza-text-input").removeClass("validation-error");
  return true;
}

// Magazine - card list
$(".card-list-s .button-up, .card-list-l .button-up").on("click", function(e){
  window.alleanza.util.scrollToTopPage();
});

$(".card-list-l .button-show").on("click", function(e){
  let fivecards = $('.card-list-l .five--card').not(':visible').filter(':eq(0)');
  fivecards.css('display', 'flex');
  fivecards.removeClass("hide");
  if(!$('.card-list-l .five--card').not(':visible').length > 0){
    $(this).addClass("hidden");
  }
});

$(".card-list-s .button-show").on("click", function(e){
  let tworow = $('.card-list-s .two-rows--card').not(':visible').filter(':eq(0)');
  tworow.css('display', 'flex');
  tworow.removeClass("hide");
  if(!$('.card-list-s .two-rows--card').not(':visible').length > 0){
    $(this).addClass("hidden");
  }
});

// strutture sanitarie
$(".ricerca-strutture-sanitarie .modal-struttura-contatti").scroll(function (e) {
  let height = $(this).scrollTop();
  let modal_name = $(this).closest(".modal-struttura").find(".modal-struttura-name");
  if (height > 0 && !modal_name.hasClass("shadow-scroll")) {
    modal_name.addClass("shadow-scroll");
  } else if (height == 0 && modal_name.hasClass("shadow-scroll")) {
    modal_name.removeClass("shadow-scroll");
  }
});

// Previdenza
$(".alleanza-select-container .customized").on("click", function (e) {
  $(".group-customized").removeClass('hidden');
});

// Sticky container
$(window).on("load", function () {
  if ($(".sticky-container").length > 0) {
    let $sticky = $(".sticky-container");
    // Se ho il fadeTop lo sticky parte nascosto
    if ($sticky.hasClass("fadeTop")) {
      $sticky.hide();
    }
  }
})
$(window).on("scroll", function () {
  if ($(".sticky-container").length > 0) {
    let $sticky = $(".sticky-container");
    let diff = $(document).height() - $(window).height() - 400;
    if ($sticky.hasClass("fadeBottom") && !$sticky.hasClass("fadeTop")) {
      if ($(this).scrollTop() > diff) {
        $sticky.fadeOut(500);
      } else {
        $sticky.fadeIn(500);
      }
    } else if ($sticky.hasClass("fadeTop") && !$sticky.hasClass("fadeBottom")) {
      if ($(this).scrollTop() < 200) {
        $sticky.fadeOut(500);
      } else {
        $sticky.fadeIn(500);
      }
    } else if ($sticky.hasClass("fadeTop") && $sticky.hasClass("fadeBottom")) {
      if ($(this).scrollTop() > diff || $(this).scrollTop() < 200) {
        $sticky.fadeOut(200);
      } else {
        $sticky.fadeIn(300);
      }
    }
  }
})

//Dropdown -- Select - Option
$('select.customSelect').each(function () {
  var list = $('<ul />');


  $(this).find('option').each(function () {
    list.append($('<li />').append($('<a />').text($(this).text())));
  });

  list.insertAfter($(this));
  $("ul").addClass("hidden");

});

$('.selectCustom').on('click', function (e) {
  $("ul").removeClass("hidden");
});

$('.selectCustom ul li a').on('click touch', function (e) {

  $("ul").removeClass("hidden");
  e.preventDefault();
  $(".title").addClass("focused");
  var customSelect = $(this);
  var active = customSelect.hasClass('active');
  var label = active ? customSelect.find('select').attr('placeholder') : $(this).text();
  customSelect.find('option').prop('selected', false);
  customSelect.find('ul li').removeClass('active');

  customSelect.toggleClass('filled', !active);
  $('.response').text(label);

  if (!active) {
    customSelect.find('option:contains(' + $(this).text() + ')').prop('selected', true);
    $(this).parent().addClass('active');
  }

  customSelect.removeClass('open');
  $("ul").addClass("hidden");
});

$('.customSelect > .selectCustom-container').on('click touch', function (e) {
  var self = $(this).parent();
  self.toggleClass('open');
});

$('.customSelect').on('click touch', function (e) {
  var customSelect = $('.customSelect');
  if (customSelect !== e.target && !customSelect.has(e.target).length) {
    customSelect.removeClass('open');
    $("ul").addClass("hidden");
  }
});

//This guid is RFC4122 version 4 compliant
// util.guid = function uuidv4() {
//   return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
//     var r = (Math.random() * 16) | 0,
//       v = c == "x" ? r : (r & 0x3) | 0x8;
//     return v.toString(16);
//   });
// };

// util.getUrlVars = function() {
//   var vars = [],
//     hash;
//   var hashes = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");

//   for (var i = 0; i < hashes.length; i++) {
//     hash = hashes[i].split("=");
//     vars.push(hash[0]);
//     vars[hash[0]] = hash[1];
//   }

//   return vars;
// };


//Radio Button Form Reclami

/* $(".no_option").on("click", function(){ 
   var self = this;
   // get all elements with same name but itself and mark them unchecked
   Array.prototype.filter.call(document.getElementsByName(this.name), function(filterEl) {
     return self !== filterEl;
   }).forEach(function(otherEl) {
     delete otherEl.dataset.check
   })

   // set state based on previous one
   if (this.dataset.hasOwnProperty('check')) {
     this.checked = false
     delete this.dataset.check
   } else {
     this.dataset.check = ''
   }
 }); */


//Accordion Section

$('.accordion-item .section-accordion-title').on('click touch', function (e) {
  if ($(this).find('.accordion-section__icons').find('i').hasClass('icon-alleanza-add')) {
    if ($('.accordion-section').find('.accordion-section__icons').find('i').hasClass('icon-alleanza-remove')) {
      $('.section-accordion--open').addClass('hidden');
      $('.accordion-section__icons').find('i').addClass('icon-alleanza-add');
      $('.accordion-section__icons').find('i').removeClass('icon-alleanza-remove');
      $(".accordion-section__titles").css('font-weight', '400');
    }
   // $(this).find('.section-accordion--open').removeClass('hidden');
    $(this).parent('.accordion-item').find('.section-accordion--open').removeClass('hidden');
    $(this).find('.accordion-section__icons').find('i').addClass('icon-alleanza-remove');
    $(this).find('.accordion-section__icons').find('i').removeClass('icon-alleanza-add');
    $(this).find(".accordion-section__titles").css('font-weight', '700');

  } else if ($('.accordion-section').find('.accordion-section__icons').find('i').hasClass('icon-alleanza-remove')) {
    $('.section-accordion--open').addClass('hidden');
    $('.accordion-section__icons').find('i').addClass('icon-alleanza-add');
    $('.accordion-section__icons').find('i').removeClass('icon-alleanza-remove');
    $(".accordion-section__titles").css('font-weight', '400');
  }
});


util.equalHeight = function (sameHeightEl) {
  if (sameHeightEl) {
    let maxHeight = -1;
    $(sameHeightEl).each(function () {
      $(this).removeAttr("style");
      maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
    });
    $(sameHeightEl).each(function () {
      $(this).height(maxHeight);
    });
  }
};

function truncateText(containerTruncate, parTruncate) {
  var textContainerHeight = $(containerTruncate).height();

  $(parTruncate).each(function () {
    var $ellipsisText = $(this);

    while ($ellipsisText.outerHeight(true) > textContainerHeight) {
      $ellipsisText.text(function (index, text) {
        return text.replace(/\W*\s(\S)*$/, "...");
      });
    }
  });
}

util.selectInput = function () {
  // START alleanza SELECT
  const alleanzaSelectContainer = $(".alleanza-select-container");
  const alleanzaSelect = $(".alleanza-select");
  const alleanzaSelected = $(".selected");
  const alleanzaSelectClear = $(".clear-link");
  const alleanzaSelectDropdown = $(".alleanza-select-dropdown");
  let isIconClicked = false;

  function isAngularSelect(element) {
    return $(element).closest(".angular-custom-select").length > 0 || $(element).hasClass("angular-custom-select");
  }

  function isAngularDate(element) {
    return $(element).closest(".angular-custom-date").length > 0 || $(element).hasClass("angular-custom-date");
  }

  $(document).on("click", function (e) {
    alleanzaSelectContainer.find(alleanzaSelect).each(function () {
      var that = $(this);
      if (isAngularSelect($(e.target)) || isAngularSelect($(that))) {
        return;
      }
      if (!$(e.target).hasClass("icon-alleanza-sinistri-assistenza")) {
        $("[data-toggle='tooltip']").tooltip("hide");
      } // if the target of the click isn't the alleanzaSelect or Dropdown > display none dropdown

      if (!that.is(e.target) && that.has(e.target).length === 0 && !that.closest(alleanzaSelectContainer).find(alleanzaSelectDropdown).is(e.target) && that.closest(alleanzaSelectContainer).find(alleanzaSelectDropdown).has(e.target).length === 0) {
        that.closest(alleanzaSelectContainer).find(alleanzaSelectDropdown).addClass("alleanza-select-dropdown--hidden"); // toggle drop icon
        toggleDropIcon(that);
        toggleBorderSelect(that); // SELECT UNDERLINE removeClass "strong-underline"
        if (that.hasClass("alleanza-select--underline")) {
          that.find(".selected-container").removeClass("strong-underline");
        }
      } // If Click out '.alleanza-select' or Dropdown and '.selected' has val '' > remove class '--focused' to '.alleanza-select' - '.clear-link' fadeOut - toggle drop icon

      if (!that.is(e.target) && that.has(e.target).length === 0 && !that.closest(alleanzaSelectContainer).find(alleanzaSelectDropdown).is(e.target) && that.closest(alleanzaSelectContainer).find(alleanzaSelectDropdown).has(e.target).length === 0 && that.closest(alleanzaSelectContainer).find(alleanzaSelected).val() == "") {
        that.closest(alleanzaSelectContainer).find(alleanzaSelect).removeClass("alleanza-select--focused");
        that.closest(alleanzaSelectContainer).find(alleanzaSelectClear).fadeOut(250);
        toggleDropIcon(that);
      }
    });
  });

  // Click on .alleanza-select > add 'focus' to this - display none dropdown
  alleanzaSelectContainer.find(alleanzaSelect).each(function () {
    const that = $(this);

    ////
    ////
    // START - ADD INITIALIZED CLASS FOR ANGULAR > return if element has class "initialized"
    if (
      $(this)
        .closest(alleanzaSelectContainer)
        .hasClass("initialized")
    ) {
      return;
    }
    $(this)
      .closest(alleanzaSelectContainer)
      .addClass("initialized");
    // END - ADD INITIALIZED CLASS FOR ANGULAR > return if element has class "initialized"
    ////
    ////

    // add '--focused' class if input value are precompiled
    if (
      $(this)
        .find(alleanzaSelected)
        .val() != ""
    ) {
      $(this).addClass("alleanza-select--focused");
    }

    // add tooltip toggle event listener to elements
    $(this)
      .closest(alleanzaSelectContainer)
      .find("[data-toggle='tooltip']")
      .on("click", function () {
        $(alleanzaSelect).each(function (index, element) {
          $(element)
            .not(that)
            .find("[data-toggle='tooltip']")
            .tooltip("hide");
        });
        isIconClicked = true;
        $(this).tooltip("toggle");
      });

    $(this).on("click", function () {
      if (isIconClicked) {
        isIconClicked = false;
        return;
      }
      if (isAngularSelect(this)) {
        return;
      }

      // add '--focused' class to this
      $(this).addClass("alleanza-select--focused");

      // SELECT UNDERLINE addClass "strong-underline"
      if ($(this).hasClass("alleanza-select--underline")) {
        $(this)
          .find(".selected-container")
          .addClass("strong-underline");
      }

      // remove or add '--hidden' to this dropdown
      if (
        !$(this)
          .closest(alleanzaSelectContainer)
          .find(alleanzaSelectDropdown)
          .hasClass("alleanza-select-dropdown--hidden")
      ) {
        $(this)
          .closest(alleanzaSelectContainer)
          .find(alleanzaSelectDropdown)
          .addClass("alleanza-select-dropdown--hidden");
      } else {
        $(this)
          .closest(alleanzaSelectContainer)
          .find(alleanzaSelectDropdown)
          .removeClass("alleanza-select-dropdown--hidden");
      }
      // add '.focus()' to this input
      $(this)
        .closest(alleanzaSelectContainer)
        .not(".not-autocomplete")
        .find(alleanzaSelected)
        .focus();
      $(this)
        .closest(".not-autocomplete")
        .find(alleanzaSelected)
        .focus(function () {
          $(this).blur();
        });
      // alleanzaSelectContainer.find(alleanzaSelectClear).fadeOut(250);

      if ($(this).hasClass("alleanza-select--focused")) {
        $(this)
          .closest(alleanzaSelectContainer)
          .find(alleanzaSelectDropdown)
          .removeClass("alleanza-select-dropdown--hidden");
      }

      // '.selected' input on 'click' > Filtering dropdown items
      var value = $(this)
        .find(alleanzaSelected)
        .val()
        .toLowerCase();
      $(this)
        .closest(alleanzaSelectContainer)
        .not(".not-autocomplete")
        .find(alleanzaSelectDropdown)
        .find("a.dropdown-item")
        .filter(function () {
          $(this).toggle(
            $(this)
              .text()
              .toLowerCase()
              .indexOf(value) > -1
          );
        });

      // toggle drop icon
      toggleDropIcon($(this));
      toggleBorderSelect($(this));
      // add class '.icon-arrow-down' to '.drop'
      changeDropArrowWhenDropdownItemAreHidden();
    });

    // '.selected' input on 'keyup' > Filtering dropdown items
    $(this).on("keyup", function () {
      if (isAngularSelect(this)) {
        return;
      }
      var value = $(this)
        .find(alleanzaSelected)
        .val()
        .toLowerCase();
      $(this)
        .closest(alleanzaSelectContainer)
        .find(alleanzaSelectDropdown)
        .find("a.dropdown-item")
        .filter(function () {
          $(this).toggle(
            $(this)
              .text()
              .toLowerCase()
              .indexOf(value) > -1
          );
        });

      // add class '.icon-arrow-down' to '.drop'
      changeDropArrowWhenDropdownItemAreHidden();
      // show clear 'X' icon
      showAndHideSelectClear($(this));
      // add class '--focused' to alleanzaSelect when is focus with 'tab' key
      $(this)
        .closest(alleanzaSelect)
        .addClass("alleanza-select--focused");
    });

    // If Click out .alleanza-select > display none dropdown - toggle drop icon
    // $(document).on("click", function (e) {
    //   if (isAngularSelect($(e.target)) || isAngularSelect($(that))) {
    //     return;
    //   }

    //   if (!$(e.target).hasClass("icon-alleanza-sinistri-assistenza")) {
    //     $("[data-toggle='tooltip']").tooltip("hide");
    //   }

    //   // if the target of the click isn't the alleanzaSelect or Dropdown > display none dropdown
    //   if (
    //     !that.is(e.target) &&
    //     that.has(e.target).length === 0 &&
    //     !that
    //       .closest(alleanzaSelectContainer)
    //       .find(alleanzaSelectDropdown)
    //       .is(e.target) &&
    //     that
    //       .closest(alleanzaSelectContainer)
    //       .find(alleanzaSelectDropdown)
    //       .has(e.target).length === 0
    //   ) {
    //     that
    //       .closest(alleanzaSelectContainer)
    //       .find(alleanzaSelectDropdown)
    //       .addClass("alleanza-select-dropdown--hidden");

    //     // toggle drop icon
    //     toggleDropIcon(that);
    //     toggleBorderSelect(that);

    //     // SELECT UNDERLINE removeClass "strong-underline"
    //     if (that.hasClass("alleanza-select--underline")) {
    //       that.find(".selected-container").removeClass("strong-underline");
    //     }
    //   }
    //   // If Click out '.alleanza-select' or Dropdown and '.selected' has val '' > remove class '--focused' to '.alleanza-select' - '.clear-link' fadeOut - toggle drop icon
    //   if (
    //     !that.is(e.target) &&
    //     that.has(e.target).length === 0 &&
    //     !that
    //       .closest(alleanzaSelectContainer)
    //       .find(alleanzaSelectDropdown)
    //       .is(e.target) &&
    //     that
    //       .closest(alleanzaSelectContainer)
    //       .find(alleanzaSelectDropdown)
    //       .has(e.target).length === 0 &&
    //     that
    //       .closest(alleanzaSelectContainer)
    //       .find(alleanzaSelected)
    //       .val() == ""
    //   ) {
    //     that
    //       .closest(alleanzaSelectContainer)
    //       .find(alleanzaSelect)
    //       .removeClass("alleanza-select--focused");
    //     that
    //       .closest(alleanzaSelectContainer)
    //       .find(alleanzaSelectClear)
    //       .fadeOut(250);

    //     toggleDropIcon(that);
    //   }
    // });
    // Click on '.dropdown-item' > keep this val an pass it to '.selected' input - display none dropdown - show and hide '.' - toggle drop icon
    $(this)
      .closest(alleanzaSelectContainer)
      .find(alleanzaSelectDropdown)
      .find(".dropdown-item")
      .on("click", function (e) {
        const alleanzaSelectValue = ($(this).text()).trim();
        if (isAngularSelect(this)) {
          return;
        }
        e.preventDefault();
        $(this)
          .closest(alleanzaSelectContainer)
          .find(alleanzaSelected)
          .val(alleanzaSelectValue)
          .attr("data-selected", alleanzaSelectValue);
        $(this)
          .closest(alleanzaSelectContainer)
          .find(alleanzaSelectDropdown)
          .addClass("alleanza-select-dropdown--hidden");

        showAndHideSelectClear($(this));
        toggleDropIcon($(this));
        toggleBorderSelect($(this));
        window.alleanza.util.underlinedAutoWidth();
      });
  });

  $(".new-contatta-agenzia").on("click focusout", function(){
    setTimeout(function() {
      checkAndActivateArrow();
    }, 5);
  })

  function checkAndActivateArrow(){
    $(".alleanza-select-dropdown").each(function(){
      var selectContainer = $(this).parents(".alleanza-select-container").find(".selected-container");
      if ($(this).css("display") == "block") {
        selectContainer.addClass("arrow-down");
      } else {
        selectContainer.removeClass("arrow-down");
      }
    });
  }


  // Show and hide clear 'X' icon in  SELECT
  function showAndHideSelectClear(thisEl) {
    //For tab key hide '.clear-link'
    if (event.keyCode == 9) {
      var currentInput = event.target;
      $(alleanzaSelected)
        .not(currentInput)
        .closest(alleanzaSelect)
        .find(alleanzaSelectClear)
        .fadeOut(250);
    } else {
      thisEl
        .closest(alleanzaSelectContainer)
        .find(alleanzaSelectClear)
        .fadeIn(250, function () {
          $(this).on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            //add '--hidden' class to all dropdown
            alleanzaSelectContainer.find(alleanzaSelectDropdown).addClass("alleanza-select-dropdown--hidden");
            // toggle class to '.drop' icon to all select
            alleanzaSelectContainer
              .find(alleanzaSelect)
              .find("[class^='icon-arrow']")
              .removeClass("icon-arrow-up")
              .addClass("icon-arrow-down");
            $(this)
              .closest(alleanzaSelect)
              .find(alleanzaSelected)
              .val("")
              .focus();
            $(this)
              .closest(alleanzaSelectContainer)
              .find(alleanzaSelectDropdown)
              .removeClass("alleanza-select-dropdown--hidden");
            $(this)
              .closest(alleanzaSelectContainer)
              .find(alleanzaSelectDropdown)
              .find(".dropdown-item")
              .css("display", "flex");
            $(this).fadeOut(250);
            // toggle drop icon
            toggleDropIcon($(this));
          });
        });
    }
  }

  function toggleBorderSelect(thisEl) {
    // focus dropdown
    if (!$(".welfare-quote-step").length) {
      if (
        thisEl
          .closest(alleanzaSelectContainer)
          .find(alleanzaSelectDropdown)
          .hasClass("alleanza-select-dropdown--hidden")
      ) {
        thisEl
          .closest(alleanzaSelectContainer)
          .find(alleanzaSelect).removeClass("border-orange");
        // .css("border", "solid 1px #6e757c");
      } else {
        thisEl
          .closest(alleanzaSelectContainer)
          .find(alleanzaSelect).addClass("border-orange");
        // .css("border", "solid 2px #EC6608");
      }
    }
  }

  function toggleDropIcon(thisEl) {
    if (
      thisEl
        .closest(alleanzaSelectContainer)
        .find(alleanzaSelectDropdown)
        .hasClass("alleanza-select-dropdown--hidden")
    ) {
      thisEl
        .closest(alleanzaSelectContainer)
        .find(alleanzaSelect)
        .find("i.input-icon")
        .removeClass("icon-arrow-up")
        .addClass("icon-arrow-down");
    } else {
      thisEl
        .closest(alleanzaSelectContainer)
        .find(alleanzaSelect)
        .find("i.input-icon")
        .removeClass("icon-arrow-down")
        .addClass("icon-arrow-up");
    }
  }
  function changeDropArrowWhenDropdownItemAreHidden() {
    if (
      $(this)
        .closest(alleanzaSelectContainer)
        .find(alleanzaSelectDropdown)
        .find("a.dropdown-item")
        .is(":hidden")
    ) {
      $(this)
        .closest(alleanzaSelectContainer)
        .find(alleanzaSelect)
        .find("[class^='icon-arrow']")
        .removeClass("icon-arrow-up")
        .addClass("icon-arrow-down");
    }
  }
  // END alleanza SELECT
  //
  //
  //
  // START alleanza INPUT
  const alleanzaInputContainer = $(".alleanza-input-container");
  const alleanzaInput = $(".alleanza-input");
  // Added textarea to input array as the styles for both remain same - to remove added the focused state
  const alleanzaInputTag = $(".input-container input,.input-container textarea");
  const alleanzaInputClear = $(".input-container .clear-link");

  // Input Google Autocomplete
  /*if (alleanzaInputContainer.hasClass("google-autocomplete")) {
    window.addEventListener("mapLibraryReady", () => {
      const inputGoogleAutcomplete = document.getElementById("google");
      const mapContainer = document.createElement("div");
      mapContainer.className = "google";
      const map = new google.maps.Map(mapContainer);
      const mapsPlaces = new google.maps.places.Autocomplete(
        inputGoogleAutcomplete
      );
    });
  }*/

  const monthNames = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
  const dayNames = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
  const dayNamesShort = ["Dom", "Lun", "Mar", "Mer", "Gio ", "Ven", "Sab"];
  let italian_daterangepicker = {
    "direction": "ltr",
    "format": "DD/MM/YYYY",
    "separator": " - ",
    "applyLabel": "Applica",
    "cancelLabel": "Annulla",
    "fromLabel": "Da",
    "toLabel": "A",
    "customRangeLabel": "Personalizzata",
    "daysOfWeek": dayNamesShort,
    "monthNames": monthNames,
    "firstDay": 1,
  };

  // Click on .alleanza-input > add '--focused' to this
  $(document).on("click", function (e) {
    alleanzaInputContainer.find(alleanzaInput).each(function () {
      var that = $(this);
      // if the target of the click isn't the alleanzaInput and input has val '' > remove 'focus' to .alleanza-select - clear fadeOut
      if (!that.closest(alleanzaInputContainer).is(".datepicker-calendar, .daterangepicker-calendar") && !that.is(e.target) && that.has(e.target).length === 0 && that.closest(alleanzaInputContainer).find(alleanzaInputTag).val() == "" || that.closest(alleanzaInputContainer).is(".datepicker-calendar, .daterangepicker-calendar") && !that.is(e.target) && that.has(e.target).length === 0 && that.closest(alleanzaInputContainer).find(alleanzaInputTag).val() == "" && that.closest(alleanzaSelectContainer).find(".daterangepicker").is(e.target) && that.closest(alleanzaSelectContainer).find(".daterangepicker").has(e.target).length != 0) {
        that.closest(alleanzaInput).removeClass("alleanza-input--focused");
        that.closest(alleanzaInput).find(alleanzaSelectClear).fadeOut(250);
      } // if the target of the click isn't the alleanzaInput of INPUT UNDERLINE > removeClass "strong-underline"

      if (that.hasClass("alleanza-input--underline") && !that.is(e.target) && that.has(e.target).length === 0) {
        that.find(".input-container").removeClass("strong-underline");
      }
    });
  });
  alleanzaInputContainer.find(alleanzaInput).each(function () {
    const that = $(this);
    if (!isAngularDate(this)) {
      if (
        $(this)
          .find(alleanzaInputTag)
          .val() != ""
      ) {
        $(this).addClass("alleanza-input--focused");
      }
    }

    // add tooltip toggle event listener to elements
    $(this)
      .closest(alleanzaInputContainer)
      .find("[data-toggle='tooltip']")
      .on("click", function () {
        $(alleanzaInput).each(function (index, element) {
          $(element)
            .not(that)
            .find("[data-toggle='tooltip']")
            .tooltip("hide");
        });
        isIconClicked = true;
        $(this).tooltip("toggle");
      });

    ////
    ////
    // START - ADD INITIALIZED CLASS FOR ANGULAR > return if element has class "initialized"
    if (
      $(this)
        .closest(alleanzaInputContainer)
        .hasClass("initialized")
    ) {
      return;
    }
    $(this)
      .closest(alleanzaInputContainer)
      .addClass("initialized");
    // END - ADD INITIALIZED CLASS FOR ANGULAR > return if element has class "initialized"
    ////
    ////

    // DateRangePicker - Single date
    if (
      $(this)
        .closest(alleanzaInputContainer)
        .hasClass("datepicker-calendar")
    ) {
      var currentDateInput = $(this).closest(alleanzaInputContainer);
      $(this)
        .closest(alleanzaInputContainer)
        .find(alleanzaInputTag)
        .daterangepicker({
          parentEl: ".datepicker-calendar",
          singleDatePicker: true,
          showDropdowns: true,
          minDate: $('.section-assistenza').length ? newDate() : "01/01/1900",
          locale: italian_daterangepicker,
          isInvalidDate: (date) => isInvalidDate(date, currentDateInput),
          //minYear: 1901,
          //maxYear: parseInt(moment().format("YYYY"), 10),
          opens: "center",
        });
      $(this)
        .closest(alleanzaInputContainer)
        .find(alleanzaInputTag)
        .on("hide.daterangepicker", function () {
          window.alleanza.util.underlinedAutoWidth();
          // if(!$(".welfare-quote-step").length){
          // $(this)
          //   .closest(alleanzaInputContainer)
          //   .find(alleanzaInput)
          //   .css("border", "solid 1px #6e757c");
          // }
          OffBorderInput($(this));
        });
      $(this)
        .closest(alleanzaInputContainer)
        .find(alleanzaInputTag)
        .val("");
    }

    if (
      $(this)
        .closest(alleanzaInputContainer)
        .hasClass("daterangepicker-calendar")
    ) {
      // DateRangePicker - Double date
      $(this)
        .closest(alleanzaInputContainer)
        .find(alleanzaInputTag)
        .daterangepicker({
          parentEl: $(this).closest(".daterangepicker-calendar"),
          autoApply: true,
          showButtonPanel: true,
          showDropdowns: true,
          minDate: "01/01/1900",
          locale: italian_daterangepicker,
          //minYear: 1901,
          //maxYear: parseInt(moment().format("YYYY"), 10),
          opens: "left",
        });
      $(this)
        .closest(alleanzaInputContainer)
        .find(alleanzaInputTag)
        .on("hide.daterangepicker", function () {
          window.alleanza.util.underlinedAutoWidth();
          OffBorderInput($(this));
        });
      $(this)
        .closest(alleanzaInputContainer)
        .find(alleanzaInputTag)
        .val("");
    }

    function isInvalidDate(date, currentInput) {
      if (!date && typeof dayOfWeek instanceof Date) {
        return true;
      }
      var disableWeekend = $(currentInput).closest(alleanzaInputContainer).hasClass("JS-datepicker-NoWeekend");
      if (disableWeekend) {
        var dayOfWeek = date.day();
        return dayOfWeek === 0 || dayOfWeek === 6; //True se Sabato o Domenica
      }
      return false;
    }

    function newDate(txt) {
      let format_date;
      if (txt) {
        format_date = new Date(txt);
      } else {
        format_date = new Date();
      }
      var dd = format_date.getDate();
      var day = format_date.getDay();
      var mm = format_date.getMonth() + 1; //January is 0! 
      var yyyy = format_date.getFullYear();
      if (dd < 10) { dd = '0' + dd }
      if (mm < 10) { mm = '0' + mm }
      return dd + '/' + mm + '/' + yyyy + '/' + day;
    }

    function OffBorderInput(thisEl) {
      if (!$(".welfare-quote-step").length) {
        thisEl
          .closest(alleanzaInputContainer)
          .find(alleanzaInput).removeClass("border-orange")
        // .css("border", "solid 1px #6e757c");
      }
    }

    function OnBorderInput(thisEl) {
      if (!$(".welfare-quote-step").length) {
        thisEl
          .closest(alleanzaInputContainer)
          .find(alleanzaInput).addClass("border-orange");
        // .css("border", "solid 2px #EC6608");
      }
    }


    $(this).on("click", function () {
      if (isIconClicked) {
        isIconClicked = false;
        return;
      }
      $(this).addClass("alleanza-input--focused");
      $(this)
        .find(alleanzaInputTag)
        .focus();
      alleanzaInputContainer.find(alleanzaInputClear).fadeOut(250);
      OnBorderInput($(this));
      // INPUT UNDERLINE addClass "strong-underline"
      if ($(this).hasClass("alleanza-input--underline")) {
        $(this)
          .find(".input-container")
          .addClass("strong-underline");
      }
    });

    // Click on input on 'keyup' > show clear 'X' icon
    $(this)
      .closest(alleanzaInputContainer)
      .find(alleanzaInputTag)
      .on("keyup", function () {
        // add class '--focused' to alleanzaSelect when is focus with 'tab' key
        $(this)
          .closest(alleanzaInput)
          .addClass("alleanza-input--focused");
        //For tab key hide '.clear-link'
        if (event.keyCode == 9) {
          var currentInput = event.target;
          alleanzaInputContainer
            .find(alleanzaInputTag)
            .not(currentInput)
            .closest(alleanzaInput)
            .find(alleanzaInputClear)
            .fadeOut(250);
        }
        showAndHideInputClear($(this));
      });
  });

  // Show and hide clear 'X' icon in INPUT
  function showAndHideInputClear(thisEl) {
    thisEl
      .closest(alleanzaInputContainer)
      .find(alleanzaInputClear)
      .fadeIn(250, function () {
        $(this).on("click", function (e) {
          e.preventDefault();
          e.stopPropagation();
          $(this)
            .closest(alleanzaInput)
            .find(alleanzaInputTag)
            .val("")
            .focus();
          $(this).fadeOut(250);
        });
      });
  }
  // END alleanza INPUT

  // START alleanza SELECT/INPUT UNDERLINED AUTO WIDTH
  window.alleanza.util.underlinedAutoWidth();
  // END alleanza SELECT/INPUT UNDERLINED AUTO WIDTH

  // START alleanza SELECT/INPUT ONLY NUMBER
  $("input.numeric-text").on("input propertychange", function () {
    $(this).val(
      $(this)
        .val()
        .replace(/[^\d]+/g, "")
    );
  });
  // END alleanza SELECT/INPUT ONLY NUMBER

  // alleanza SELECT NOT-AUTOCOMPLETE
  $(".not-autocomplete")
    .find("input")
    .focus(function () {
      $(this).blur();
    });
};
// START alleanza SELECT/INPUT UNDERLINED AUTO WIDTH
util.underlinedAutoWidth = function () {
  if ($(".underlined").length && $(".underlined .input-width").length) {
    $(".underlined").each(function () {
      var that = $(this);
      $(this).find("input").css("min-width", 0);
      $(this).find(".input-width").text($(this).find("input:not(.pip-input2)").val() || $(this).find("input:not(.pip-input2)").attr("placeholder"));

      $(this).find(".input-width").text($(this).find("input.pip-input2").val() && 
      $(this).find("input.pip-input2").val().length > $(this).find("input.pip-input2").attr("placeholder").length ? 
      $(this).find("input.pip-input2").val() : $(this).find("input.pip-input2").attr("placeholder"));


      $(this).find("input:not(.pip-input, .pip-input2, .pip-input3)").width(Math.round($(this).find(".input-width").width() * 1.04)).css("min-width", Math.round($(this).find(".selected-container, .input-container").width() * 1.04));
      $(this).find("input.pip-input").width(Math.round($(this).find(".input-width").width() + 30));
      $(this).find("input.pip-input2").width(Math.round($(this).find(".input-width").width()) + 1).css("min-width", Math.round($(this).find(".selected-container, .input-container").width()) + 1);
      $(this).find("input.pip-input3").width(Math.round($(this).find(".input-width").width()));
      $(this).off("input").on("input", function () {
        that.find(".input-width").text(that.find("input").val() || that.find("input").attr("placeholder"));
        that.find("input").width(Math.round(that.find(".input-width").width() + 10));
      });
    });
  }
};
// util.underlinedAutoWidth = function () {
//   if ($(".underlined").length && $(".underlined .input-width").length) {
//     $(".underlined")
//       .each(function () {
//         $(this)
//           .find("input")
//           .css("min-width", 0);

//         $(this)
//           .find(".input-width")
//           .text(
//             $(this)
//               .find("input")
//               .val() ||
//             $(this)
//               .find("input")
//               .attr("placeholder")
//           );
//         $(this)
//           .find("input")
//           .width(
//             Math.round(
//               $(this)
//                 .find(".input-width")
//                 .width() * 1.04
//             )
//           )
//           .css(
//             "min-width",
//             Math.round(
//               $(this)
//                 .find(".selected-container, .input-container")
//                 .width() * 1.04
//             )
//           );
//       })
//       .on("input", function () {
//         $(this)
//           .find(".input-width")
//           .text(
//             $(this)
//               .find("input")
//               .val() ||
//             $(this)
//               .find("input")
//               .attr("placeholder")
//           );
//         $(this)
//           .find("input")
//           .width(
//             Math.round(
//               $(this)
//                 .find(".input-width")
//                 .width() + 1
//             )
//           );
//       });
//   }
// };
// END alleanza SELECT/INPUT UNDERLINED AUTO WIDTH

// numeri con punti per millesimi
util.numberWithCommas = function (x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

// slash per data di nascita
util.addSlashDate = function (e) {
  if (e.keyCode != 8 && e.keyCode != 193 && e.keyCode != 111) { // controllo i caratteri inseriti
    let l = $(e.target).val().length;
    let x = l === 2 || l === 5 ? '/' : ''; // se la lunghezza è 2 o 5 aggiunge lo slash altrimenti nulla
    $(e.target).val($(e.target).val() + x);
  }
};

// START scroll to top page
util.scrollToTopPage = function () {
  $("html,body").animate({ scrollTop: 0 }, 500, function () { });
};
// END scroll to top page

// START alleanza FILTERING
// util.filtering = function(init, name) {
//   if ($(".filter-container").length) {
//     var addClassShow = function addClassShow(element, name) {
//       var i, arr1, arr2;
//       arr1 = element.className.split(" ");
//       arr2 = name.split(" ");

//       for (i = 0; i < arr2.length; i++) {
//         if (arr1.indexOf(arr2[i]) == -1) {
//           element.className += " " + arr2[i];
//         }
//       }
//     };

//     var removeClassShow = function removeClassShow(element, name) {
//       var i, arr1, arr2;
//       arr1 = element.className.split(" ");
//       arr2 = name.split(" ");

//       for (i = 0; i < arr2.length; i++) {
//         while (arr1.indexOf(arr2[i]) > -1) {
//           arr1.splice(arr1.indexOf(arr2[i]), 1);
//         }
//       }

//       element.className = arr1.join(" ");
//     };

//     var filterSelection = function filterSelection(filterName) {
//       var filterDiv, i;
//       filterDiv = document.getElementsByClassName("filterDiv");
//       if (filterName == "all") filterName = "";

//       for (i = 0; i < filterDiv.length; i++) {
//         removeClassShow(filterDiv[i], "show");
//         if (filterDiv[i].className.indexOf(filterName) > -1) addClassShow(filterDiv[i], "show");
//       }
//     }; // Add active class to the current button + Fade + filterSelection()

//     var setFilter = function setFilter(filterName) {
//       $("[data-filter=".concat(filterName, "]")).on("click", function() {
//         var that = $(this);
//         $(".block-filter-item")
//           .closest(".block-filter")
//           .fadeOut(250, function() {
//             filterSelection(filterName);
//             $(".sub-nav-item-button").removeClass("active-filter");
//             that.addClass("active-filter");
//           });
//         $(".block-filter-item")
//           .closest(".block-filter")
//           .fadeIn(250);
//       });
//     };

//     if (init) {
//       filterSelection("all");
//       setFilter("all");
//     }

//     if (name) {
//       setFilter(name);
//     }
//   }
// };
// END alleanza FILTERING

//===Debounce===
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// util.debounce = function(func, wait, immediate) {
//   var timeout;
//   return function() {
//     var context = this,
//       args = arguments;
//     var later = function() {
//       timeout = null;
//       if (!immediate) func.apply(context, args);
//     };
//     var callNow = immediate && !timeout;
//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//     if (callNow) func.apply(context, args);
//   };
// };

// util.isLegacyIE = (window.ActiveXObject ? true : false) || (!!window.MSInputMethodContext && !!document.documentMode);

window.alleanza.util = util;
