const appInit = () => {
  window.alleanza.mainServices.init();
  window.alleanza.smallCardCarousel.init();
  window.alleanza.smallGenericCarousel.init();
  window.alleanza.twoCardsCarousel.init();
  window.alleanza.oneCardCarousel.init();
  window.alleanza.hero.init();
  window.alleanza.alleanzaKaltura.init();
  // window.alleanza.stickyTabNav.init();
  window.alleanza.header.init();
  window.alleanza.vetrinaProdottiTab.init();
  window.alleanza.selectInputDefault.init();
  window.alleanza.mediaCarousel.init();
  window.alleanza.quotazioni.init();
  window.alleanza.trovaAgenziaCarousel.init();
  window.alleanza.carouselQuote.init();
  window.alleanza.archivioProd.init();
  // window.alleanza.experience.init();
  // window.alleanza.experienceVideo.init();
  window.alleanza.image.init();
  window.alleanza.objectFitPolyfilll.init();
  window.alleanza.preventivatoriPrev.init();
  // window.alleanza.alleanzaLavoraConNoiVideo.init();
  window.alleanza.alleanzaYouTube.init();
  window.alleanza.carouselVetrina.init();
  window.alleanza.carouselExpAgenzie.init();
  window.alleanza.carouselInstantWin.init();
};

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", () => {
    WebFont.load({
      google: {
        families: ["Roboto:300,400,500,700&display=swap"],
      },
      active: function () {
        appInit();
      },
    });
  });
} else {
  WebFont.load({
    google: {
      families: ["Roboto:300,400,500,700&display=swap"],
    },
    active: function () {
      appInit();
    },
  });
}