// Start Module: selectInputDefault

(function () {
  let selectInputDefault = window.alleanza.selectInputDefault || {};

  selectInputDefault.init = () => {
    window.alleanza.util.selectInput();
    window.alleanza.util.selectInput2();
  };

  
$('.generali-input-container .input-textarea , textarea').on('focus blur', function (e) {
  $(this).parents('.input-textarea').toggleClass('is-focused', (e.type === 'focus' || $(this).val().length > 0));
}).trigger('blur');

  util.selectInput2 = function () {
    // START generali SELECT
    const generaliSelectContainer = $(".generali-select-container");
    const generaliSelect = $(".generali-select");
    const generaliSelected = $(".selected");
    const generaliSelectClear = $(".clear-link");
    const generaliSelectDropdown = $(".generali-select-dropdown");
    let isIconClicked = false;

    function isAngularSelect(element) {
      return $(element).closest(".angular-custom-select").length > 0 || $(element).hasClass("angular-custom-select");
    }

    function isAngularDate(element) {
      return $(element).closest(".angular-custom-date").length > 0 || $(element).hasClass("angular-custom-date");
    }

    $(document).on("click", function (e) {
      generaliSelectContainer.find(generaliSelect).each(function () {
        var that = $(this);
        if (isAngularSelect($(e.target)) || isAngularSelect($(that))) {
          return;
        }
        if (!$(e.target).hasClass("icon-generali-sinistri-assistenza")) {
          $("[data-toggle='tooltip']").tooltip("hide");
        } // if the target of the click isn't the generaliSelect or Dropdown > display none dropdown

        if (!that.is(e.target) && that.has(e.target).length === 0 && !that.closest(generaliSelectContainer).find(generaliSelectDropdown).is(e.target) && that.closest(generaliSelectContainer).find(generaliSelectDropdown).has(e.target).length === 0) {
          that.closest(generaliSelectContainer).find(generaliSelectDropdown).addClass("generali-select-dropdown--hidden"); // toggle drop icon
          toggleDropIcon(that);
          toggleBorderSelect(that); // SELECT UNDERLINE removeClass "strong-underline"
          if (that.hasClass("generali-select--underline")) {
            that.find(".selected-container").removeClass("strong-underline");
          }
        } // If Click out '.generali-select' or Dropdown and '.selected' has val '' > remove class '--focused' to '.generali-select' - '.clear-link' fadeOut - toggle drop icon

        if (!that.is(e.target) && that.has(e.target).length === 0 && !that.closest(generaliSelectContainer).find(generaliSelectDropdown).is(e.target) && that.closest(generaliSelectContainer).find(generaliSelectDropdown).has(e.target).length === 0 && that.closest(generaliSelectContainer).find(generaliSelected).val() == "") {
          that.closest(generaliSelectContainer).find(generaliSelect).removeClass("generali-select--focused");
          that.closest(generaliSelectContainer).find(generaliSelectClear).fadeOut(250);
          toggleDropIcon(that);
        }
      });
    });

    // Click on .generali-select > add 'focus' to this - display none dropdown
    generaliSelectContainer.find(generaliSelect).each(function () {
      const that = $(this);

      ////
      ////
      // START - ADD INITIALIZED CLASS FOR ANGULAR > return if element has class "initialized"
      if (
        $(this)
          .closest(generaliSelectContainer)
          .hasClass("initialized")
      ) {
        return;
      }
      $(this)
        .closest(generaliSelectContainer)
        .addClass("initialized");
      // END - ADD INITIALIZED CLASS FOR ANGULAR > return if element has class "initialized"
      ////
      ////

      // add '--focused' class if input value are precompiled
      if (
        $(this)
          .find(generaliSelected)
          .val() != ""
      ) {
        $(this).addClass("generali-select--focused");
      }

      // add tooltip toggle event listener to elements
      $(this)
        .closest(generaliSelectContainer)
        .find("[data-toggle='tooltip']")
        .on("click", function () {
          $(generaliSelect).each(function (index, element) {
            $(element)
              .not(that)
              .find("[data-toggle='tooltip']")
              .tooltip("hide");
          });
          isIconClicked = true;
          $(this).tooltip("toggle");
        });

      $(this).on("click", function () {
        if (isIconClicked) {
          isIconClicked = false;
          return;
        }
        if (isAngularSelect(this)) {
          return;
        }

        // add '--focused' class to this
        $(this).addClass("generali-select--focused");

        // SELECT UNDERLINE addClass "strong-underline"
        if ($(this).hasClass("generali-select--underline")) {
          $(this)
            .find(".selected-container")
            .addClass("strong-underline");
        }

        // remove or add '--hidden' to this dropdown
        if (
          !$(this)
            .closest(generaliSelectContainer)
            .find(generaliSelectDropdown)
            .hasClass("generali-select-dropdown--hidden")
        ) {
          $(this)
            .closest(generaliSelectContainer)
            .find(generaliSelectDropdown)
            .addClass("generali-select-dropdown--hidden");
        } else {
          $(this)
            .closest(generaliSelectContainer)
            .find(generaliSelectDropdown)
            .removeClass("generali-select-dropdown--hidden");
        }
        // add '.focus()' to this input
        $(this)
          .closest(generaliSelectContainer)
          .not(".not-autocomplete")
          .find(generaliSelected)
          .focus();
        $(this)
          .closest(".not-autocomplete")
          .find(generaliSelected)
          .focus(function () {
            $(this).blur();
          });
        generaliSelectContainer.find(generaliSelectClear).fadeOut(250);

        if ($(this).hasClass("generali-select--focused")) {
          $(this)
            .closest(generaliSelectContainer)
            .find(generaliSelectDropdown)
            .removeClass("generali-select-dropdown--hidden");
        }

        // '.selected' input on 'click' > Filtering dropdown items
        var value = $(this)
          .find(generaliSelected)
          .val()
          .toLowerCase();
        $(this)
          .closest(generaliSelectContainer)
          .not(".not-autocomplete")
          .find(generaliSelectDropdown)
          .find("a.dropdown-item")
          .filter(function () {
            $(this).toggle(
              $(this)
                .text()
                .toLowerCase()
                .indexOf(value) > -1
            );
          });

        // toggle drop icon
        toggleDropIcon($(this));
        toggleBorderSelect($(this));
        // add class '.icon-arrow-down' to '.drop'
        changeDropArrowWhenDropdownItemAreHidden();
      });

      // '.selected' input on 'keyup' > Filtering dropdown items
      $(this).on("keyup", function () {
        if (isAngularSelect(this)) {
          return;
        }
        var value = $(this)
          .find(generaliSelected)
          .val()
          .toLowerCase();
        $(this)
          .closest(generaliSelectContainer)
          .find(generaliSelectDropdown)
          .find("a.dropdown-item")
          .filter(function () {
            $(this).toggle(
              $(this)
                .text()
                .toLowerCase()
                .indexOf(value) > -1
            );
          });

        // add class '.icon-arrow-down' to '.drop'
        changeDropArrowWhenDropdownItemAreHidden();
        // show clear 'X' icon
        showAndHideSelectClear($(this));
        // add class '--focused' to generaliSelect when is focus with 'tab' key
        $(this)
          .closest(generaliSelect)
          .addClass("generali-select--focused");
      });

      // Click on '.dropdown-item' > keep this val an pass it to '.selected' input - display none dropdown - show and hide '.clear-link' - toggle drop icon
      $(this)
        .closest(generaliSelectContainer)
        .find(generaliSelectDropdown)
        .find(".dropdown-item")
        .on("click", function (e) {
          const generaliSelectValue = ($(this).text()).trim();
          if (isAngularSelect(this)) {
            return;
          }
          e.preventDefault();
          $(this)
            .closest(generaliSelectContainer)
            .find(generaliSelected)
            .val(generaliSelectValue)
            .attr("data-selected", generaliSelectValue);
          $(this)
            .closest(generaliSelectContainer)
            .find(generaliSelectDropdown)
            .addClass("generali-select-dropdown--hidden");

          showAndHideSelectClear($(this));
          toggleDropIcon($(this));
          toggleBorderSelect($(this));
          window.alleanza.util.underlinedAutoWidth();
        });
    });

    // Show and hide clear 'X' icon in  SELECT
    function showAndHideSelectClear(thisEl) {
      //For tab key hide '.clear-link'
      if (event.keyCode == 9) {
        var currentInput = event.target;
        $(generaliSelected)
          .not(currentInput)
          .closest(generaliSelect)
          .find(generaliSelectClear)
          .fadeOut(250);
      } else {
        thisEl
          .closest(generaliSelectContainer)
          .find(generaliSelectClear)
          .fadeIn(250, function () {
            $(this).on("click", function (e) {
              e.preventDefault();
              e.stopPropagation();
              //add '--hidden' class to all dropdown
              generaliSelectContainer.find(generaliSelectDropdown).addClass("generali-select-dropdown--hidden");
              // toggle class to '.drop' icon to all select
              generaliSelectContainer
                .find(generaliSelect)
                .find("[class^='icon-arrow']")
                .removeClass("icon-arrow-up")
                .addClass("icon-arrow-down");
              $(this)
                .closest(generaliSelect)
                .find(generaliSelected)
                .val("")
                .focus();
              $(this)
                .closest(generaliSelectContainer)
                .find(generaliSelectDropdown)
                .removeClass("generali-select-dropdown--hidden");
              $(this)
                .closest(generaliSelectContainer)
                .find(generaliSelectDropdown)
                .find(".dropdown-item")
                .css("display", "flex");
              $(this).fadeOut(250);
              // toggle drop icon
              toggleDropIcon($(this));
            });
          });
      }
    }

    function toggleBorderSelect(thisEl) {
      // focus dropdown
      if (
        thisEl
          .closest(generaliSelectContainer)
          .find(generaliSelectDropdown)
          .hasClass("generali-select-dropdown--hidden")
      ) {
        thisEl
          .closest(generaliSelectContainer)
          .find(generaliSelect).removeClass("border-orange");
        // .css("border", "solid 1px #6e757c");
      } else {
        thisEl
          .closest(generaliSelectContainer)
          .find(generaliSelect).addClass("border-orange");
        // .css("border", "solid 2px #EC6608");
      }
    }

    function toggleDropIcon(thisEl) {
      if (
        thisEl
          .closest(generaliSelectContainer)
          .find(generaliSelectDropdown)
          .hasClass("generali-select-dropdown--hidden")
      ) {
        thisEl
          .closest(generaliSelectContainer)
          .find(generaliSelect)
          .find("i.input-icon")
          .removeClass("icon-arrow-up")
          .addClass("icon-arrow-down");
      } else {
        thisEl
          .closest(generaliSelectContainer)
          .find(generaliSelect)
          .find("i.input-icon")
          .removeClass("icon-arrow-down")
          .addClass("icon-arrow-up");
      }
    }
    function changeDropArrowWhenDropdownItemAreHidden() {
      if (
        $(this)
          .closest(generaliSelectContainer)
          .find(generaliSelectDropdown)
          .find("a.dropdown-item")
          .is(":hidden")
      ) {
        $(this)
          .closest(generaliSelectContainer)
          .find(generaliSelect)
          .find("[class^='icon-arrow']")
          .removeClass("icon-arrow-up")
          .addClass("icon-arrow-down");
      }
    }
    // END generali SELECT
    //
    //
    //
    // START generali INPUT
    const generaliInputContainer = $(".generali-input-container");
    const generaliInput = $(".generali-input");
    // Added textarea to input array as the styles for both remain same - to remove added the focused state
    const generaliInputTag = $(".input-container input,.input-container textarea");
    const generaliInputClear = $(".input-container .clear-link");

    // Input Google Autocomplete
    /*if (generaliInputContainer.hasClass("google-autocomplete")) {
      window.addEventListener("mapLibraryReady", () => {
        const inputGoogleAutcomplete = document.getElementById("google");
        const mapContainer = document.createElement("div");
        mapContainer.className = "google";
        const map = new google.maps.Map(mapContainer);
        const mapsPlaces = new google.maps.places.Autocomplete(
          inputGoogleAutcomplete
        );
      });
    }*/

    const monthNames = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    const dayNames = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
    const dayNamesShort = ["Dom", "Lun", "Mar", "Mer", "Gio ", "Ven", "Sab"];
    let italian_daterangepicker = {
      "direction": "ltr",
      "format": "DD/MM/YYYY",
      "separator": " - ",
      "applyLabel": "Applica",
      "cancelLabel": "Annulla",
      "fromLabel": "Da",
      "toLabel": "A",
      "customRangeLabel": "Personalizzata",
      "daysOfWeek": dayNamesShort,
      "monthNames": monthNames,
      "firstDay": 1,
    };

    // Click on .generali-input > add '--focused' to this
    $(document).on("click", function (e) {
      generaliInputContainer.find(generaliInput).each(function () {
        var that = $(this);
        // if the target of the click isn't the generaliInput and input has val '' > remove 'focus' to .generali-select - clear fadeOut
        if (!that.closest(generaliInputContainer).is(".datepicker-calendar, .daterangepicker-calendar") && !that.is(e.target) && that.has(e.target).length === 0 && that.closest(generaliInputContainer).find(generaliInputTag).val() == "" || that.closest(generaliInputContainer).is(".datepicker-calendar, .daterangepicker-calendar") && !that.is(e.target) && that.has(e.target).length === 0 && that.closest(generaliInputContainer).find(generaliInputTag).val() == "" && that.closest(generaliSelectContainer).find(".daterangepicker").is(e.target) && that.closest(generaliSelectContainer).find(".daterangepicker").has(e.target).length != 0) {
          that.closest(generaliInput).removeClass("generali-input--focused");
          OffBorderInput(that);
          that.closest(generaliInput).find(generaliSelectClear).fadeOut(250);
        }else if(!that.closest(generaliInputContainer).is(".datepicker-calendar, .daterangepicker-calendar") && !that.is(e.target) && that.has(e.target).length === 0 && that.closest(generaliInputContainer).find(generaliInputTag).val() != "" || that.closest(generaliInputContainer).is(".datepicker-calendar, .daterangepicker-calendar") && !that.is(e.target) && that.has(e.target).length === 0 && that.closest(generaliInputContainer).find(generaliInputTag).val() != "" && that.closest(generaliSelectContainer).find(".daterangepicker").is(e.target) && that.closest(generaliSelectContainer).find(".daterangepicker").has(e.target).length != 0){
          OffBorderInput(that); // se ha contenuto ma non è selezionato
        }
        // if the target of the click isn't the generaliInput of INPUT UNDERLINE > removeClass "strong-underline"

        if (that.hasClass("generali-input--underline") && !that.is(e.target) && that.has(e.target).length === 0) {
          that.find(".input-container").removeClass("strong-underline");
        }
      });
    });
    generaliInputContainer.find(generaliInput).each(function () {
      const that = $(this);
      if (!isAngularDate(this)) {
        if (
          $(this)
            .find(generaliInputTag)
            .val() != ""
        ) {
          $(this).addClass("generali-input--focused");
        }
      }

      // add tooltip toggle event listener to elements
      $(this)
        .closest(generaliInputContainer)
        .find("[data-toggle='tooltip']")
        .on("click", function () {
          $(generaliInput).each(function (index, element) {
            $(element)
              .not(that)
              .find("[data-toggle='tooltip']")
              .tooltip("hide");
          });
          isIconClicked = true;
          $(this).tooltip("toggle");
        });

      ////
      ////
      // START - ADD INITIALIZED CLASS FOR ANGULAR > return if element has class "initialized"
      if (
        $(this)
          .closest(generaliInputContainer)
          .hasClass("initialized")
      ) {
        return;
      }
      $(this)
        .closest(generaliInputContainer)
        .addClass("initialized");
      // END - ADD INITIALIZED CLASS FOR ANGULAR > return if element has class "initialized"
      ////
      ////

      // DateRangePicker - Single date
      if (
        $(this)
          .closest(generaliInputContainer)
          .hasClass("datepicker-calendar")
      ) {
        var currentDateInput = $(this).closest(generaliInputContainer);
        $(this)
          .closest(generaliInputContainer)
          .find(generaliInputTag)
          .daterangepicker({
            parentEl: ".datepicker-calendar",
            singleDatePicker: true,
            showDropdowns: true,
            minDate: $('.section-assistenza').length ? newDate() : "01/01/1900",
            locale: italian_daterangepicker,
            isInvalidDate: (date) => isInvalidDate(date, currentDateInput),
            opens: "center",
          });
        $(this)
          .closest(generaliInputContainer)
          .find(generaliInputTag)
          .on("hide.daterangepicker", function () {
            window.alleanza.util.underlinedAutoWidth();
            OffBorderInput($(this));
          });
        $(this)
          .closest(generaliInputContainer)
          .find(generaliInputTag)
          .val("");
      }

      if (
        $(this)
          .closest(generaliInputContainer)
          .hasClass("daterangepicker-calendar")
      ) {
        // DateRangePicker - Double date
        $(this)
          .closest(generaliInputContainer)
          .find(generaliInputTag)
          .daterangepicker({
            parentEl: $(this).closest(".daterangepicker-calendar"),
            autoApply: true,
            showButtonPanel: true,
            showDropdowns: true,
            minDate: "01/01/1900",
            locale: italian_daterangepicker,
            opens: "left",
          });
        $(this)
          .closest(generaliInputContainer)
          .find(generaliInputTag)
          .on("hide.daterangepicker", function () {
            window.alleanza.util.underlinedAutoWidth();
            OffBorderInput($(this));
          });
        $(this)
          .closest(generaliInputContainer)
          .find(generaliInputTag)
          .val("");
      }

      function isInvalidDate(date, currentInput) {
        if (!date && typeof dayOfWeek instanceof Date) {
          return true;
        }
        var disableWeekend = $(currentInput).closest(generaliInputContainer).hasClass("JS-datepicker-NoWeekend");
        if (disableWeekend) {
          var dayOfWeek = date.day();
          return dayOfWeek === 0 || dayOfWeek === 6; //True se Sabato o Domenica
        }
        return false;
      }

      function newDate(txt) {
        let format_date;
        if (txt) {
          format_date = new Date(txt);
        } else {
          format_date = new Date();
        }
        var dd = format_date.getDate();
        var day = format_date.getDay();
        var mm = format_date.getMonth() + 1; //January is 0! 
        var yyyy = format_date.getFullYear();
        if (dd < 10) { dd = '0' + dd }
        if (mm < 10) { mm = '0' + mm }
        return dd + '/' + mm + '/' + yyyy + '/' + day;
      }

      $(this).on("click", function () {
        if (isIconClicked) {
          isIconClicked = false;
          return;
        }
        $(this).addClass("generali-input--focused");
        $(this)
          .find(generaliInputTag)
          .focus();
        generaliInputContainer.find(generaliInputClear).fadeOut(250);
        OnBorderInput($(this));
        // INPUT UNDERLINE addClass "strong-underline"
        if ($(this).hasClass("generali-input--underline")) {
          $(this)
            .find(".input-container")
            .addClass("strong-underline");
        }
      });

      // Click on input on 'keyup' > show clear 'X' icon
      $(this)
        .closest(generaliInputContainer)
        .find(generaliInputTag)
        .on("keyup", function () {
          // add class '--focused' to generaliSelect when is focus with 'tab' key
          $(this)
            .closest(generaliInput)
            .addClass("generali-input--focused");
          //For tab key hide '.clear-link'
          if (event.keyCode == 9) {
            var currentInput = event.target;
            generaliInputContainer
              .find(generaliInputTag)
              .not(currentInput)
              .closest(generaliInput)
              .find(generaliInputClear)
              .fadeOut(250);
          }
          showAndHideInputClear($(this));
        });
    });
    
    function OffBorderInput(thisEl) {
      thisEl
        .closest(generaliInputContainer)
        .find(generaliInput).removeClass("border-orange")
      // .css("border", "solid 1px #6e757c");
    }

    function OnBorderInput(thisEl) {
      thisEl
        .closest(generaliInputContainer)
        .find(generaliInput).addClass("border-orange");
      // .css("border", "solid 2px #EC6608");
    }

    // Show and hide clear 'X' icon in INPUT
    function showAndHideInputClear(thisEl) {
      thisEl
        .closest(generaliInputContainer)
        .find(generaliInputClear)
        .fadeIn(250, function () {
          $(this).on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(this)
              .closest(generaliInput)
              .find(generaliInputTag)
              .val("")
              .focus();
            $(this).fadeOut(250);
          });
        });
    }
    // END generali INPUT

    // START generali SELECT/INPUT UNDERLINED AUTO WIDTH
    window.alleanza.util.underlinedAutoWidth();
    // END generali SELECT/INPUT UNDERLINED AUTO WIDTH

    // START generali SELECT/INPUT ONLY NUMBER
    $("input.numeric-text").on("input propertychange", function () {
      $(this).val(
        $(this)
          .val()
          .replace(/[^\d]+/g, "")
      );
    });
    // END generali SELECT/INPUT ONLY NUMBER

    // generali SELECT NOT-AUTOCOMPLETE
    $(".not-autocomplete")
      .find("input")
      .focus(function () {
        $(this).blur();
      });
  };

  window.alleanza.selectInputDefault = selectInputDefault;
})();
// Start Module: selectInputDefault
