(function () {
  // start carousel media
  let mediaCarousel = window.alleanza.mediaCarousel || {};
  window.alleanza.carouselsMedia = [];
  mediaCarousel.init = () => {
    if ($(".carousel-media .swiper-container").length) {
      // let carousels = [];
      $('.carousel-media').each(function (index, element) {
        $(this).addClass('s' + index);
        $(".s" + index + " .swiper-pagination").addClass("pagination-media" + index);
        $(".s" + index + " .s3next").addClass("s3next-media" + index);
        $(".s" + index + " .s3prev").addClass("s3prev-media" + index);
        let swiperItems = $(".s" + index + " .swiper-container .swiper-slide");
        let carouselMediaVideoImage = new Swiper('.s' + index + " .swiper-container", {
          spaceBetween: 24,
          slidesPerView: 1,
          watchOverflow: true,
          updateOnWindowResize: true,
          observer: true, 
          observeParents: true,
          centeredSlides: true,
          speed: 1000,
          pagination: {
            el: ".swiper-pagination.pagination-media" + index,
            // dynamicBullets: true,
            clickable: true,
          },
          navigation: {

            nextEl: ".s3next.s3next-media" + index,
            prevEl: ".s3prev.s3prev-media" + index,
          },
          breakpoints: {
            1439: {
              slidesPerView: 1,
              spaceBetween: 24,
              slidesOffsetBefore: 0,
            },
            989: {
              slidesPerView: 1,
              spaceBetween: 24,
              // slidesOffsetBefore: 35,
            },
            767: {
              slidesPerView: 1,
              spaceBetween: 24,
              // slidesOffsetBefore: 35,
            },            
          },
        });
        let next = $(".carousel-media.s"+ index+" .swiper--arrow--nav .s3next.s3next-media" + index);
        let prev = $(".carousel-media.s"+ index+" .swiper--arrow--nav .s3prev.s3prev-media" + index);
          if (swiperItems.length != 0 && swiperItems.length <= 1) {
            next.addClass("hidden");
            prev.addClass("hidden");
          }
        // inserisco i caroselli nella variabile globale per il controllo dei video youtube
        window.alleanza.carouselsMedia.push(carouselMediaVideoImage);
      });
    };
  };
  // end carousel media

  window.alleanza.mediaCarousel = mediaCarousel;
})();
