// On resize
$(window).on("resize", function () {
    if ($('.trova-agenzia').length > 0) {
        alignCardsHeight();
    }
});

// Init
setTimeout(function () {
    alignCardsHeight();
    $('.trova-agenzia .ta-results').removeClass('loading').hide();
}, 1000);

// Set the same height to all cards
function alignCardsHeight() {
    $('.trova-agenzia .ta-results .ta-card').css('height', '');
    var maxHeight = 0;

    $('.trova-agenzia .ta-results .ta-card').each(function (i, card) {
        if ($(card).outerHeight() > maxHeight) {
            maxHeight = $(card).outerHeight();
        }
    });

    $('.trova-agenzia .ta-results .ta-card').css('height', maxHeight + 'px');
}

// Search
$('.trova-agenzia .ta-search .ta-search-button button').on('click', function () {
    // $(this).closest('.ta-search').find('.ta-search-results').show();

    // var $trovaAgenzia = $(this).closest('.trova-agenzia');

    // $trovaAgenzia.find('.ta-map img.map-default').hide();
    // $trovaAgenzia.find('.ta-map img.map-results').show();
    // $trovaAgenzia.find('.ta-map img.map-pin').show();
    // $trovaAgenzia.find('.ta-map img.map-pin-active').show();
    // $trovaAgenzia.find('.ta-results').show();
    // $trovaAgenzia.find('.ta-results .ta-card').removeClass('active');
    // $trovaAgenzia.find('.ta-results .ta-card').eq(0).addClass('active');

    showAgenziaOnMap();
});

// Clear search field
$('.trova-agenzia .ta-search .ta-search-input .alleanza-text-input i.input-icon').on('click', function () {
    $(this).closest('.alleanza-text-input').find('input').removeClass('is-dirty').val('');
});

// Click on card
$('.trova-agenzia .ta-results .ta-card').on('click', function () {
    $(this).closest('.trova-agenzia').find('.ta-results .ta-card').removeClass('active');
    $(this).addClass('active');

    showAgenziaOnMap();
});

// Place the pin on map
function showAgenziaOnMap() {
    var $card = $('.trova-agenzia .ta-results .ta-card.active');

    var x = $card.data('coordinates-x');
    var y = $card.data('coordinates-y');

    // $card.closest('.trova-agenzia').find('.ta-map img.pin').css({'top': x + 'px', 'left': y + 'px'});
    $card.closest('.trova-agenzia').find('.ta-map img.pin.map-pin-active').css({ 'top': x + 'px', 'left': y + 'px' });
}