$(".quotazioni-specifiche .quotazioni-specifiche__collapse__chart-button").on("click", function () {
  $(this).find("i.input-icon").toggleClass('icon-arrow-down').toggleClass('icon-arrow-up');
});


(function () {
  let quotazioni = window.alleanza.quotazioni || {};

  quotazioni.init = () => {
    moment.locale("it");

    /// Chart accordion - Start

    const accordionButton = ".quotazioni-specifiche__collapse__chart-button";

    $(".quotazioni-specifiche__collapse__chart-item").each(function (i, element) {
      $(element).attr("id", `quotazioni-specifiche__collapse-${i}`);
      $(element).on("shown.bs.collapse", function () {
        window.alleanza.util.selectInput();
      });
    });

    $(accordionButton).each(function (i, element) {
      $(element).attr("data-target", `#quotazioni-specifiche__collapse-${i}`);
    });

    /// Chart accordion - End

    // /// Date-range-picker - Start
    // let today = moment();
    // let threeMonthsAgo = moment().subtract(3, "months");
    // const parentEl = $(".quotazioni-specifiche");

    // const daterangepickerOptions = {
    //   cancelLabel: 'Clear',
    //   // startDate: threeMonthsAgo,
    //   // endDate: today,
    //   opens: "center",
    //   autoUpdateInput: false,
    //   parentEl,
    //   autoApply: true,
    //   locale: {
    //     separator: " - ",
    //     applyLabel: "Applica",
    //     cancelLabel: "Cancella", 
    //     fromLabel: "Da",
    //     toLabel: "A",
    //     daysOfWeek: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
    //     monthNames: [
    //       "GENNAIO",
    //       "FEBBRAIO",
    //       "MARZO",
    //       "APRILE",
    //       "MAGGIO",
    //       "GIUGNO",
    //       "LUGLIO",
    //       "AGOSTO",
    //       "SETTEMBRE",
    //       "OTTOBRE",
    //       "NOVEMBRE",
    //       "DICEMBRE",
    //     ],
    //     firstDay: 1,
    //   },
    // };

    // $("input[name='daterange']").daterangepicker(daterangepickerOptions);

    // /// Hiding the placeholder implementation.
    // // $('input[name="daterange"]').val("gg/mm/aaaa - gg/mm/aaaa");

    // $('input[name="daterange"]').each(function(i, element) {
    //   $(element).on("showCalendar.daterangepicker", function() {
    //     // $(this).css("border", "solid 1px #e5e5e5");
    //     $("label").css("transform", "translateY(-10px) scale(0.8)");
    //     $("input").attr('style', "color: #000f20 !important;");
    //   });

    //   $(element).on("hide.daterangepicker", function() {
    //     // $(this).css("border", "solid 1px #e5e5e5");
    //   });

    //   $(element)
    //     .find("button")
    //     .on("click", function() {
    //       $(element)
    //         .find("input")
    //         .focus();
    //     });
    // });

    // /// Date-range-picker - End

    /// Chart - Start
    // Nascondo se testo più lungo di 290 char
    var text_min = $('.quotazioni-specifiche .qs-hidden-content');
    var counter_char = 290;

    // per ogni contenuto di testo controllo se questo ha più di 290 char e in esito positivo, ne tronca il contenuto
    text_min.each(function () {
      var t = $(this).text();
      if (t.length < counter_char) { return; }
      let show_more = ($(this).closest(".quotazioni-specifiche")).find('a.link.show-more');
      $(this).css("max-height", 70);
      $(this).css("overflow", "hidden");
      show_more.removeClass("hidden");

    });

    // al click su "mostra di più" mostra tutto il contenuto troncato
    $('.quotazioni-specifiche a.link.show-more').on("click", function () {
      let container = $(this).closest(".quotazioni-specifiche");
      let wrapper = container.find(".qs-hidden-content");
      let show_less = container.find("a.link.show-less");
      wrapper.css("max-height", "");
      wrapper.css("overflow", "none");
      $(this).addClass("hidden");
      show_less.removeClass("hidden");
    });

    // al click su "mostra di meno" tronca il contenuto
    $('.quotazioni-specifiche a.link.show-less').on("click", function () {
      let container = $(this).closest(".quotazioni-specifiche");
      let wrapper = container.find(".qs-hidden-content");
      let show_more = container.find("a.link.show-more");
      wrapper.css("max-height", 70);
      wrapper.css("overflow", "hidden");
      $(this).addClass("hidden");
      show_more.removeClass("hidden");
    });

    Chart.defaults.global.defaultFontColor = '#303a45';
    Chart.defaults.global.defaultFontSize = 12;
    Chart.defaults.global.defaultFontFamily = 'AlleanzaSans';

    window.alleanza.createChart = function (chartElement, dataSet) {
      var ctx = chartElement.getContext("2d");
      ctx.height = 200;

      var timestamps = [];
      var values = [];

      if (dataSet.length) {
        // var normalizedData = _(dataSet)
        //   .map(_.values)
        //   .unzip()
        //   .value();
        var normalizedData = [];
        normalizedData.push(dataSet.map(function (x) {
          return x.Data;
        }));
        normalizedData.push(dataSet.map(function (x) {
          return x.Valore;
        }));
        timestamps = normalizedData[0].map(function (element) {
          return Number(element.slice(6, -2));
        });
        values = normalizedData[1];
      }

      var quotazioniChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: timestamps,
          datasets: [
            {
              data: values,
              backgroundColor: "transparent",
              borderColor: "#002855",
              borderWidth: 1,
              lineTension: 0,
              pointBackgroundColor: "#002855",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            // Disable the on-canvas tooltip
            enabled: false,

            custom: function (tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById("chartjs-tooltip");

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                tooltipEl.innerHTML = "<table></table>";
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = "<thead>";

                titleLines.forEach(function (title) {
                  innerHtml += "<tr><th>" + title + "</th></tr>";
                });
                innerHtml += "</thead><tbody>";

                bodyLines.forEach(function (body, i) {
                  var valueFormatted;
                  var value = Number.parseFloat(body);
                  if (Number.isNaN(value)) {
                    valueFormatted = body;
                  }
                  else {
                    valueFormatted = value.toLocaleString("it-IT", { minimumFractionDigits: 4, maximumFractionDigits: 4 });
                  }
                  var colors = tooltipModel.labelColors[i];
                  var style = "background:" + colors.backgroundColor;
                  style += "; border-color:" + colors.borderColor;
                  style += "; border-width: 2px";
                  var span = '<span style="' + style + '"></span>';
                  innerHtml += "<tr><td>" + span + valueFormatted + "</td></tr>";
                });
                innerHtml += "</tbody>";

                var tableRoot = tooltipEl.querySelector("table");
                tableRoot.innerHTML = innerHtml;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = "absolute";
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.boxShadow = "0 1px 4px 0 rgba(0, 0, 0, 0.3)";
              tooltipEl.style.backgroundColor = "white";
              tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
              tooltipEl.style.pointerEvents = "none";
            },
          },

          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  zeroLineWidth: 2,
                  drawBorder: false,
                },
                ticks: {
                  beginAtZero: false,
                  padding: 20,
                  callback: function (value, index, values) {
                    return value.toLocaleString("it-IT");
                  }
                }
              },
            ],
            xAxes: [
              {
                gridLines: {
                  drawOnChartArea: false,
                  drawTicks: true,
                  // tickMarkLength: 8,
                },
                ticks: {
                  padding: 2,
                  // modifica Greg per mostrare meno date nell'ascissa
                  display: true,
                  autoSkip: true,
                  maxTicksLimit: 15
                },
                type: "time",
                time: {
                  parser: "MM/DD/YYYY HH:mm",
                  tooltipFormat: "ll HH:mm",
                  unit: "day",
                  unitStepSize: 10,
                  displayFormats: {
                    day: "DD.  MMM",
                  },
                },
              },
            ],
          },
        },
      });
      $(chartElement).data("chartId", quotazioniChart.id);
    };
    /// Chart - End
  };

  window.alleanza.quotazioni = quotazioni;
})();
