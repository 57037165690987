(function () {
    $( ".hero-banner-round.animated" ).each(function( index ) {

        let tl_mod_hero_banner_round = gsap.timeline({
            scrollTrigger: {
                trigger: this,
                // toggleActions: "restart none none none",
                start: "top 60%",
            },
        });

        tl_mod_hero_banner_round.set($(this).find(".banner-round-container"), {yPercent: -50, autoAlpha: 0 });
        tl_mod_hero_banner_round.to($(this).find(".banner-round-container"), {yPercent: 0, autoAlpha: 1, duration: 0.5 });
        tl_mod_hero_banner_round.fromTo($(this).find(".sticker"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        .fromTo($(this).find(".title"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        .fromTo($(this).find(".description"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        .fromTo($(this).find(".label"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        .fromTo($(this).find(".box-round picture img"), {scale: 0.5,  autoAlpha: 0 }, { scale: 1.03,ease: Circ.easeOut,  autoAlpha: 1 })
        .to($(this).find(".box-round picture img"), { scale: 1, ease: Circ.easeIn, autoAlpha: 1 })

        .fromTo($(this).find(".video-rectangle"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        // .fromTo($(this).find(".video-rectangle .text-anim-1"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        // .fromTo($(this).find(".video-rectangle .text-anim-2"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        // .fromTo($(this).find(".video-rectangle .text-anim-3"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
    });

})();
