$(document).ready(function () {
    var mqva990 = window.matchMedia("(min-width: 990px) and (max-width: 1439)");
    var mqva1440 = window.matchMedia("(min-width: 1440px)");
    var mqva990min = window.matchMedia("(min-width: 990px)");
    $(".vertical-accordion.animated").each(function (index) {
        var el = $(this);
        var tl_acc = gsap.timeline({
            scrollTrigger: {
                trigger: this,
                repeat: -1,
                start: "top 60%"
            }
        });

        if (mqva990min.matches) {
            $(this).find("li.va-li").each(function (el) {
                tl_acc.fromTo(this, {
                    y: "100%",
                    autoAlpha: 0
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: Power3.easeOut,
                    duration: 0.3
                });
            });
            $(this).find("li.va-li").each(function (el) {
                if ($(this).hasClass("active")) {
                    tl_acc.fromTo($(this).find(".va-content"), {
                        x: "-300",
                        autoAlpha: 0
                    }, {
                        x: 0,
                        autoAlpha: 1,
                        ease: Power3.easeOut,
                        duration: 0.3
                    });
                } else {
                    tl_acc.fromTo($(this).find(".va-header"), {
                        x: "-300",
                        autoAlpha: 0
                    }, {
                        x: 0,
                        autoAlpha: 1,
                        ease: Power3.easeOut,
                        duration: 0.3
                    });
                }
            });
            tl_acc.fromTo($(this).find("picture.side-picture > img"), {
                x: "-200",
                autoAlpha: 0
            }, {
                x: 0,
                autoAlpha: 1,
                ease: Power3.easeOut,
                duration: 0.3
            });
            randomizeFloating($(this).find("picture.side-picture > img"), tl_acc);
        } else {
            $(this).find("li.va-li").each(function (el) {
                tl_acc.fromTo(this, {
                    x: "-100%",
                    autoAlpha: 0
                }, {
                    x: "0",
                    autoAlpha: 1,
                    ease: Power3.easeOut,
                    duration: 0.3
                });
            });
            $(this).find("li.va-li").each(function (el) {
                if ($(this).hasClass("active")) {
                    tl_acc.fromTo($(this).find(".va-header"), {
                        x: "-300",
                        autoAlpha: 0
                    }, {
                        x: 0,
                        autoAlpha: 1,
                        ease: Power3.easeOut,
                        duration: 0.3
                    }).fromTo($(this).find(".va-content"), {
                        x: "-300",
                        autoAlpha: 0
                    }, {
                        x: 0,
                        autoAlpha: 1,
                        ease: Power3.easeOut,
                        duration: 0.3
                    });
                } else {
                    tl_acc.fromTo($(this).find(".va-header"), {
                        x: "-300",
                        autoAlpha: 0
                    }, {
                        x: 0,
                        autoAlpha: 1,
                        ease: Power3.easeOut,
                        duration: 0.3
                    });
                }
            });
        }
    });
    //hover
    $(".vertical-accordion.animated ul.va-ul li.va-li .va-header").off('mouseover').on('mouseover', function () {
        $(this).parent().addClass("is-hover");
        //$(this).parent().removeClass("is-not-hover");
        $(this).parent().parent().find("li:not(.is-hover)").addClass("is-not-hover");
    });
    $(".vertical-accordion.animated ul.va-ul li.va-li .va-header").off('mouseout').on('mouseout', function () {
        $(this).parent().removeClass("is-hover");
        //$(this).parent().addClass("is-not-hover");
        $(this).parent().parent().find("li").removeClass("is-not-hover");
    });

    function randomizeFloating(target, tl) {
        var can = target;
        var randomX = random(10, 20);
        var randomY = random(20, 30);
        var randomDelay = random(0, 1);
        var randomTime = random(3, 5);
        var randomTime2 = random(5, 10);
        var randomAngle = random(8, 12); // const randomX = random(5, 20);
        // const randomY = random(5, 20);
        // const randomDelay = random(0, 1);
        // const randomTime = random(1, 2);
        // const randomTime2 = random(2, 3);
        // const randomAngle = random(1, 5);

        moveX(can, 1);
        moveY(can, -1);
        rotate(can, 1);

        function rotate(target, direction) {
            tl.to(target, randomTime2(), {
                rotation: randomAngle(direction),
                delay: randomDelay(),
                ease: Sine.easeInOut,
                onComplete: rotate,
                onCompleteParams: [target, direction * -1]
            });
        }

        function moveX(target, direction) {
            tl.to(target, randomTime(), {
                x: randomX(direction),
                ease: Sine.easeInOut,
                onComplete: moveX,
                onCompleteParams: [target, direction * -1]
            });
        }

        function moveY(target, direction) {
            tl.to(target, randomTime(), {
                y: randomY(direction),
                ease: Sine.easeInOut,
                onComplete: moveY,
                onCompleteParams: [target, direction * -1]
            });
        }

        function random(min, max) {
            var delta = max - min;
            return function () {
                var direction = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
                return (min + delta * Math.random()) * direction;
            };
        }
    }
});