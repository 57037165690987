(function () {
  $(".header.animated").each(function(){
    let tl_header_animated = gsap.timeline();
    var x = 0,
        y = "-100%";
    let first = $(".header--quick-nav");
    let second = $(".header--content");
    let elements = [first, second];
    tl_header_animated.fromTo(elements,
        { x: x, y: y, autoAlpha: 0 },
        {
          x: 0,
          y: "0%",
          autoAlpha: 1,
          stagger:{
            each: 1
          }
        });

    tl_header_animated.eventCallback("onComplete", () => {
      $(".header.animated").addClass('animation-ended');
    });
    
  });
}());
