// Start Module: experience
(function () {
    let experience = window.alleanza.experience || {};
    const monthNames = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    const dayNames = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
    const dayNamesShort = ["Dom", "Lun", "Mar", "Mer", "Gio ", "Ven", "Sab"];

    experience.init = () => {
        let trattamento_step4 = false;

        $(".alleanza-experience .sticky-info-preventivo a").on("click", function () {
            window.alleanza.util.scrollToTopPage();
        });

        // disabilito button step 4
        $(".alleanza-experience.experience-step-4 .sticky-action a.alleanza-button, .alleanza-experience.experience-step-4 .sticky-action-mobile a.alleanza-button").addClass("disabled");

        if ($('.mod-tabs-experience')) {
            let selected_box;
            const today = new Date();
            var format_date;
            let selected_date = newDate();
            $(".select-date #datepickerday").html(setDateString(selected_date));
            $(".select-date #prev").addClass("disabled");

            let italian_daterangepicker = {
                "direction": "ltr",
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Applica",
                "cancelLabel": "Annulla",
                "fromLabel": "Da",
                "toLabel": "A",
                "customRangeLabel": "Personalizzata",
                "daysOfWeek": dayNamesShort,
                "monthNames": monthNames,
                "firstDay": 1,
            };
            let picker = $('#daterangepicker1').daterangepicker({
                "parentEl": "#daterangepicker1-container",
                singleDatePicker: true,
                minDate: selected_date,
                'drops': "down",
                "opens": "center",
                setDate: selected_date,
                locale: italian_daterangepicker,
                isInvalidDate: isInvalidDateExperience
            });
            // range update listener
            picker.on('apply.daterangepicker', function (ev, picker) {
                // let dt = $("#daterangepicker-result").html('Selected date range: ' + picker.startDate.format('YYYY-MM-DD') + ' to ' + picker.endDate.format('YYYY-MM-DD'));
                let dt = new Date(picker.startDate);
                selected_date = newDate(dt);
                $(".select-date #datepickerday").html(setDateString(selected_date));
                if (format_date > today) {
                    $(".select-date #prev").removeClass("disabled");
                } else {
                    $(".select-date #prev").addClass("disabled");
                }
                if (!$("#menuCalendario").hasClass("hidden")) {
                    $("#menuCalendario").addClass("hidden")
                    $("#menuOrario1").removeClass("hidden");
                    $("#menuOrario2").removeClass("hidden");
                    $('.mod-tabs-experience #tab-toggle').prop("checked", true).change();
                    $(".mod-tabs-experience .scelta2").addClass("active");
                    $(".mod-tabs-experience .scelta1").removeClass("active");
                }
            });

            if (picker.data('daterangepicker')) {
                // prevent hide after range selection
                picker.data('daterangepicker').hide = function () { };
                // show picker on load
                picker.data('daterangepicker').show();
            }

            $(".select-date #prev").on("click", function () {
                if (!$(this).hasClass("disabled")) {
                    format_date.setDate(format_date.getDate() - 1);
                    $(".select-date #datepickerday").html(setDateString(newDate(format_date)));
                    if (checkDates(format_date, today) && !$(".select-date #prev").hasClass("disabled")) {
                        $(".select-date #prev").addClass("disabled");
                    }
                }
            })

            $(".select-date #next").on("click", function () {
                format_date.setDate(format_date.getDate() + 1);
                $(".select-date #datepickerday").html(setDateString(newDate(format_date)));
                if (format_date > today && $(".select-date #prev").hasClass("disabled")) {
                    $(".select-date #prev").removeClass("disabled");
                }
                // picker.daterangepicker("setDate", newDate(day) );
            })
            $(".mod-tabs-experience .orari-box").on("click", function () {
                if (!$(this).hasClass("disabled")) {
                    if (selected_box) {
                        $(selected_box).removeClass("active");
                    }
                    selected_box = "#" + $(this).attr('id');
                    $(selected_box).addClass("active");

                }
            });

            $('.mod-tabs-experience #tab-toggle').on("click", function () {
                if (this.checked) {
                    $("#menuOrario1").removeClass("hidden");
                    $("#menuOrario2").removeClass("hidden");
                    $("#menuCalendario").addClass("hidden");
                } else {
                    $("#menuCalendario").removeClass("hidden");
                    $("#menuOrario1").addClass("hidden");
                    $("#menuOrario2").addClass("hidden");
                }
            });
        }
        function isInvalidDateExperience(date) {
            if (!date && typeof dayOfWeek instanceof Date) {
                return true;
            }
            var dayOfWeek = date.day();
            return dayOfWeek === 0 || dayOfWeek === 6; //True se Sabato o Domenica
        }
        function checkDates(first, second) {
            return first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        }
        function newDate(txt) {
            if (txt) {
                format_date = new Date(txt);
            } else {
                format_date = new Date();
            }
            var dd = format_date.getDate();
            var day = format_date.getDay();
            var mm = format_date.getMonth() + 1; //January is 0! 
            var yyyy = format_date.getFullYear();
            if (dd < 10) { dd = '0' + dd }
            if (mm < 10) { mm = '0' + mm }
            return dd + '/' + mm + '/' + yyyy + '/' + day;
        }
        function setDateString(txt) {
            if (txt) {
                let dt = txt.split("/");
                return dayNames[dt[3]] + " " + dt[0] + " " + monthNames[parseInt(dt[1]) - 1].toLowerCase() + " " + dt[2];
            } else {
                return '';
            }

        }

        // START step 4
        // controllo checkbox privacy step 4
        $(".alleanza-experience.experience-step-4 .alleanza-checkbox input").on("change", function (e) {
            trattamento_step4 = this.checked;
            abilitaButtonExperience("4");
        });

        // step 4 abilita button sticky quando tutti i form sono completati
        $(".alleanza-experience.experience-step-4 .experience-form .alleanza-text-input input").on("change", function (e) {
            abilitaButtonExperience("4");
        });
        $(".alleanza-experience.experience-step-4 .experience-form .alleanza-select-container .alleanza-select-dropdown a.dropdown-item").on("click", function (e) {
            abilitaButtonExperience("4");
        });

        // controllo mail valida
        $(".alleanza-experience.experience-step-4 .experience-form input#experience-mail").on("change", function (e) {
            let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!$(this).val().match(mailformat)) {
                $(this).val("");
                $(this).closest(".alleanza-text-input").find(".message").removeClass("hidden");
            } else {
                $(this).closest(".alleanza-text-input").find(".message").addClass("hidden");
            }
            abilitaButtonExperience("4");
        });

        // abilito button step 4
        function abilitaButtonExperience(step) {
            if (step == "4" && trattamento_step4) {
                let counter = 0;
                $(".alleanza-experience.experience-step-4 .experience-form .alleanza-text-input input").each(function (index) {
                    if ($(this).val() != "") {
                        counter++;
                    }
                });
                if (counter == 3 && $(".alleanza-experience.experience-step-4 .experience-form .selected-container input").attr("data-selected")) {
                    $(".alleanza-experience.experience-step-4 .sticky-action a.alleanza-button, .alleanza-experience.experience-step-4 .sticky-action-mobile a.alleanza-button").removeClass("disabled");
                } else {
                    $(".alleanza-experience.experience-step-4 .sticky-action a.alleanza-button, .alleanza-experience.experience-step-4 .sticky-action-mobile a.alleanza-button").addClass("disabled");
                }
            } else {
                $(".alleanza-experience.experience-step-4 .sticky-action a.alleanza-button, .alleanza-experience.experience-step-4 .sticky-action-mobile a.alleanza-button").addClass("disabled");
            }
        }
        // END step 4
    };

    window.alleanza.experience = experience;
})();
  // End Module: experience

