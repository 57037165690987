//////////////////////
// VIEWPORT CHECKER //
//////////////////////
var isMobile = false;
var isTabletPort = false;
var isTabletLand = false;
var isDesktop = false;

function viewportChecker() {
  var windowWidht = $(window).width();

  if (windowWidht < 768) {
    isMobile = true;
    isTabletPort = false;
    isTabletLand = false;
    isDesktop = false;
  }
  if (windowWidht >= 768 && windowWidht < 990) {
    isMobile = false;
    isTabletPort = true;
    isTabletLand = false;
    isDesktop = false;
  }
  if (windowWidht >= 990 && windowWidht < 1400) {
    isMobile = false;
    isTabletPort = false;
    isTabletLand = true;
    isDesktop = false;
  }
  if (windowWidht >= 1400) {
    isMobile = false;
    isTabletPort = false;
    isTabletLand = false;
    isDesktop = true;
  }

}

viewportChecker();

var windowWidth = $(window).width();
var resizeTime;
var resizeTimeout = false;
var resizeDelta = 200;
$(window).resize(function () {
  viewportChecker();
  resizeTime = new Date();
  if (resizeTimeout === false) {
    resizeTimeout = true;
    setTimeout(resizeend, resizeDelta);
  }
});

try {
  if ($(".ricerca-strutture-sanitarie").length > 0) {
    resizeStruttureSanitarie();
  }
} catch (e) {
  console.log("Error: ", e);
}

function resizeend() {
  if (new Date() - resizeTime < resizeDelta) {
    setTimeout(resizeend, resizeDelta);
  } else {
    resizeTimeout = false;



    if ($(window).width() == windowWidth) return;
    windowWidth = $(window).width();

    try {
      if ($(".welfare-quote-step").length > 0) setTimeout(resizeend(), resizeDelta);

      if ($(".experience-cards-agenzia").length > 0) {
        setTimeout(showArrows("experience-cards-agenzia"), resizeDelta);
      }

      if ($(".ricerca-strutture-sanitarie").length > 0) {
        resizeStruttureSanitarie();
      }
    } catch (e) {
      // console.log(e)
    }

    window.alleanza.util.underlinedAutoWidth();
  }
};

function showArrows(carousel) {
  let swiperItems = $("." + carousel + " .swiper-container .swiper-slide");
  var next = $("." + carousel + " .swiper--arrow--nav .next-agenzia");
  var prev = $("." + carousel + " .swiper--arrow--nav .prev-agenzia");
  if ((swiperItems.length <= 4 && window.innerWidth >= 1439) || swiperItems.length <= 3) {
    next.addClass("hidden");
    prev.addClass("hidden");
  } else if (window.innerWidth < 1439 && swiperItems.length > 3) {
    next.removeClass("hidden");
    prev.removeClass("hidden");
  }
}

function resizeStruttureSanitarie() {
  let $strutture = $(".ricerca-strutture-sanitarie");
  $strutture.removeClass("vers-desktop");
  $strutture.removeClass("vers-mobile");
  if (isMobile) {
    // visualizzazione mobile
    $strutture.addClass("vers-mobile");
    // setting visualizzazione alla ricerca effettuata
    if ($strutture.hasClass("clicked-search")) {
      if ($strutture.find(".toggle-strutture-sanitarie-mobile input").prop("checked")) {
        // $strutture.find('.map-results-list').show();
        $strutture.find('.show-strutture').show();
        $strutture.find('.map-strutture-google').hide();
        $strutture.find('.box-swiper-mobile').hide();
      } else {
        // $strutture.find(".map-results-list").hide();
        $strutture.find('.show-strutture').hide();
        $strutture.find('.map-strutture-google').show();
        $strutture.find('.box-swiper-mobile').show();
      }
    }
  } else {
    // visualizzazione desktop/tablet
    $strutture.addClass("vers-desktop");
    // setting visualizzazione alla ricerca effettuata
    if ($strutture.hasClass("clicked-search")) {
      $strutture.find('.map-results-list').show();
      $strutture.find('.show-strutture').show();
      $strutture.find('.map-strutture-google').show();
    }
  }
}