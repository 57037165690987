(function () {
    // Start quattro card visibili in desktop carousel card generic
    $('.slider-generic-small').hide();
    let smallGenericCarousel = window.alleanza.smallGenericCarousel || {};
    smallGenericCarousel.init = () => {
        if ($(".slider-generic-small .swiper-container").length) {
            $('.slider-generic-small').each(function (index, element) {
                $(this).addClass('four' + index);
                $(".four" + index + " .swiper-pagination").addClass("four-pag-" + index);
                $(".four" + index + " .s3next").addClass("s3next-4" + index);
                $(".four" + index + " .s3prev").addClass("s3prev-4" + index);
                let swiperItems = $(".four" + index + " .swiper-container .swiper-slide");
                const carouselGeneric = new Swiper(".four" + index + " .swiper-container", {
                    slidesPerView: "auto",
                    spaceBetween: 12,
                    centeredSlides: window.innerWidth <= 375,
                    watchOverflow: true,
                    observer: true, 
                    observeParents: true,
                    updateOnWindowResize: true,
                    centerInsufficientSlides: true,
                    speed: 1000,
                    pagination: {
                        el: ".swiper-pagination.four-pag-" + index,
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".s3next.s3next-4" + index,
                        prevEl: ".s3prev.s3prev-4" + index,
                    },
                    breakpoints: {
                        1439: {
                            slidesPerView: 4,
                            spaceBetween: 24,
                            centeredSlides: false,
                        },
                        989: {
                            slidesPerView: 4,
                            spaceBetween: 21,
                            centeredSlides: false,
                        },
                        767: {
                            slidesPerView: 4,
                            spaceBetween: 21,
                            centeredSlides: false,
                        },

                        374: {
                            slidesPerView: "auto",
                            spaceBetween: 12,
                            centeredSlides: true,
                        }
                    },
                });
                // SPGI-1314 - Aggiunto if per nascondere le freccie del carosello
                var next = $(".slider-generic-small.four" + index + " .swiper--arrow--nav .s3next.s3next-4" + index);
                var prev = $(".slider-generic-small.four" + index + " .swiper--arrow--nav .s3prev.s3prev-4" + index);
                if (swiperItems.length != 0 && swiperItems.length <= 4) {
                    next.addClass("hidden");
                    prev.addClass("hidden");
                }
            });
        }
        $('.slider-generic-small').show();
    };
    // End quattro card visibili in desktop carousel card generic

    window.alleanza.smallGenericCarousel = smallGenericCarousel;
})();
