
   (function () {
    // Start tre card visibili in desktop Carousel cross selling small
    $(".experience-cards-agenzia .carousel-cards-agenzia").hide();
    let carouselExpAgenzie = window.alleanza.carouselExpAgenzie || {};
    carouselExpAgenzie.init = () => {
        if ($(".experience-cards-agenzia .swiper-container").length) {
            let swiperItems = $(".experience-cards-agenzia .swiper-container .swiper-slide");
            const carouselExpAgenzieSwiper = new Swiper(".experience-cards-agenzia .swiper-container", {
                slidesPerView: "auto",
                spaceBetween: 23,
                centeredSlides: window.innerWidth <= 375,
                centerInsufficientSlides: true,
                watchOverflow: true,
                updateOnWindowResize: true,
                observer: true,
                observeParents: true,
                height: 410,
                speed: 1000,
                navigation: {
                    nextEl: ".experience-cards-agenzia .next-agenzia",
                    prevEl: ".experience-cards-agenzia .prev-agenzia",
                },
                breakpoints: {
                    1439: {
                        slidesPerView: swiperItems.length == 4 ? 4 : 3,
                        spaceBetween: 24,
                        centeredSlides: false,
                    },
                    989: {
                        slidesPerView: 3,
                        spaceBetween: 22,
                        centeredSlides: false,
                    },
                    767: {
                        slidesPerView: "auto",
                        spaceBetween: 22,
                        centeredSlides: false,
                    },

                    374: {
                        slidesPerView: "auto",
                        spaceBetween: 12,
                        centeredSlides: true,
                    },
                },
            });

            // SPGI-1314 - Aggiunto if per nascondere le freccie del carosello
            var next = $(".experience-cards-agenzia .swiper--arrow--nav .next-agenzia");
            var prev = $(".experience-cards-agenzia .swiper--arrow--nav .prev-agenzia");
            if ((swiperItems.length <= 4 && window.innerWidth >= 1439) || swiperItems.length <= 3 ) {
                next.addClass("hidden");
                prev.addClass("hidden");
            }else{
                next.removeClass("hidden");
                prev.removeClass("hidden");
            }
            $(".experience-cards-agenzia .carousel-cards-agenzia").show();
        }
        // $(".experience-cards-agenzia .swiper-container").show();
    };
    // End tre card visibili in desktop Carousel cross selling small

    window.alleanza.carouselExpAgenzie = carouselExpAgenzie;
})();
