

// Animazioni experience finali per integrato.
// Le funzioni vengono richiamate dal js di backend.


// Animazioni Experience
var mql = window.matchMedia("(min-width: 768px)");
var mediaVideo, selezioneTab, primoVideo, srcVideoDesktop, srcVideoMobile;
var videoPresentazione = true;
// var isMobile = false;
var windowWidth = $(window).width();
var showCapIntermedio = false;
var step1;
var step2;
var step3;
var step4;
var step5;
var stepCAPIntermedio;

var xpwr;

function initStepsExperience() {
    step1 = $(".atterraggio-experience.exp-step01.animated");
    step2 = $(".experience-video-presentazione.exp-step02.animated");
    step3 = $(".experience-video-appuntamento.exp-step03.animated");
    step4 = $(".experience-video-loading.exp-step04.animated");
    step5 = $(".experience-video-agenzia.exp-step05.animated");
    stepCAPIntermedio = $(".atterraggio-experience-cap-intermedio.animated");
    xpwr = $(".atterraggio-experience.animated .atterraggio-experience-container .center-elements"); // STEP 01 - Animazione atterraggio experience

    containerAppuntamento = $(".container-appuntamento");

    var prosegui = xpwr.find(".alleanza-button");
    var atterraggioin = xpwr.find(".alleanza-text-input .font-locator");

    if (atterraggioin && prosegui) {
        atterraggioin.on("keyup", function () {
            var dInput = this.value;

            if (dInput.length > 0) {
                gsap.to(prosegui, {
                    autoAlpha: 1,
                    duration: 0.3
                });
                prosegui.removeAttr("disabled");
            } else {
                gsap.to(prosegui, {
                    autoAlpha: 0.4,
                    duration: 0.3
                });
                prosegui.attr("disabled", "disabled");
            }

            return false;
        });
    } // verifico che sia stato scritto qualcosa nel campo CAP, abilito il tasto prosegui


    var proseguiCapInt = $(".atterraggio-experience-cap-intermedio").find(".alleanza-button");
    var atterraggioinCapInt = $(".atterraggio-experience-cap-intermedio").find(".alleanza-text-input .font-locator");

    if (atterraggioinCapInt && proseguiCapInt) {
        atterraggioinCapInt.on("keyup", function () {
            var dInputCapInt = this.value;

            if (dInputCapInt.length > 0) {
                gsap.to(proseguiCapInt, {
                    autoAlpha: 1,
                    duration: 0.3
                });
                proseguiCapInt.removeAttr("disabled");
            } else {
                gsap.to(proseguiCapInt, {
                    autoAlpha: 0.4,
                    duration: 0.3
                });
                proseguiCapInt.attr("disabled", "disabled");
            }

            return false;
        });
    } //BRB
}

function AnimazioneAtterraggio() {
    var tl_exp01 = gsap.timeline();
    tl_exp01.set(xpwr.find('.logotipo'), {
        left: "-7px",
        width: "0px"
    }).fromTo(xpwr.find('.pittogramma'), {
        scale: 0.3,
        autoAlpha: 0
    }, {
        scale: 1,
        ease: Back.easeOut.config(2),
        autoAlpha: 1,
        duration: 0.6
    }).fromTo(xpwr.find('.logotipo'), {
        width: "0px",
        left: "-7px"
    }, {
        width: "208px",
        left: "0px",
        duration: 1
    }).fromTo(xpwr.find('.atterraggio-contents'), {
        height: '0px'
    }, {
        height: "auto",
        duration: 1
    }).fromTo(xpwr.find('.atterraggio-logo'), {
        scale: 1
    }, {
        scale: 0.7,
        duration: 1
    }, '-=1').fromTo(xpwr.find('.font-sans-display-2'), {
        y: 50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.2
    }, '-=0.2').fromTo(xpwr.find('.font-paragraph-2'), {
        y: 50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.2
    }).fromTo(xpwr.find('.alleanza-text-input'), {
        y: 50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.2
    }).fromTo(xpwr.find('.alleanza-button'), {
        y: 50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 0.4,
        duration: 0.2
    }).fromTo(xpwr.find('.link'), {
        y: 50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.2
    }).to($('.experience-full-wrapper .header--content--action'), {

        y: 0,
        autoAlpha: 1,
        duration: 0.2
    });
}
//click "prosegui" o "prosegui senza cap"
function atterraggioToPresentazione() {
    // console.log("atterraggio to presentazione");
    step2.show();
    var tltransitionStep1 = gsap.timeline({
        paused: true,
        onComplete: function onComplete() {
            $('.exp-step02 video').on('ended', function () {
                //$(".exp-step01").addClass("hidden");
                step1.hide();
                setTimeout(function () {
                    buttonContAnim();
                }, 100);
            });
        }
    })
        .from($('.experience-full-wrapper .header--content--logo'), {
            y: -50,
            autoAlpha: 1,
            duration: 0.2
        })
        .fromTo(step2, {
            autoAlpha: 0
        }, {
            autoAlpha: 1,
            duration: 0.1
        }, '-=1.2')
        .fromTo($('.experience-full-wrapper .header-light, .experience-full-wrapper .header--content--logo, .experience-full-wrapper .header--content--action'), {
            y: -50,
            autoAlpha: 0
        }, {
            y: 0,
            autoAlpha: 1,
            duration: 0.2
        }, '-=0.2')
        ;
    tltransitionStep1.play(0);
}
function transizioneHeaderInEntrata() {
    // console.log("in entrata");
    var tltransitionInEntrata = gsap.timeline({
        paused: true,
        onComplete: function onComplete() { }
    }).fromTo($('.experience-full-wrapper .header-light'), {
        y: -50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.2
    }, '-=0.2')
        .fromTo($('.header--content--action'), {
            y: -50,
            autoAlpha: 0
        }, {
            y: 0,
            autoAlpha: 1,
            duration: 0.2
        }, '-=0.2'
        );
    tltransitionInEntrata.play(0);
}
function transizioneHeaderInUscita() {
    let tltransitionInUscita = gsap
        .timeline({
            paused: true,
            onComplete: function () { },
        })
        .fromTo(
            $(".experience-full-wrapper .header-light"),
            { y: 0, autoAlpha: 1 },
            { y: -50, autoAlpha: 0, duration: 0.2 },
            "-=0.2"
        );
    tltransitionInUscita.play(0);
}
//animazione blu di transizione tra i vari step dell'expericence
function TransitionSlideBlue(onComplete) {
    var pageTransitionBlue = $(".page-transition.blue");
    var slideTransitionAnimation = gsap
        .timeline({
            paused: true,
            onComplete: onComplete,
        })
        .set(pageTransitionBlue, {
            css: {
                zIndex: 999,
            },
        })
        .fromTo(
            pageTransitionBlue,
            1.2,
            {
                scaleX: 0,
            },
            {
                scaleX: 1,
                transformOrigin: "left",
                ease: Power3.easeInOut,
            }
        )
        .fromTo(
            pageTransitionBlue,
            1.2,
            {
                scaleX: 1,
                autoAlpha: 1,
            },
            {
                scaleX: 0,
                autoAlpha: 1,
                transformOrigin: "right",
                ease: Power4.easeInOut,
            }
        );
    slideTransitionAnimation.play(0);
    slideTransitionAnimation.eventCallback("onStart", function () {
        setTimeout(function () {
            $(".js-alleanza-experience").hide();
        }, 800);
    });
}
function TransitionCapIntermedio() {
    var appCapAn = gsap
        .timeline({
            paused: true,
        })
        .fromTo(
            $(".atterraggio-experience-cap-intermedio .box-cap h2"),
            {
                y: 50,
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration: 0.2,
            }
        )
        .fromTo(
            $(".atterraggio-experience-cap-intermedio .box-cap h3"),
            {
                y: 50,
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration: 0.2,
            }
        )
        .fromTo(
            $(
                ".atterraggio-experience-cap-intermedio .box-cap .alleanza-text-input"
            ),
            {
                y: 50,
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration: 0.2,
            }
        )
        .fromTo(
            $(
                ".atterraggio-experience-cap-intermedio .box-cap .alleanza-button"
            ),
            {
                y: 50,
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 0.4,
                duration: 0.2,
            }
        );
    appCapAn.play(0);
    // console.log("fine transizione");
}

//3 dots animation in step 4
function dotsAnimation() {
    var dots = step4.find(".dots span.dot.moving");
    var tl = new TimelineMax({
        repeat: -1,
        repeatDelay: 0.05,
    });
    tl.staggerFromTo(
        dots,
        0.5,
        {
            autoAlpha: 0,
        },
        {
            autoAlpha: 1,
        },
        0.15
    );
} // animazione sui bottoni

function buttonContAnim() {
    $(".experience-video-presentazione .button-container").removeClass("hidden");

    mediaVideo = $(".video-presentazione.hidden").find("video").get(0);
    var originalSrc = mediaVideo.getAttribute("src");

    if (!originalSrc || originalSrc == null) {
        var dataSrc = mediaVideo.getAttribute("data-src");
        mediaVideo.setAttribute("src", dataSrc);
    }

    var buttonsAnim = gsap.timeline({
        onComplete: function onComplete() {
            $(".experience-video-presentazione .button-container .alleanza-button").addClass("animated");
        }
    }).fromTo($(".experience-video-presentazione .button-container-title"), {
        y: 50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.2
    }).fromTo($(".experience-video-presentazione #open-agenzia"), {
        y: 50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.2
    }).fromTo($(".experience-video-presentazione #open-recruitment"), {
        y: 50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.2
    }).fromTo($(".experience-video-presentazione #button-info"), {
        y: 50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.2
    }).fromTo($(".experience-video-presentazione #button-prodotti"), {
        y: 50,
        autoAlpha: 0
    }, {
        y: 0,
        autoAlpha: 1,
        duration: 0.2
    });
    buttonsAnim.play(0);
}

function buttonContAnimReverse() {
    //reverse buttons animation
    var buttonsAnimRev = gsap
        .timeline({
            paused: true,
        })
        .fromTo(
            $(".experience-video-presentazione .button-container-title"),
            {
                y: 50,
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration: 0.2,
            }
        )
        .fromTo(
            $(".experience-video-presentazione #open-agenzia"),
            {
                y: 50,
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration: 0.2,
            }
        )
        .fromTo(
            $(".experience-video-presentazione #open-recruitment"),
            {
                y: 50,
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration: 0.2,
            }
        )
        .fromTo(
            $(".experience-video-presentazione #button-info"),
            {
                y: 50,
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration: 0.2,
            }
        )
        .fromTo($(".experience-video-presentazione #button-prodotti"), {
            y: 50,
            autoAlpha: 0,
        }, {
            y: 0,
            autoAlpha: 1,
            duration: 0.2,
        }
        );
    buttonsAnimRev.reverse(0);
    $(".experience-video-presentazione .button-container").addClass(
        "hidden"
    );
}
// verifico che sia stato scritto qualcosa nel campo CAP, abilito il tasto prosegui
// let prosegui = xpwr.find(".alleanza-button");
// let atterraggioin = xpwr.find(".alleanza-text-input .font-locator");
// if (atterraggioin && prosegui) {
//     atterraggioin.on("keyup", function () {
//         var dInput = this.value;
//         if (dInput.length > 0) {
//             gsap.to(prosegui, { autoAlpha: 1, duration: 0.3 });
//             prosegui.removeAttr("disabled");
//         } else {
//             gsap.to(prosegui, { autoAlpha: 0.4, duration: 0.3 });
//             prosegui.attr("disabled", "disabled");
//         }
//         return false;
//     });
// }
// // verifico che sia stato scritto qualcosa nel campo CAP, abilito il tasto prosegui
// let proseguiCapInt = $(".atterraggio-experience-cap-intermedio").find(
//     ".alleanza-button"
// );
// let atterraggioinCapInt = $(".atterraggio-experience-cap-intermedio").find(
//     ".alleanza-text-input .font-locator"
// );
// if (atterraggioinCapInt && proseguiCapInt) {
//     atterraggioinCapInt.on("keyup", function () {
//         var dInputCapInt = this.value;
//         if (dInputCapInt.length > 0) {
//             gsap.to(proseguiCapInt, { autoAlpha: 1, duration: 0.3 });
//             proseguiCapInt.removeAttr("disabled");
//         } else {
//             gsap.to(proseguiCapInt, { autoAlpha: 0.4, duration: 0.3 });
//             proseguiCapInt.attr("disabled", "disabled");
//         }
//         return false;
//     });
// }
// //BRB
function AnimationStep1() {
    var mql = window.matchMedia("(min-width: 990px)");
    var container = $(".container-appuntamento");
    if (container.hasClass("experience-step-1")) {
        container.find(".step-1").show();
        container.find(".step-1").each(function (index) {
            var _this = this;
            var tl_mod_experience_step1 = gsap.timeline({
                scrollTrigger: {
                    trigger: this,
                    // toggleActions: "restart none none none",
                    start: "top 60%",
                },
            });
            tl_mod_experience_step1.fromTo(
                container.find(".bg-animated"),
                {
                    width: 0,
                },
                {
                    width: "100%",
                    duration: 0.5,
                }
            );
            tl_mod_experience_step1.fromTo(
                $(".alleanza-progress-bar"),
                {
                    y: -50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            );
            tl_mod_experience_step1.fromTo(
                container.find(".js-alleanza-experience-sticky-action"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            );
            if (mql.matches) {
                tl_mod_experience_step1
                    .fromTo(
                        $(this).find(".anim-1"),
                        {
                            y: 50,
                            autoAlpha: 0,
                        },
                        {
                            y: 0,
                            autoAlpha: 1,
                            duration: 0.3,
                        }
                    )
                    .fromTo(
                        $(this).find(".anim-2"),
                        {
                            y: 50,
                            autoAlpha: 0,
                        },
                        {
                            y: 0,
                            autoAlpha: 1,
                            duration: 0.3,
                        }
                    )
                    .fromTo(
                        $(this).find(".anim-3"),
                        {
                            y: 50,
                            autoAlpha: 0,
                        },
                        {
                            y: 0,
                            autoAlpha: 1,
                            duration: 0.3,
                        }
                    )
                    .fromTo(
                        $(this).find(".experience-image"),
                        {
                            scale: 0.5,
                            autoAlpha: 0,
                        },
                        {
                            scale: 1.03,
                            ease: Circ.easeOut,
                            autoAlpha: 1,
                        }
                    )
                    .to($(this).find(".experience-image"), {
                        scale: 1,
                        ease: Circ.easeIn,
                        autoAlpha: 1,
                    });
            } else {
                tl_mod_experience_step1
                    .fromTo(
                        $(this).find(".experience-image"),
                        {
                            scale: 0.5,
                            autoAlpha: 0,
                        },
                        {
                            scale: 1.03,
                            ease: Circ.easeOut,
                            autoAlpha: 1,
                        }
                    )
                    .to($(this).find(".experience-image"), {
                        scale: 1,
                        ease: Circ.easeIn,
                        autoAlpha: 1,
                    })
                    .fromTo(
                        $(this).find(".anim-1"),
                        {
                            y: 50,
                            autoAlpha: 0,
                        },
                        {
                            y: 0,
                            autoAlpha: 1,
                            duration: 0.3,
                        }
                    )
                    .fromTo(
                        $(this).find(".anim-2"),
                        {
                            y: 50,
                            autoAlpha: 0,
                        },
                        {
                            y: 0,
                            autoAlpha: 1,
                            duration: 0.3,
                        }
                    )
                    .fromTo(
                        $(this).find(".anim-3"),
                        {
                            y: 50,
                            autoAlpha: 0,
                        },
                        {
                            y: 0,
                            autoAlpha: 1,
                            duration: 0.3,
                        }
                    );
            }
            tl_mod_experience_step1.eventCallback(
                "onComplete",
                function () {
                    $(_this).addClass("bg_dark");
                }
            );
        });
    }
}

// var containerAppuntamento = $(".container-appuntamento");
var containerAppuntamento;

function Animationstep1Tostep2() {
    // console.log("step1 to step 2");
    containerAppuntamento.removeClass("experience-step-1");
    containerAppuntamento.addClass("experience-step-2");
    containerAppuntamento.find(".step-2").fadeIn();
    //step1 sparisce
    TweenMax.to(containerAppuntamento.find(".step-1"), {
        x: "-100%",
        duration: 0.3,
        onComplete: function onComplete() {
            containerAppuntamento.find(".step-1").hide();
        },
    });
}
function Animationstep2ToStep1() {
    // console.log("step 2 to step 1");
    containerAppuntamento.removeClass("experience-step-2");
    containerAppuntamento.addClass("experience-step-1");
    TweenMax.to(containerAppuntamento.find(".step-1"), {
        x: "0",
        duration: 0.3,
    });
}
function Animationstep3ToStep4() {
    // console.log("step 3 to step 4");
    containerAppuntamento.removeClass("experience-step-2");
    containerAppuntamento.addClass("experience-step-4");
    TweenMax.to(containerAppuntamento.find(".calendar.step-2"), {
        x: "-100%",
        duration: 0.3,
        onComplete: function onComplete() {
            containerAppuntamento.find(".calendar.step-2").hide();
        },
    });
    TweenMax.to(containerAppuntamento.find(".experience-form.step-4"), {
        left: "15px",
        duration: 0.3,
        onComplete: function onComplete() { },
    });
}
function Animationstep3ToStep2() {
    // console.log("step 3 to step 2");
    containerAppuntamento.removeClass("experience-step-3");
    containerAppuntamento.addClass("experience-step-2");
    TweenMax.to(containerAppuntamento.find(".calendar.step-2"), {
        x: "0",
        duration: 0.3,
    });
}
function AnimationStep4ToStep5() {
    // console.log("step 4 to 5");
    containerAppuntamento.removeClass("experience-step-4");
    containerAppuntamento.addClass("experience-step-5");
    var mql = window.matchMedia("(min-width: 990px)");
    var container_step5 = containerAppuntamento.find(".step-5");
    var tl_mod_experience_step4 = new TimelineMax();
    tl_mod_experience_step4.fromTo(
        containerAppuntamento.find(".bg-animated-4"),
        {
            width: 0,
        },
        {
            width: "100%",
            duration: 0.5,
            onComplete: function onComplete() {
                containerAppuntamento.find(".step-2").hide();
                containerAppuntamento.find(".step-4").hide();
                container_step5.show();
            },
        }
    );
    if (mql.matches) {
        tl_mod_experience_step4
            .fromTo(
                container_step5.find(".anim-1"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            )
            .fromTo(
                container_step5.find(".anim-2"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            )
            .fromTo(
                container_step5.find(".anim-3"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            )
            .fromTo(
                container_step5.find(".anim-4"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            )
            .fromTo(
                container_step5.find(".anim-5"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            )
            .fromTo(
                container_step5.find(".container-image"),
                {
                    scale: 0.5,
                    autoAlpha: 0,
                },
                {
                    scale: 1.03,
                    ease: Circ.easeOut,
                    autoAlpha: 1,
                }
            )
            .to(container_step5.find(".container-image"), {
                scale: 1,
                ease: Circ.easeIn,
                autoAlpha: 1,
            });
    } else {
        tl_mod_experience_step4
            .fromTo(
                container_step5.find(".container-image"),
                {
                    scale: 0.5,
                    autoAlpha: 0,
                },
                {
                    scale: 1.03,
                    ease: Circ.easeOut,
                    autoAlpha: 1,
                }
            )
            .to(container_step5.find(".container-image"), {
                scale: 1,
                ease: Circ.easeIn,
                autoAlpha: 1,
            })
            .fromTo(
                container_step5.find(".anim-1"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            )
            .fromTo(
                container_step5.find(".anim-2"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            )
            .fromTo(
                container_step5.find(".anim-3"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            )
            .fromTo(
                container_step5.find(".anim-4"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            )
            .fromTo(
                container_step5.find(".anim-5"),
                {
                    y: 50,
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.3,
                }
            );
    }
}
