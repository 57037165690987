(function () {
    // Start una card in pagina carousel cross selling large
    $('.slider-large-card').hide();
    let oneCardCarousel = window.alleanza.oneCardCarousel || {};
    oneCardCarousel.init = () => {
        if ($(".slider-large-card .swiper-container").length && !$(".vetrina-prodotti").length) {
            $('.slider-large-card ').each(function (index, element) {
                $(this).addClass('one' + index);
                $(".one" + index + " .swiper-pagination").addClass("one-pag-" + index);
                $(".one" + index + " .s3next-l").addClass("s3next-1" + index);
                $(".one" + index + " .s3prev-l").addClass("s3prev-1" + index);
                let swiperItems = $(".one" + index + " .swiper-container .swiper-slide");
                const eigthSectionSwiper = new Swiper(".one" + index + " .swiper-container", {
                    pagination: {
                        el: ".swiper-pagination.one-pag-" + index,
                        // dynamicBullets: true,
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".s3next-l.s3next-1" + index,
                        prevEl: ".s3prev-l.s3prev-1" + index,
                    },
                    centeredSlides: true,
                    slidesPerView: "auto",
                    spaceBetween: window.innerWidth <= 375 ? 100 : 400,
                    updateOnWindowResize: true,
                    observer: true,
                    observeParents: true,
                    speed: 1200,

                });
                // SPGI-1314 - Aggiunto if per nascondere le freccie del carosello
                var next = $(".slider-large-card.one" + index + " .swiper--arrow--nav .s3next-l.s3next-1" + index);
                var prev = $(".slider-large-card.one" + index + " .swiper--arrow--nav .s3prev-l.s3prev-1" + index);
                if (swiperItems.length != 0 && swiperItems.length <= 1) {
                    next.addClass("hidden");
                    prev.addClass("hidden");
                }
            });
        }
        $('.slider-large-card').show();
    };
    // End una card in pagina carousel cross selling large

    window.alleanza.oneCardCarousel = oneCardCarousel;
})();
