(function () {
  let stickyTabNav = window.alleanza.stickyTabNav || {};

  stickyTabNav.init = () => {
    const stickyTab = $(".sticky-action");
    const quotationNav = $(".sticky-action .sticky-info-preventivo");
    const quotationNavHeight = $(".sticky-action .sticky-info-preventivo").outerHeight(true);

    if (stickyTab.length) {
      // Sticky Tab Menu
      // const barratopHeight = $(".barratop").outerHeight();
      const stikyElement = $("#service-filter");
      let stickyTop = stikyElement.offset().top - 20;
      let stickyBottom = stikyElement.offset().top + stikyElement.height();

      window.addEventListener("scroll", function () {
        stikyOnScroll(stickyTop, stickyBottom);
      });

      $(".editParam").on("shown.bs.collapse", function () {
        const stickyTop2 = stikyElement.offset().top - 20;
        const stickyBottom2 = stikyElement.offset().top + stikyElement.height();
        stickyTop = stickyTop2;
        stickyBottom = stickyBottom2;
      });

      $(".editParam").on("hidden.bs.collapse", function () {
        const stickyTop3 = stikyElement.offset().top - 20;
        const stickyBottom3 = stikyElement.offset().top + stikyElement.height();
        stickyTop = stickyTop3;
        stickyBottom = stickyBottom3;
      });

      function stikyOnScroll(stickyTopN, stickyBottomN) {
        if (window.pageYOffset >= stickyTopN) {
          stikyElement.addClass("alleanza-sticky");
          $(".sticky-action .container.alleanza-sticky + .container-fluid").css(
            "padding-top",
            quotationNavHeight
          );
          // stikyElement.find(".quotation-price").slideUp();
        } else {
          stikyElement.removeClass("alleanza-sticky");
          $(".sticky-action .container-fluid").css("padding-top", "0");
          // stikyElement.find(".quotation-price").slideDown();
        }
        // if (window.pageYOffset >= stickyBottomN && $(window).width() < 776) {
        //   scrollToTop();
        // }
      }
      // END Sticky Tab Menu

      // Scroll to top and fade stikyElement
      let prevScrollpos = window.pageYOffset;
      function scrollToTop() {
        let currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
          stikyElement.fadeIn();
        } else {
          stikyElement.fadeOut();
        }
        prevScrollpos = currentScrollPos;
      }
      // END Scroll to top and fade stikyElement

      // Click on tabs and Scroll
      quotationNav.find(".nav-link").on("click", function () {
        scrollToSectionstickyTab();
      });
      // END Click on tabs and Scroll

      // ScrollToSectionstickyTab
      function scrollToSectionstickyTab() {
        $("html,body")
          .stop()
          .animate(
            {
              scrollTop: stickyTab.offset().top - barratopHeight - 40,
            },
            "slow"
          );
      }
      // END ScrollToSectionstickyTab
    }

    // SPGI-1312
    $('.card').on('shown.bs.collapse', function () {
      var panel = $(this).closest('.card');

      $('html, body').animate({
        scrollTop: panel.offset().top
      }, 0);
    });

  };

  window.alleanza.stickyTabNav = stickyTabNav;

})();