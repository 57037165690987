(function () {

    var mql = window.matchMedia("(min-width: 768px)");
    let firstBannerInHome = true;




    $( ".hero-banner , .banner-hero-boxed").each(function( index ) {

        let el = $(this);
        let tl_banner;
        let videoPlaying = false;

        if($('header').hasClass('animated') && firstBannerInHome){
            tl_banner = new TimelineMax({ paused:true });

            let is_tl_play = false;
            let bannerAnimationComplete = false;

            firstBannerInHome = false;

            var refreshId = setInterval(function(){ 
                if(is_tl_play){
                    clearInterval(refreshId);
                }
                if($(".header.animated").length === 0 || ($(".header.animated").length > 0 && $(".header.animated").hasClass('animation-ended'))){
                    tl_banner.play();
                    is_tl_play = true;
                } 
            }, 500);

        } else {
            // animazione banner allo scroll
            tl_banner = gsap.timeline({
                scrollTrigger: {
                    trigger: this,
                    markers: false,
                    start: "top 60%",
                }
            });
        }
        
        tl_banner.eventCallback("onComplete", () => {
            if(el.hasClass("video-animated")){
                videoAnimation();
            }
        });

        function commonTextsAnimation(){
            tl_banner
            .fromTo(el, { x: "-100%", autoAlpha: 0 }, { x: 0, ease: Power3.easeOut, autoAlpha: 1, duration: 0.5 })
            .fromTo(el.find(".caption-title"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2} )
            .fromTo(el.find(".caption-title-1"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2} )
            .fromTo(el.find(".main-title"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2} )
            .fromTo(el.find(".description"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2} )
            .fromTo(el.find(".agente"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2} )
            .fromTo(el.find(".alleanza-button"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2})
        }

        function commonFadeInImageAnimation(){
            tl_banner
            .fromTo(el.find(".image"), {scale: 0.4, autoAlpha: 0 }, { scale: 1 ,ease: Back.easeOut.config(2),  autoAlpha: 1, duration: 0.6})
            .fromTo(el.find(".image-mobile"), {scale: 0.4,  autoAlpha: 0 }, { scale: 1 ,ease: Back.easeOut.config(2),  autoAlpha: 1, duration: 0.6})
        }

        //hero banner animations
        if(el.hasClass("hb-animated")){
            commonTextsAnimation();
            commonFadeInImageAnimation();
            tl_banner
            .to(el.find(".image"), { scale: 1, ease: Circ.easeIn, autoAlpha: 1 })
        }

        //hero banner secondario animations
        if(el.hasClass("sec-animated")){
            commonTextsAnimation();
            if (mql.matches) {tl_banner.fromTo(el.find(".image"), {x: "150", y: "200",  scale: 0.4,  autoAlpha: 0 }, {x: "0", y: "0", scale: 1, ease: Power2.easeOut,  autoAlpha: 1, duration: 0.5})}
            else{tl_banner.fromTo(el.find(".image-mobile"), {x: "0", y: "200",  scale: 0.4,  autoAlpha: 0 }, {x: "0", y: "0", scale: 1, ease: Power2.easeOut,  autoAlpha: 1, duration: 0.5})
            .fromTo(el.find(".image-mobile-1"), {x: "0", y: "200",  scale: 0.4,  autoAlpha: 0 }, {x: "0", y: "20", scale: 1, ease: Power2.easeOut,  autoAlpha: 1, duration: 0.5})}
            tl_banner
            .fromTo(el.next(".box-white"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2} )
        }

        //banner boxed hero animations
        if(el.hasClass("bhb-animated")){
            commonTextsAnimation();
            tl_banner
            .fromTo(el.find(".image"), {x: "150", y: "200",  scale: 0.4,  autoAlpha: 0 }, {x: "0", y: "0", scale: 1, ease: Power2.easeOut,  autoAlpha: 1 })
            .fromTo(el.find(".image-mobile"), {x: "0", y: "200",  scale: 0.4,  autoAlpha: 0 }, {x: "0", y: "0", scale: 1, ease: Power2.easeOut,  autoAlpha: 1 })
        }

        function videoAnimation(){

            let v = el.find(".video-section").find("#bva")[0];

            if(v){
                setTimeout(function(){
                    if(v && v.readyState === 4 && !videoPlaying) {
                     gsap.fromTo(el.find(".images-section"), {scale: 1, autoAlpha: 1 }, { scale: 0 ,transformOrigin:"50% 50%" , ease: Back.easeOut.config(2),  autoAlpha: 0, duration: 0.6})
                     gsap.fromTo(el.find(".video-section"), {scale: 0, autoAlpha: 0 }, { scale: 1 ,transformOrigin:"50% 50%", ease: Back.easeOut.config(1),  autoAlpha: 1, duration: 0.6}, "-=0.3")
     
                     v.currentTime = 0;
     
                     v.play();
                     videoPlaying = true;
     
                     v.addEventListener('ended',function(){
     
                         v.pause();
                         videoPlaying = false;
                         // gsap.set(el.find(".images-section"), {scale: 0, autoAlpha: 0 });
                         gsap.fromTo(el.find(".video-section"), {scale: 1, autoAlpha: 1 }, { scale: 0 ,ease: Back.easeOut.config(1),  autoAlpha: 0, duration: 0.6})
                         gsap.fromTo(el.find(".images-section"), {scale: 0, autoAlpha: 0 }, { scale: 1 ,ease: Back.easeOut.config(2),  autoAlpha: 1, duration: 0.6}, "-=0.3")
     
                     }, false);
                    }
         
                 }, 1500);
             
            }

        }

        function exitAnimation(){
            let v = el.find(".video-section").find("#bva")[0];
            if(v){
                setTimeout(function(){
                    v.pause();
                    videoPlaying = false;
                    gsap.fromTo(el.find(".video-section"), {scale: 1, autoAlpha: 1 }, { scale: 0 ,ease: Back.easeOut.config(1),  autoAlpha: 0, duration: 0.6})
                    gsap.fromTo(el.find(".images-section"), {scale: 0, autoAlpha: 0 }, { scale: 1 ,ease: Back.easeOut.config(2),  autoAlpha: 1, duration: 0.6}, "-=0.3")
                }, 1000);
            }

        }
        
        if(el.hasClass("video-animated")){
    
            tl_banner
            .fromTo(el, { x: "-100%", autoAlpha: 0 }, { x: 0, ease: Power3.easeOut, autoAlpha: 1, duration: 0.4 })
            
            if (mql.matches) { tl_banner.fromTo(el.find(".image"), {scale: 0.4, autoAlpha: 0 }, { scale: 1 ,ease: Back.easeOut.config(2),  autoAlpha: 1, duration: 0.6})}
            else{
                tl_banner.fromTo(el.find(".image-mobile"), {scale: 0.4,  autoAlpha: 0 }, { scale: 1 ,ease: Back.easeOut.config(2),  autoAlpha: 1, duration: 0.6})
            }

            tl_banner
            .fromTo(el.find(".caption-title"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2} , "-=0.4")
            .fromTo(el.find(".main-title"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2} )
            .fromTo(el.find(".description"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2} )
            .fromTo(el.find(".agente"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2} )
            .fromTo(el.find(".alleanza-button"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.2})

            setTimeout(function(){
                el.mouseenter(function(){
                    el.addClass("video-mouse-hover");
                    if(!videoPlaying) videoAnimation()
                }).mouseleave(function(){
                    el.removeClass("video-mouse-hover");
                    exitAnimation()
                });
            }, 1000);

        }
    })


    function randomizeFloating(target,tl)
    {
        const can = target;

        // const randomX = random(10, 20);
        // const randomY = random(20, 30);
        // const randomDelay = random(0, 1);
        // const randomTime = random(1, 2);
        // const randomTime2 = random(2, 5);
        // const randomAngle = random(8, 12);

        const randomX = random(1, 5);
        const randomY = random(1, 2);
        // const randomDelay = random(0, 1);
        const randomTime = random(1, 3);
        // const randomTime2 = random(2, 3);
        // const randomAngle = random(8, 12);

        moveX(can, 1);
        moveY(can, 1);
        // moveY(can, -1);
        // rotate(can, 1);

        // function rotate(target, direction) {
        //     tl.to(target, randomTime2(), {
        //         rotation: randomAngle(direction),
        //         delay: randomDelay(),
        //         ease: Sine.easeInOut,
        //         onComplete: rotate,
        //         onCompleteParams: [target, direction * -1],
        //     });
        // }

        function moveX(target, direction) {
            tl.to(target, randomTime(), {
                x: randomX(direction),
                ease: Sine.easeInOut,
                onComplete: moveX,
                onCompleteParams: [target, direction * -1],
            });
        }

        function moveY(target, direction) {
            tl.to(target, randomTime(), {
                y: randomY(direction),
                ease: Sine.easeInOut,
                onComplete: moveY,
                onCompleteParams: [target, direction * -1],
            });
        }

        function random(min, max) {
            const delta = max - min;
            return (direction = 1) => (min + delta * Math.random()) * direction;
        }
    }

})();
