(function () {
    let vetrinaProdottiTab = window.alleanza.vetrinaProdottiTab || {};

    const chipsContainer = $(".vetrina-prodotti .chips-container");
    let chipButtons = chipsContainer.find("button");

    vetrinaProdottiTab.init = () => {
        // Vetrina Prodotti - selected first button
        let cardPrima;
        if (chipsContainer.length > 0 && chipButtons.length > 0) {
            $("#" + chipButtons[0].id).addClass("active-chip");
            $("#carousel-" + chipButtons[0].id).removeClass("hidden");
            window.alleanza.carouselVetrina.init(chipButtons[0].id);
            cardPrima = chipButtons[0].id;
        }
        chipsContainer.find("button").on('click', function (event) {
            let elem = $(this)[0].id;
            if (elem) {
                let button = elem;
                if (button === cardPrima) {
                    $("#" + button).addClass("active-chip");
                } else {
                    $("#" + cardPrima).removeClass("active-chip");
                    $("#carousel-" + cardPrima).addClass("hidden");
                    $("#carousel-" + button).removeClass("hidden");
                    cardPrima = button;
                    window.alleanza.carouselVetrina.init(button);
                }
            }
        });
    };

    window.alleanza.vetrinaProdottiTab = vetrinaProdottiTab;

})();