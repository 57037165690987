(function () {

    $( ".hero-banner-round.levels-animated" ).each(function( index ) {
        let tl_mod_hero_banner_round_levels = gsap.timeline({
            scrollTrigger: {
                trigger: this,
                // toggleActions: "restart none none none",
                start: "top 60%",
            },
        });

        floatingElement($(this));

        tl_mod_hero_banner_round_levels.set($(this).find(".banner-round-container"), {yPercent: -50, autoAlpha: 0 });
        tl_mod_hero_banner_round_levels.to($(this).find(".banner-round-container"), {yPercent: 0, autoAlpha: 1, duration: 0.5 });
        tl_mod_hero_banner_round_levels.fromTo($(this).find(".sticker"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        .fromTo($(this).find(".title"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        .fromTo($(this).find(".description"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        .fromTo($(this).find(".box-round .container-image"), {scale: 0.5,  autoAlpha: 0 }, { scale: 1, ease: Power3.easeOut,  autoAlpha: 1 })

        .fromTo($(this).find(".video-rectangle"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        // .fromTo($(this).find(".video-rectangle .text-anim-1"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        // .fromTo($(this).find(".video-rectangle .text-anim-2"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        // .fromTo($(this).find(".video-rectangle .text-anim-3"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
        
        mouseOnImage();
    });

    function mouseOnImage(){
        $(".hero-banner-round.levels-animated").find(".box-round .container-image").hover(
            function(){
                TweenMax.to(".hero-banner-round.levels-animated .box-round .container-image", 1.5, {scale: 1.03})
            },
            function(){
                TweenMax.to(".hero-banner-round.levels-animated .box-round .container-image", 1.5, {scale: 1})
            }
        );
    }

    function floatingElement(container) {
        tweenProperty(container.find(".pos1 img"), 'x', -10, 20);
        tweenProperty(container.find(".pos1 img"), 'y', -4, 2);
        tweenProperty(container.find(".pos2 img"), 'x', -5, 5);
        tweenProperty(container.find(".pos2 img"), 'y', -20, 0);
        tweenProperty(container.find(".pos2 img"), 'rotation', -20, 0);
        tweenProperty(container.find(".pos3 img"), 'x', -10, 30);
        tweenProperty(container.find(".pos3 img"), 'y', 0, 20);
        tweenProperty(container.find(".pos3 img"), 'rotation', 0, 5);
    }

    function tweenProperty(target, prop, min, max) {
        var randomDur = gsap.utils.random(3, 7, 0.2, true);
        let randomDelay = gsap.utils.random(0.2, 0.8, 0.2, true);
    
        gsap.to(target,  {
            [prop]: gsap.utils.random(min, max),
            duration: randomDur(), 
            delay: randomDelay(), 
            ease: 'Power1.easeInOut',
            onComplete: tweenProperty,
            onCompleteParams: [target, prop, min, max]
        });
    }

})();
