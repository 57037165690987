// On resize
$(window).resize(function () {
    if ($('.trova-agenzia.animated').length > 0) {
        alignCardsHeight();
    }
});

// Init
setTimeout(function () {
    alignCardsHeight();
    $('.trova-agenzia.animated .ta-results').removeClass('loading').hide();

    // Show on scroll
    $( ".trova-agenzia.animated" ).each(function( index ) {
        let tl_mod_trova_agenzia = gsap.timeline({
            scrollTrigger: {
                trigger: this,
                markers: false,
                // toggleActions: "restart none none none",
                start: "top 60%",
            },
        });

        tl_mod_trova_agenzia.fromTo($(this).find(".ta-title"), { y: 50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
            .fromTo($(this).find(".ta-search"), { y: -50, autoAlpha: 0 }, { y: 0, autoAlpha: 1 , duration: 0.3} )
    });

}, 1000);

// Set the same height to all cards
function alignCardsHeight() {
    $('.trova-agenzia.animated .ta-results .ta-card').css('height', '');
    var maxHeight = 0;

    $('.trova-agenzia.animated .ta-results .ta-card').each(function (i, card) {
        if ($(card).outerHeight() > maxHeight) {
            maxHeight = $(card).outerHeight();
        }
    });

    $('.trova-agenzia.animated .ta-results .ta-card').css('height', maxHeight + 'px');
}

// Search
$('.trova-agenzia.animated .ta-search .ta-search-button button').bind('click', function () {
    showAgenziaOnMap();
});

// Clear search field
$('.trova-agenzia.animated .ta-search .ta-search-input .alleanza-text-input i.input-icon').bind('click', function () {
    $(this).closest('.alleanza-text-input').find('input').removeClass('is-dirty').val('');
});

// Click on card
$('.trova-agenzia.animated .ta-results .ta-card').bind('click', function () {
    $(this).closest('.trova-agenzia.animated').find('.ta-results .ta-card').removeClass('active');
    $(this).addClass('active');

    showAgenziaOnMap();
});

// Place the pin on map
function showAgenziaOnMap() {
    var $card = $('.trova-agenzia.animated .ta-results .ta-card.active');

    var x = $card.data('coordinates-x');
    var y = $card.data('coordinates-y');

    // $card.closest('.trova-agenzia').find('.ta-map img.pin').css({'top': x + 'px', 'left': y + 'px'});
    $card.closest('.trova-agenzia.animated').find('.ta-map img.pin.map-pin-active').css({ 'top': x + 'px', 'left': y + 'px' });
}

function showMap(fitBounds) {
    var tlm_map = new TimelineMax();
    var main = $(".trova-agenzia.animated");
    var mapContainer = main.find('.cont-ta-map');
    tlm_map.add(TweenMax.fromTo(mapContainer, {
        height: 0
    }, {
        height: '450px',
        ease: Power3.easeOut,
        duration: 0.7
    }), 0);

    if (fitBounds){
        tlm_map.add(fitBounds,0);
    }

    tlm_map.add(TweenMax.to(main.find('.cont-ta-map .bg-animated'), 0.7, {
        top:"100%",
        ease: Power3.easeOut
    }), 0.5);

    var first3 = main.find(".ta-card").slice(0, 3);
    var others = main.find(".ta-card").slice(3);
    var cardsContainer = main.find(".swiper-container");
    var carouselItem = main.find(".ta-card");
    var prevArrow = main.find(".swiper-button-prev");
    var nextArrow = main.find(".swiper-button-next");
    var pagination = main.find(".swiper-pagination");
    var arrayValues = [prevArrow, first3, nextArrow, pagination, others];
    tlm_map
        .fromTo(cardsContainer, { y:"-100%",autoAlpha: 0 }, {y:0, autoAlpha: 1, opacity: 1, duration: 0.2 })
        // .staggerFromTo(arrayValues, 0.5, { y: "-100%", autoAlpha: 0 }, { y: 0, autoAlpha: 1, opacity: 1 }, 0.5)
}
