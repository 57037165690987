// Start Module: alleanza Video Kaltura
(function() {
  let alleanzaKaltura = window.alleanza.alleanzaKaltura || {};

  alleanzaKaltura.init = () => {

    if (!$(".carousel-media .swiper-container").length && !$(".experience-lavora-con-noi").length ) {
      $(".kalturaPlayer").each(function() {    
        
        let intoBannerRound = $(".hero-banner-round #"+$(this).attr("id")).length;
        let poster =  $(this).data("poster");
        let played;
        
        kWidget.embed({
          targetId: $(this).attr("id"),
          wid: "_"+$(this).data("partnerid"),
          uiconf_id: $(this).data("uiconfid"),
          // cache_st: 1437579475,
          entry_id: $(this).data("video"),
          flashvars: {
            'controlBarContainer.plugin': intoBannerRound <= 0,
            controlBarContainer: {
              hover: false,
            },
          },
          'readyCallback': function(playerId){
            var kdp = document.getElementById(playerId);
            // init button custom + poster
            $(document).on("ready", function(){
              $('#'+playerId+'_ifp').on("ready",function(){
                  $('#'+playerId+'_ifp').contents().find('.largePlayBtn').css({"color": "#FF8200", "background-color": "white", "background": "white", "border-radius": "50%", 
                  "padding": "17px 15px 17px 20px"});
                  if(poster){
                    $('#'+playerId+'_ifp').contents().find('.mwEmbedPlayer img').attr("src", poster);
                    $('#'+playerId+'_ifp').contents().find('.mwEmbedPlayer img').css({"height": "100%", "width": "100%", "object-fit": "cover"});
                    $('#'+playerId+'_ifp').contents().find('.nativeEmbedPlayerPid').attr("poster", poster);
                  }
              });
            });
            // stop video se fuori inquadratura
            $(window).on("scroll",function() {
              var top_of_element = $(".alleanza-video-media").offset().top;
              var bottom_of_element = $(".alleanza-video-media").offset().top + $(".alleanza-video-media").outerHeight();
              var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
              var top_of_screen = $(window).scrollTop();

              if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                // Vedo
              } else {
                // Non vedo
                kdp.sendNotification("doPause");
              }
            });

            // stop video a cambio card vertical accordion
            $('.vertical-accordion .va-header').on('click', function() {
              kdp.sendNotification("doPause");
            });      
            
            if(intoBannerRound > 0){
            // gestione full screen per banner round
              kdp.kBind("doPlay", function(){
                kdp.sendNotification('openFullScreen'); 
              }); 
              kdp.kBind("doPause", function(){
                kdp.sendNotification('closeFullScreen');

              });
              kdp.kBind("openFullScreen", function(){
                $("#fslog").append("openFullScreen\n");
              });
              kdp.kBind("closeFullScreen", function(){
                $("#fslog").append("closeFullScreen\n");                
              });
            }
            kdp.kBind("doPlay", function(){
              played = kdp; 
            }); 
          }
        });
      });
    }
  };

  window.alleanza.alleanzaKaltura = alleanzaKaltura;
})();
