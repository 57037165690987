// Start Module: alleanza Video
(function () {
  let alleanzaLavoraConNoiVideo = window.alleanza.alleanzaLavoraConNoiVideo || {};

  alleanzaLavoraConNoiVideo.init = () => {
    if ($(".experience-lavora-con-noi").length) {
      let mediaVideo, fullscreen, playIcon;

      //Ambassador
      $(".experience-lavora-con-noi .lavora-con-noi-3 .play-icon").on("click", function (e) {
        playIcon = $(this);
        mediaVideo = playIcon.find("video").get(0);
        fullscreen = mediaVideo.webkitRequestFullscreen || mediaVideo.mozRequestFullScreen || mediaVideo.msRequestFullscreen || mediaVideo.webkitEnterFullScreen;
        fullscreen.call(mediaVideo);
        playIcon.find('.alleanza-video-lavora').removeClass('hidden');
        mediaVideo.play();
      });

      $(".experience-lavora-con-noi .lavora-con-noi-3 .play-icon video").on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function (e) {
        var isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        if (!isFullScreen) {
          mediaVideo.pause();
          mediaVideo.currentTime = 0;
          $(".experience-lavora-con-noi .lavora-con-noi-3 .play-icon").find('.alleanza-video-lavora').addClass('hidden');
        }
      });

      $(".experience-lavora-con-noi .lavora-con-noi-3 .agenti-foto img").on("click", function (e) {
        playIcon = $(this).parents(".agenti-foto").find(".play-icon");
        mediaVideo = playIcon.find("video").get(0);

        var originalSrc = mediaVideo.getAttribute("src");
        if(!originalSrc || originalSrc == null){
          var dataSrc = mediaVideo.getAttribute("data-src");
          mediaVideo.setAttribute("src", dataSrc);
        }

        fullscreen = mediaVideo.webkitRequestFullscreen || mediaVideo.mozRequestFullScreen || mediaVideo.msRequestFullscreen || mediaVideo.webkitEnterFullScreen
        fullscreen.call(mediaVideo);
        playIcon.find('.alleanza-video-lavora').removeClass('hidden');
        mediaVideo.play();
      });

      // var mediaVideo = $(this).get(0);
      // var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
      // var event = state ? 'FullscreenOn' : 'FullscreenOff';
      // if (event === 'FullscreenOff') {
      //   mediaVideo.pause();
      //   $(".experience-lavora-con-noi .lavora-con-noi-3 .play-icon").find('.alleanza-video-lavora').addClass('hidden');
      //   mediaVideo.currentTime = 0;
      // }
      // else {
      //   let clickPlay = true;
      //   $(".experience-lavora-con-noi .text-break .play-icon  video").on('click', function () {
      //     if (clickPlay) {
      //       mediaVideo.pause();
      //       clickPlay = false;
      //     } else {
      //       mediaVideo.play();
      //       clickPlay = true;
      //     }
      //   });
      // }

      $(".experience-lavora-con-noi .text-break .play-icon").on("click", function (e) {
        playIcon = $(this);
        mediaVideo = playIcon.find("video").get(0);

        var originalSrc = mediaVideo.getAttribute("src");
        if(!originalSrc || originalSrc == null){
          var dataSrc = mediaVideo.getAttribute("data-src");
          mediaVideo.setAttribute("src", dataSrc);
        }
        
        fullscreen = mediaVideo.webkitRequestFullscreen || mediaVideo.mozRequestFullScreen || mediaVideo.msRequestFullscreen || mediaVideo.webkitEnterFullScreen
        fullscreen.call(mediaVideo);
        playIcon.find('.alleanza-video-lavora').removeClass('hidden');
        playIcon.find('.image-lavora').addClass('hidden');
        mediaVideo.play();
      });

      $(".experience-lavora-con-noi .text-break .play-icon  video").on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function (e) {
        var isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        if (!isFullScreen) {
          mediaVideo.pause();
          mediaVideo.currentTime = 0;
          // per mettere l'immagine all'uscita del fullscreen
          $('.experience-lavora-con-noi .text-break .play-icon .alleanza-video-lavora').addClass('hidden');
          $('.experience-lavora-con-noi .text-break .play-icon .image-lavora').removeClass('hidden');
        }
        // var mediaVideo = $(this).get(0);
        // var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        // var event = state ? 'FullscreenOn' : 'FullscreenOff';
        // if (event === 'FullscreenOff') {
        //   mediaVideo.pause();
        //   // per mettere l'immagine all'uscita del fullscreen
        //   $('.experience-lavora-con-noi .text-break .play-icon .alleanza-video-lavora').addClass('hidden');
        //   $('.experience-lavora-con-noi .text-break .play-icon .image-lavora').removeClass('hidden');
        // }
        //  else {
        //   let clickPlay = true;
        //   $(".experience-lavora-con-noi .text-break .play-icon  video").on('click', function () {
        //     if (clickPlay) {
        //       mediaVideo.pause();
        //       clickPlay = false;
        //     } else {
        //       mediaVideo.play();
        //       clickPlay = true;
        //     }
        //   });
        // }
      });
    }
  };
  window.alleanza.alleanzaLavoraConNoiVideo = alleanzaLavoraConNoiVideo;
})();