(function () {
    //carousel upselling e carousel generic
    $(".carousel-animated").each(function() {
        let tl_carousel = gsap.timeline({
            scrollTrigger: {
                trigger: this,
                start:"top 60%",
                markers: false,
                once:true,
            }
        });
        let carouselTitle = $(this).find(".carousel-animated-title");
        let cardsContainer = $(this).find(".swiper-container");
        let carouselItem = $(this).find(".card-active");
        // let prevArrow = $(this).find(".swiper-button-prev");
        // let nextArrow = $(this).find(".swiper-button-next");
        let arrows = $(this).find(".swiper--arrow--nav");
        let dots = $(this).find(".swiper-pagination");

        let arrayValues1 = [carouselTitle, carouselItem];
        let arrayValues2 = [arrows, dots];

        tl_carousel.set(dots, {autoAlpha:0});

        tl_carousel
        .fromTo(this, {autoAlpha:0},  { autoAlpha:1, opacity:1, duration:0.3, delay:0.2})
        .staggerFromTo(arrayValues1, 0.2, {y: 50, autoAlpha:0}, {y:0,autoAlpha:1}, 0.3)
        .staggerFromTo(arrayValues2, 0.2, {y: 50, autoAlpha:0}, {y:0,autoAlpha:1}, 0.3)

        // tl_carousel.fromTo(this, {autoAlpha:0},  { autoAlpha:1, opacity:1, duration:1, delay:0.4})
        //     .staggerFromTo(carouselTitle,0.5,{y:"-100%", autoAlpha:0}, {y:0,autoAlpha:1, opacity:1}, 0.5)
        //     .fromTo(cardsContainer, {autoAlpha:0}, { autoAlpha:1, opacity:1, duration:0.5})
        //     .staggerFromTo(arrayValues,0.5,{y:100, autoAlpha:0}, {y:0,autoAlpha:1, opacity:1}, 0.5)
        //     .to(dots, {autoAlpha:1, opacity:1, duration:0.5})
    });
    //vetrina prodotti
    $(".vetrina-prodotti.animated").each(function() {
        let tl_vetrina_prodotti_carousel = gsap.timeline({
            scrollTrigger: {
                trigger: this,
                start:"top 60%",
                markers: false
            }
        });
        let captionTitle = $(this).find(".caption-title");
        let title = $(this).find(".title");
        let chips = $(this).find(".chips-container");
        let cardsContainer = $(this).find(".carousels-card:first"); //il primo carosello
        let carouselItem = cardsContainer.find(".swiper-slide:first"); //la prima card del primo carosello
        // let prevArrow = cardsContainer.find(".swiper-button-prev");
        // let nextArrow = cardsContainer.find(".swiper-button-next");
        let arrows = cardsContainer.find(".swiper--arrow--nav");
        let dots = cardsContainer.find(".swiper-pagination");

        // let arrayValues1 = [captionTitle, title, chips];
        // let arrayValues2 = [prevArrow, carouselItem, nextArrow];

        let arrayValuesV = [captionTitle, title, chips, cardsContainer, arrows, dots]

        tl_vetrina_prodotti_carousel
        .fromTo(this, {autoAlpha:0},  { autoAlpha:1, opacity:1, duration:0.3, delay:0.2})
        .staggerFromTo(arrayValuesV, 0.2, {y: 50, autoAlpha:0}, {y:0,autoAlpha:1}, 0.2)

        // .fromTo(captionTitle, 0.2, {y: 100, autoAlpha:0 }, {y:0, autoAlpha:1})
        // .fromTo(title, 0.2, {y: 100, autoAlpha:0 }, {y:0, autoAlpha:1})
        // .fromTo(chips, 0.2, {y: 100, autoAlpha:0 }, {y:0, autoAlpha:1})
        // .fromTo(cardsContainer, 0.3, {y: 100, autoAlpha:0 }, {y:0, autoAlpha:1})
        // .fromTo(arrows, 0.2, {y: 100, autoAlpha:0 }, {y:0, autoAlpha:1})
        // .fromTo(pagination, 0.2, {y: 100, autoAlpha:0 }, {y:0, autoAlpha:1})

        // tl_vetrina_prodotti_carousel.fromTo(this, {autoAlpha:0},  { autoAlpha:1, opacity:1, duration:0.5, delay:0.2})
        //     .staggerFromTo(arrayValues1, 0.5, {y:"-100%", autoAlpha:0}, {y:0,autoAlpha:1, opacity:1}, 0.5)
        //     .fromTo(cardsContainer, {autoAlpha:0}, { autoAlpha:1, opacity:1, duration:0.2})
        //     .staggerFromTo(arrayValues2,0.3,{y:"-100%", autoAlpha:0}, {y:0,autoAlpha:1, opacity:1}, 0.3)
    });
}());
