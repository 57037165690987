//Gestione Lunghezza input

$(".pip-step-3-new input.input-style").each(function(){
    $(this).width((parseFloat($(this).css('font-size')))*0.6*($(this).val().length));
});

$(".pip-step-3-new input.input-style").on("input", function(){
    $(this).width((parseFloat($(this).css('font-size')))*0.6*($(this).val().length));
});

$(".pip-step-3-new input.input-style-second-input").on("input", function(){
    $(this).width((parseFloat($(this).css('font-size')))*0.6*($(this).val().length));
});


//Gestione Tooltip

$(".product-title").on("click", function(){
    if($(this).find(".tooltip").css("display")=="none"){
        $(this).find(".tooltip").css("display","block");
        var tooltip = $(this).find(".tooltip");
        setTimeout(() => {
            $(document).on("click", function hideTooltip(event){
                if (!$(event.target).hasClass("tooltip") && !$(event.target).parent().hasClass("tooltip")) {
                    tooltip.css("display", "none");
                    $(document).off("click", hideTooltip);
                }
            });
        }, 20);
    }
});


//Gestione Bottone Tablet e Mobile

if ($(".button-appuntamento-margin").length > 0 && $(".checkbox-alignment").length > 0){
    $(document).on("scroll", function(){
        var scrollTop = $(".checkbox-alignment").offset().top;
        var buttonTop = $(".button-appuntamento-margin").offset().top;
        var scrollHeight = $(window).scrollTop() + $(window).height();
        if (buttonTop >= scrollTop && $(".button-appuntamento-margin").hasClass("scroll-button-class")) {
            $(".button-appuntamento-margin").removeClass("scroll-button-class");
        } else if (buttonTop > scrollHeight && !$(".button-appuntamento-margin").isInViewport() && !$(".button-appuntamento-margin").hasClass("scroll-button-class")) {
            $(".button-appuntamento-margin").addClass("scroll-button-class");
    }   });
}

$.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

