(function () {
    // Start swiper trova agenzia
    let trovaAgenziaCarousel = window.alleanza.trovaAgenziaCarousel || {};
    trovaAgenziaCarousel.init = () => {
        if ($(".slider-trova-agenzia .swiper-container").length) {
            $(".slider-trova-agenzia").addClass('ta-sw');
            $(".ta-sw .swiper-pagination").addClass("ta-pag-sw");
            $(".ta-sw .s3next").addClass("s3next-ta");
            $(".ta-sw .s3prev").addClass("s3prev-ta");
            var swiperItems = $(".slider-trova-agenzia .swiper-container .swiper-slide");
            const eigthSectionSwiper = new Swiper(".slider-trova-agenzia .swiper-container", {
                slidesPerView: "auto",
                spaceBetween: 12,
                centeredSlides: window.innerWidth <= 375,
                watchOverflow: true,
                centerInsufficientSlides: true,
                observer: true,
                observeParents: true,
                updateOnWindowResize: true,
                speed: 500,
                pagination: {
                    el: ".swiper-pagination.ta-pag-sw",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".s3next.s3next-ta",
                    prevEl: ".s3prev.s3prev-ta",
                },
                breakpoints: {
                    1439: {
                        slidesPerView: 3,
                        spaceBetween: 34,
                    },
                    989: {
                        slidesPerView: 3,
                        spaceBetween: 34,
                    },
                    767: {
                        slidesPerView: 2.5,
                        spaceBetween: 10,
                    },
                    374: {
                        slidesPerView: "auto",
                        spaceBetween: 10,
                        centeredSlides: true,
                        centerInsufficientSlides: false,
                    },
                },
            });

            // SPGI-1314 - Aggiunto if per nascondere le freccie del carosello
            var next = $(".slider-trova-agenzia .swiper--arrow--nav .s3next.s3next-ta");
            var prev = $(".slider-trova-agenzia .swiper--arrow--nav .s3prev.s3prev-ta");
            if (swiperItems.length != 0 && swiperItems.length <= 3) {
                next.addClass("hidden");
                prev.addClass("hidden");
            }
        }

    };
    // End swiper trova agenzia

    window.alleanza.trovaAgenziaCarousel = trovaAgenziaCarousel;
})();
