var va = {
    positionTop: 0,
    positionBottom: 0,
    currentScroll: 0
}

$(document).on("ready",function () {
    vaInit();
});

$(window).on("scroll",function () {
    va.currentScroll = $(window).scrollTop();

    vaScrollTitles();
});

// Change accordion item
$('.vertical-accordion .va-header').on('click', function () {
    // Get breakpoint
    var breakpoint = vaGetBreakpoint($(this));

    var $li = $(this).closest('li.va-li');

    if (breakpoint == 'desktop') {
        if (!$li.hasClass('active')) {
            $li.siblings('li.active').find('.va-hidden-content').css('max-height', '');
            $li.siblings('li.active').removeClass('active show-all');

            $li.addClass('active');
        }
    }
    else if (breakpoint == 'mobile') {
        if (!$li.hasClass('active')) {
            $li.siblings('li.active').find('.va-hidden-content').css('max-height', '');

            $li.siblings('li.active').find('.va-content').slideUp(300, function () {
                $li.siblings('li.active').removeClass('active show-all');
            });

            $li.find('.va-content').slideDown(300, function () {
                $li.addClass('active');
            });
        }
    }

    vaInit();
});

// Toggle hidden content
$('.vertical-accordion .va-toggle-hidden-content span').on('click', function () {
    // Get breakpoint
    var breakpoint = vaGetBreakpoint($(this));

    var $li = $(this).closest('li.va-li');
    var $content = $li.find('.va-content .va-hidden-content');
    var $wrapper = $li.find('.va-content .va-content-wrapper');

    if ($li.hasClass('show-all')) {
        $wrapper.removeClass("set-scrollbar");
        // Unset height
        $content.css('max-height', '');
        // setTimeout(function () {
            $li.removeClass('show-all');
        // }, 300);

    }
    else {
        $li.addClass('show-all');
        $wrapper.addClass("set-scrollbar");

        // Set height
        var height = Math.ceil($content.find('.va-hidden-content-wrapper').height() + 20);
        $content.css('max-height', height + 'px');
    }

    vaInit();
});

// Get breakpoint
function vaGetBreakpoint($el) {
    var $breakpoints = $el.closest('.vertical-accordion').find('.breakpoints');

    if ($breakpoints.find('.desktop').is(':visible')) {
        return 'desktop';
    }

    if ($breakpoints.find('.mobile').is(':visible')) {
        return 'mobile';
    }

    return '';
}

function vaInit() {
    if ($('.vertical-accordion').position()) {
        va.positionTop = Math.floor($('.vertical-accordion').position().top);
        va.positionBottom = Math.floor(va.positionTop + $('.vertical-accordion').outerHeight() - window.innerHeight);

        $('.vertical-accordion ul.va-ul li.va-li .va-header .va-scrollable').css('top', '');

        vaScrollTitles();
    }
}

function vaScrollTitles() {
    if ((va.currentScroll >= va.positionTop) && (va.currentScroll <= va.positionBottom)) {
        var delta = va.currentScroll - va.positionTop;
        $('.vertical-accordion ul.va-ul li.va-li .va-header .va-scrollable').css('top', delta + 'px');
    }
}