(function () {
    // start carousel quote
    let carouselQuote = window.alleanza.carouselQuote || {};

    carouselQuote.init = () => {
        if ($(".slider-quote .swiper-container").length) {
            $('.slider-quote').each(function (index, element) {
                $(this).addClass('quote' + index);
                $(".quote" + index + " .swiper-pagination").addClass("q" + index);
                const eigthSectionSwiper = new Swiper(".quote" + index + " .swiper-container", {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    centeredSlides: true,
                    slidesOffsetBefore: 75,
                    watchOverflow: false,
                    updateOnWindowResize: true,
                    observer: true, 
                    observeParents: true,
                    speed: 1000,
                    pagination: {
                        // el: ".swiper-pagination",
                        el: ".swiper-pagination.q" + index,
                        dynamicBullets: false,
                        clickable: true,
                    },
                    breakpoints: {
                        1439: {
                            slidesPerView: 1.9,
                            spaceBetween: 20,
                            slidesOffsetBefore: 25,
                        },
                        1240: {
                            slidesPerView: 1.7,
                            spaceBetween: 20,
                            slidesOffsetBefore: 25,
                        },
                        989: {
                            slidesPerView: 1.5,
                            spaceBetween: 20,
                            slidesOffsetBefore: 110,
                        },
                        767: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                            slidesOffsetBefore: 90,
                        },
                        374: {
                            slidesPerView: "auto",
                            spaceBetween: 12,
                            slidesOffsetBefore: 90,
                            centeredSlides: true,
                        }
                    },
                });
            });
        }
    };
    // end carousel quote

    window.alleanza.carouselQuote = carouselQuote;
})();
