(function () {
    $(".fondi-gestioni.animated").each(function(){
        let tl_quotazioni_section = gsap.timeline({
            scrollTrigger:{
                trigger: this,
                markers:false,
                start: "top 80%",
                //toggleActions: "restart none none reset"
            }
        });
        let quotazionilTitle = $(this).find(".fondi-gestioni-title");
        let quotazionilItems = $(this).find(".quotazioni-specifiche__data-container");
        let showMoreButton = $(this).find(".alleanza-show-more-button");
        let arrayValues = [this,quotazionilTitle, quotazionilItems, showMoreButton];

        tl_quotazioni_section
            .staggerFromTo(arrayValues,0.3,{y:100, autoAlpha:0}, {y:0,autoAlpha:1, opacity:1}, 0.5)
    });
}());
