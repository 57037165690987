(function () {
    // start carousel vetrina prodotti
    let carouselVetrina = window.alleanza.carouselVetrina || {};
    carouselVetrina.init = (id) => {
        $('.slider-large-card').hide();
        if ($("#carousel-" + id + " .swiper-container").length) {
            // per diversificare la paginazione e le frecce utilizzo gli id
            $("#carousel-" + id + " .swiper-pagination").addClass("pag-" + id);
            $("#carousel-" + id + " .s3next-l").addClass("s3next-" + id);
            $("#carousel-" + id + " .s3prev-l").addClass("s3prev-" + id);
            let swiperItems = $("#carousel-" + id + " .swiper-container .swiper-slide");
            const eigthSectionSwiper = new Swiper("#carousel-" + id + " .swiper-container", {
                observer: true,
                observeParents: true,
                centeredSlides: true,
                updateOnWindowResize: true,
                slidesPerView: "auto",
                speed: 1200,
                spaceBetween: 250,
                pagination: {
                    el: ".swiper-pagination.pag-" + id,
                    clickable: true,
                },
                navigation: {
                    nextEl: ".s3next-l.s3next-" + id,
                    prevEl: ".s3prev-l.s3prev-" + id,
                },
            });

            var next = $("#carousel-" + id + " .swiper--arrow--nav .s3next-l.s3next-" + id);
            var prev = $("#carousel-" + id + " .swiper--arrow--nav .s3prev-l.s3prev-" + id);
            if (swiperItems.length != 0 && swiperItems.length <= 1) {
                next.addClass("hidden");
                prev.addClass("hidden");
            }
        }
        $('.slider-large-card').show();
    };
    // end carousel vetrina prodotti

    window.alleanza.carouselVetrina = carouselVetrina;
})();
