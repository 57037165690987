// Start Module: experience
(function () {
    let experienceVideo = window.alleanza.experienceVideo || {};

    experienceVideo.init = () => {
        // start video scelta experience
        let mediaVideo, selezioneTab, primoVideo, srcVideoDesktop, srcVideoMobile;
        let videoPresentazione = true;
        let isMobileExp = false;
        let windowWidth = $(window).width();

        let nav = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        if (window.innerHeight > window.innerWidth && nav) {
            isMobileExp = true;
        }

        function viewportScreen(obj) {
            let nav = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            srcVideoDesktop = $(".experience-video-" + obj + " .video-container source").attr("data-srcvideo") || $(".experience-video-" + obj + " .video-container source").attr("src");
            srcVideoMobile = $(".experience-video-" + obj + "  .video-container source").attr("data-srcmobile");
            // if (window.innerHeight > window.innerWidth && window.matchMedia("(max-width: 990px)").matches && nav) {
            if (window.innerHeight > window.innerWidth && nav) {
                // mobile con height maggiore della width
                isMobileExp = true;
                $(".experience-video-" + obj + "  .video-container source").removeAttr("src");
                $(".experience-video-" + obj + "  .video-container source").attr("src", srcVideoMobile);
            } else {
                // dekstop
                isMobileExp = false;
                if (!$(".experience-video-" + obj + " .mask").hasClass("hidden")) {
                    $(".experience-video-" + obj + " .mask").addClass("hidden")
                }
                $(".experience-video-" + obj + "  .video-container source").removeAttr("src");
                $(".experience-video-" + obj + "  .video-container source").attr("src", srcVideoDesktop);
            }
        }

        // var handler = onVisibilityChange($(".experience-video-presentazione"), function () {
        function handler(obj) {
            // try {
            //     mediaVideo.textTracks[0].mode = 'hidden';
            // } catch (err) {
            //     // console.log("Catch:", err);
            // }
            mediaVideo = $(".experience-video-" + obj + " .video-container")
                .find("video")
                .get(0);
            if (mediaVideo) {
                // // commentare
                // if (!mediaVideo.paused) {
                //     mediaVideo.pause();
                // }
                // try {
                //     mediaVideo.load();
                //     mediaVideo.play();
                // } catch (e) {
                //     console.log("catch:", e);
                // } //
                // mediaVideo.textTracks[0].mode = 'showing';
                if (!mediaVideo.muted) {
                    ($(".experience-video-" + obj).find(".toggle-volume input")).prop( "checked", true );
                } else {
                    ($(".experience-video-" + obj).find(".toggle-volume input")).prop( "checked", false );
                }
            }
        };

        // al resize della pagina o rotate video
        $(window).on("resize", function (e) {
            if ($(window).width() != windowWidth) {
                windowWidth = $(window).width();
                if ($(".experience-video-presentazione").is(":visible")) {
                    viewportScreen("presentazione");
                    handler("presentazione");
                } else if ($(".experience-video-agenzia").is(":visible")) {
                    viewportScreen("agenzia");
                    handler("agenzia");
                } else if ($(".experience-video-appuntamento").is(":visible")) {
                    viewportScreen("appuntamento");
                    handler("appuntamento");
                }
            }
        });

        // caricamento pagina
        $(".experience-video-presentazione, .experience-video-agenzia, .experience-video-appuntamento").ready(function (e) {
            if (!$(this).hasClass("animated")) {
                if ($(".experience-video-presentazione").is(":visible")) {
                    viewportScreen("presentazione");
                    handler("presentazione");
                } else if ($(".experience-video-agenzia").is(":visible")) {
                    viewportScreen("agenzia");
                    handler("agenzia");
                } else if ($(".experience-video-appuntamento").is(":visible")) {
                    viewportScreen("appuntamento");
                    handler("appuntamento");
                }
            }
        });

        function setFinePresentazione() {

            $(".experience-video-presentazione .mask").removeClass("hidden");
            $(".experience-video-presentazione .video-container source").removeAttr("src");
            $(".experience-video-presentazione .video-container source").attr("src", primoVideo);
            $(".experience-video-presentazione .button-container").removeClass("hidden");

            mediaVideo = $(".experience-video-presentazione .video-container")
                .find("video")
                .get(0);
            mediaVideo.load();
        }

        $('.experience-video-presentazione video').on('ended', function () {
            if (videoPresentazione) {
                $(".experience-video-presentazione .button-container").removeClass("hidden");
                $(".experience-video-presentazione .mask").removeClass("hidden");
            } else {
                if (selezioneTab == "button-agenzia") {
                    // proseguiAppuntamentoAgenzia();
                    try {
                        Experience.proseguiAppuntamentoAgenzia();
                    } catch (err) {
                        console.log("Catch: ", err);
                    }
                } else if (selezioneTab == "button-lavoro") {
                    // proseguiTrovaLavoro();
                    try {
                        Experience.proseguiTrovaLavoro();
                    } catch (err) {
                        console.log("Catch: ", err);
                    }
                }
                setFinePresentazione();
            }
        });

        $(".experience-video-presentazione #button-info").on("click", function () {
            $(".experience-video-presentazione .video-presentazione").removeClass("hidden");
            $(".experience-video-presentazione .video-container").addClass("hidden");
            mediaVideo = $(".experience-video-presentazione .video-presentazione").find("video").get(0);

            var originalSrc = mediaVideo.getAttribute("src");
            if(!originalSrc || originalSrc == null){
              var dataSrc = mediaVideo.getAttribute("data-src");
              mediaVideo.setAttribute("src", dataSrc);
            }

            let fullscreen = mediaVideo.webkitRequestFullscreen || mediaVideo.mozRequestFullScreen || mediaVideo.msRequestFullscreen || mediaVideo.webkitEnterFullScreen;
            fullscreen.call(mediaVideo);
            mediaVideo.play();
        });

        $(".experience-video-presentazione .toggle-volume input, .experience-video-appuntamento .toggle-volume input, .experience-video-agenzia .toggle-volume input").on("click", function () {
            mediaVideo = ($(this).closest("section")).find(".video-container")
                .find("video")
                .get(0);
            mediaVideo.muted = !mediaVideo.muted;
        });

        // quando clicco la X del chiudi sull'header, stop de video
        ($('.experience-video-presentazione').prev("header").find("a.action-menu-trigger--mobile")).on("click", function () {
            mediaVideo = $(".experience-video-presentazione .video-container")
                .find("video")
                .get(0);
            mediaVideo.pause();
        });
        ($('.experience-video-agenzia').prev("header").find("a.action-menu-trigger--mobile")).on("click", function () {
            mediaVideo = $(".experience-video-agenzia .video-container")
                .find("video")
                .get(0);
            mediaVideo.pause();
        });
        ($('.experience-video-appuntamento').prev("header").find("a.action-menu-trigger--mobile")).on("click", function () {
            mediaVideo = $(".experience-video-appuntamento .video-container")
                .find("video")
                .get(0);
            mediaVideo.pause();
        });

        // cambio video a selezione bottone agenzia / trova lavoro
        $(".experience-video-presentazione #button-agenzia, .experience-video-presentazione #button-lavoro").on("click", function () {
            if ($(this).attr("data-srcvideo") && $(this).attr("data-srcmobile")) {
                primoVideo = $(".experience-video-presentazione .video-container source").attr("src");
                videoPresentazione = false;
                selezioneTab = $(this).attr("id");
                if (selezioneTab.indexOf("lavoro") > 0) {
                    $(".experience-video-presentazione .mask").removeClass("hidden");
                    $(".experience-video-presentazione .video-container source").removeAttr("src");
                    // $(".experience-video-presentazione .video-container track").removeAttr("src");
                    // $(".experience-video-presentazione .video-container track").attr("src", $(this).attr("data-subtitle"));
                    if (isMobileExp) {
                        $(".experience-video-presentazione .video-container source").attr("src", $(this).attr("data-srcmobile"));
                    } else {
                        $(".experience-video-presentazione .video-container source").attr("src", $(this).attr("data-srcvideo"));
                    }
                    mediaVideo = $(".experience-video-presentazione .video-container")
                        .find("video")
                        .get(0);
                    mediaVideo.load();
                    $(".experience-video-presentazione .button-container").addClass("hidden");
                    $(".experience-video-presentazione .mask").addClass("hidden");
                    // $(".experience-video-presentazione .video-container video").prop('muted', false);
                    mediaVideo.play();
                }
            }
        });

        $('.experience-video-presentazione .video-presentazione video').on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function (e) {
            var isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
            if (!isFullScreen) {
                mediaVideo = $(".experience-video-presentazione .video-presentazione")
                    .find("video")
                    .get(0);
                mediaVideo.pause();
                $(".experience-video-presentazione .video-presentazione").addClass("hidden");
                $(".experience-video-presentazione .video-container").removeClass("hidden");
            }
        });
        // end video scelta experience
    }

    window.alleanza.experienceVideo = experienceVideo;
})();
  // End Module: experience

