// Start Module: Image
(function () {
    let image = window.alleanza.image || {};

    image.init = () => {
        Array.from(document.querySelectorAll('[data-bg-img-url]')).forEach(e => {
            e.style.backgroundImage = "url('" + e.dataset.bgImgUrl + "')";
        });
    };

    window.alleanza.image = image;
})();
// End Module: Image