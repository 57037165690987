// Start Module: Header
(function () {
  let header = window.alleanza.header || {};

  let htmlAndBody = document.querySelector("html, body");
  let headerContent = document.querySelector("header .header--content");

  let navPrivateTrigger = document.querySelector("#navPrivateTrigger");
  // let navBusinessTrigger = document.querySelector("#navBusinessTrigger");
  let navPrivate; //, navBusiness;
  let menuTriggers = document.querySelectorAll("header .menu-trigger");
  let megamenuDesktop = document.querySelector("#megamenuDesktop");

  let navPrivateMobileTrigger = document.querySelector(
    "#navPrivateMobileTrigger"
  );
  // let navBusinessMobileTrigger = document.querySelector("#navBusinessMobileTrigger");
  let navPrivateMobile; //, navBusinessMobile;
  let menuTriggerMobile = document.querySelector("#menuTriggerMobile");
  let megamenuMobile = document.querySelector("#megamenuMobile");
  let megamenuMobileOverlay = document.querySelector(
    ".megamenu-mobile--overlay"
  );
  let megamenuMobileNav = megamenuMobile
    ? megamenuMobile.querySelector(".megamenu-mobile--nav")
    : undefined;
  let megamenuMobileContainer = megamenuMobile
    ? megamenuMobile.querySelector(".megamenu-mobile--container")
    : undefined;
  let megamenuMobileTriggers = megamenuMobileNav
    ? megamenuMobileNav.querySelectorAll(".menu-trigger-mobile")
    : undefined;
  let megamenuMobileBack = megamenuMobileContainer
    ? megamenuMobileContainer.querySelector(".megamenu-mobile--title")
    : undefined;
  let megamenuMobileBackTitle = megamenuMobileBack
    ? megamenuMobileBack.querySelector(".menu-title")
    : undefined;

  const headerElement = document.querySelector("header");

  header.init = () => {
    //Header - Capitalize
    if ($(".action-login").length) {
      var str = $(".label-login").text().split(" ");
      var strVal = [];
      var strFirst = [];
      str.forEach(function (a) {
        strVal.push(a.slice(1));
        strFirst.push(a.slice(0, 1));
      });

      strVal[1] = strVal[1].toLowerCase();
      strVal[0] = strVal[0].toLowerCase();
      strFirst[0] = strFirst[0].toUpperCase();
      strFirst[1] = strFirst[1].toUpperCase();

      $(".label-login").text(
        " " + strFirst[0] + strVal[0] + " " + strFirst[1] + strVal[1]
      );
    }

    if (navPrivateTrigger) {
      if (navPrivateTrigger.dataset.target)
        navPrivate = document.querySelector(navPrivateTrigger.dataset.target);

      navPrivateTrigger.addEventListener("click", (event) => {
        navPrivateTrigger.classList.add("active");
        // if (navBusinessTrigger) navBusinessTrigger.classList.remove("active");

        if (navPrivate) navPrivate.classList.remove("hidden");
        // if (navBusiness) navBusiness.classList.add("hidden");

        if ($("#megamenuDesktop").hasClass("menu-open")) {
          $("#megamenuDesktop").removeClass("menu-open");
          $(".menu-trigger").removeClass("active");
          $(".header--content").removeClass("menu-open");
        }
      });
    }

    Array.from(menuTriggers).forEach((menuTrigger) => {
      menuTrigger.addEventListener("click", (event) => {
        let menu = menuTrigger.dataset.target
          ? document.querySelector(menuTrigger.dataset.target)
          : undefined;

        if (menuTrigger.classList.contains("active")) {
          // Hide this menu
          menuTrigger.classList.remove("active");
          if (megamenuDesktop) megamenuDesktop.classList.remove("menu-open");
          if (menu) menu.classList.add("hidden");
          if (headerContent) headerContent.classList.remove("menu-open");
          htmlAndBody.classList.remove("menu-open");
          htmlAndBody.classList.remove("overflow-hidden");
        } else {
          // Hide current menu
          Array.from(menuTriggers).forEach((trigger) => {
            let menu = trigger.dataset.target
              ? document.querySelector(trigger.dataset.target)
              : undefined;

            trigger.classList.remove("active");
            if (menu) menu.classList.add("hidden");
          });

          // Show this menu
          menuTrigger.classList.add("active");
          if (megamenuDesktop) megamenuDesktop.classList.add("menu-open");
          if (menu) menu.classList.remove("hidden");
          if (headerContent) headerContent.classList.add("menu-open");
          htmlAndBody.classList.add("menu-open");
          htmlAndBody.classList.add("overflow-hidden");
        }
      });
    });

    if (navPrivateMobileTrigger) {
      if (navPrivateMobileTrigger.dataset.target)
        navPrivateMobile = document.querySelector(
          navPrivateMobileTrigger.dataset.target
        );

      navPrivateMobileTrigger.addEventListener("click", (event) => {
        navPrivateMobileTrigger.classList.add("active");
        // if (navBusinessMobileTrigger) navBusinessMobileTrigger.classList.remove("active");

        if (navPrivateMobile) navPrivateMobile.classList.remove("hidden");
        // if (navBusinessMobile) navBusinessMobile.classList.add("hidden");
      });
    }

    if (menuTriggerMobile) {
      menuTriggerMobile.addEventListener("click", (event) => {
        if (menuTriggerMobile.classList.contains("active")) {
          menuTriggerMobile.classList.remove("active");
          if (headerContent) headerContent.classList.remove("menu-open");
          if (megamenuMobile) megamenuMobile.classList.remove("menu-open");
          htmlAndBody.classList.remove("menu-open");
          htmlAndBody.classList.remove("overflow-hidden");
          megamenuMobileOverlay.classList.add("hidden");
        } else {
          menuTriggerMobile.classList.add("active");
          if (headerContent) headerContent.classList.add("menu-open");
          if (megamenuMobile) megamenuMobile.classList.add("menu-open");
          htmlAndBody.classList.add("menu-open");
          htmlAndBody.classList.add("overflow-hidden");
          megamenuMobileOverlay.classList.remove("hidden");
        }
      });
    }

    // fix AIT-673 - rimaneva l'overlay in pagina
    $(
      ".megamenu-mobile--menu a, .megamenu-mobile--nav--section.block-item a, .megamenu-mobile .alleanza-button"
    ).on("click", function () {
      menuTriggerMobile.classList.remove("active");
      if (headerContent) headerContent.classList.remove("menu-open");
      if (megamenuMobile) megamenuMobile.classList.remove("menu-open");
      htmlAndBody.classList.remove("menu-open");
      htmlAndBody.classList.remove("overflow-hidden");
      megamenuMobileOverlay.classList.add("hidden");
    });

    if (megamenuMobileTriggers) {
      Array.from(megamenuMobileTriggers).forEach((megamenuMobileTrigger) => {
        megamenuMobileTrigger.addEventListener("click", (event) => {
          // Hide current menu
          Array.from(megamenuMobileTriggers).forEach((trigger) => {
            let menu = trigger.dataset.target
              ? document.querySelector(trigger.dataset.target)
              : undefined;

            if (menu) menu.classList.add("hidden");
          });

          // Show this menu
          let menu = megamenuMobileTrigger.dataset.target
            ? document.querySelector(megamenuMobileTrigger.dataset.target)
            : undefined;

          if (megamenuMobileBackTitle)
            megamenuMobileBackTitle.innerHTML = megamenuMobileTrigger.title
              .length
              ? megamenuMobileTrigger.title
              : "Back";
          if (menu) menu.classList.remove("hidden");
          if (megamenuMobileContainer)
            megamenuMobileContainer.classList.remove("hidden");
          if (megamenuMobileNav) megamenuMobileNav.classList.add("hidden");
        });
      });
    }

    if (megamenuMobileBack) {
      megamenuMobileBack.addEventListener("click", (event) => {
        if (megamenuMobileContainer)
          megamenuMobileContainer.classList.add("hidden");
        if (megamenuMobileNav) megamenuMobileNav.classList.remove("hidden");
      });
    }
    // Close megamenu when click on overlay
    $(".megamenu-desktop--overlay").on("click", function () {
      if ($("#megamenuDesktop").hasClass("menu-open")) {
        $("#megamenuDesktop").removeClass("menu-open");
        $(".menu-trigger").removeClass("active");
        $(".header--content").removeClass("menu-open");
        $("html").removeClass("overflow-hidden");
      }
    });

    // START .cookies-banner fadeOut
    if ($(".cookies-banner").length) {
      // fadeOut when scroll
      $(window).on("scroll", function () {
        if ($(this).scrollTop() > 200) {
          $(".cookies-banner").fadeOut(250);
        }
      });
      // fadeOut when click
      $(".cookies-banner")
        .find(".close-banner")
        .on("click", function () {
          $(this).closest(".cookies-banner").fadeOut(250);
        });
      $(".cookies-banner")
        .find(".agree a")
        .on("click", function () {
          $(this).closest(".cookies-banner").fadeOut(250);
        });
    }
    // END .cookies-banner fadeOut

    header.setMenuPosition();
  };

  header.setMenuPosition = () => {
    if (!headerElement) return;
    const megaMenuDesktopElement = document.querySelector(".megamenu-desktop");
    const megaMenuMobileElement = document.querySelector(".megamenu-mobile");
    if (!megaMenuDesktopElement && !megaMenuMobileElement) return;
    const megaMenuDesktopOverlayElement = document.querySelector(
      ".megamenu-desktop--overlay"
    );
    const megaMenuMobileOverlayElement = document.querySelector(
      ".megamenu-mobile--overlay"
    );
    const headerBottomCoordinate = headerElement.getBoundingClientRect().bottom;
    if (megaMenuDesktopElement) {
      megaMenuDesktopElement.style.top = headerBottomCoordinate + "px";
      megaMenuDesktopOverlayElement.style.top = headerBottomCoordinate + "px";
    }
    if (megaMenuMobileElement) {
      megaMenuMobileElement.style.top = headerBottomCoordinate + "px";
      megaMenuMobileOverlayElement.style.top = headerBottomCoordinate + "px";
    }
  };

  // header.smartBannerCalcHeight = () => {
  //   // // Calculate height of info-banner and header for megamenu 'top'
  //   // // let headerHeight = $("header").outerHeight();
  //   // // let infoBannerHeight = $(".info-banner").outerHeight() || 0;
  //   // // let smartBannerTotHeight = infoBannerHeight + headerHeight;
  //   // if ($(".megamenu-desktop").length || $(".megamenu-mobile").length) {
  //   //   $(".megamenu-desktop").css("top", header.getMenuPosition());
  //   //   $(".megamenu-mobile").css("top", header.getMenuPosition());
  //   //   $(".megamenu-mobile--overlay").css("top", header.getMenuPosition());
  //   //   let windowOffsetPosition = 0;
  //   //   window.addEventListener("scroll", function () {
  //   //     let headerHeight = $("header").outerHeight();
  //   //     let infoBannerHeight = $(".info-banner").outerHeight() || 0;
  //   //     let smartBannerTotHeight = infoBannerHeight + headerHeight;
  //   //     let windowOffsetPosition = window.pageYOffset;
  //   //     $(".megamenu-mobile").css(
  //   //       "top",
  //   //       smartBannerTotHeight - windowOffsetPosition
  //   //     );
  //   //     $(".megamenu-mobile--overlay").css(
  //   //       "top",
  //   //       smartBannerTotHeight - windowOffsetPosition
  //   //     );
  //   //   });
  //   // Close info-banner
  //   //   $(".info-banner a.link-close").on("click", function (e) {
  //   //     e.preventDefault();
  //   //     let that = $(this).closest(".info-banner");
  //   //     if (that.hasClass("slidedown")) {
  //   //       that.removeClass("slidedown");
  //   //       that.addClass("slideup");
  //   //     }
  //   //     let headerHeight = $("header").outerHeight();
  //   //     let smartBannerTotHeight = headerHeight;
  //   //     $(".megamenu-desktop").css("top", smartBannerTotHeight);
  //   //     $(".megamenu-mobile").css(
  //   //       "top",
  //   //       smartBannerTotHeight - window.pageYOffset
  //   //     );
  //   //     $(".megamenu-mobile--overlay").css(
  //   //       "top",
  //   //       smartBannerTotHeight - window.pageYOffset
  //   //     );
  //   //     // });
  //   //   });
  //   //   // END - Close info-banner
  //   // }
  //   // // END - Calculate height of info-banner and header for megamenu 'top'
  // };

  header.closeInfoBanner = (event) => {
    event.preventDefault();
    const infoBannerElement = event.target.closest(".info-banner");
    if (infoBannerElement.classList.contains("slidedown")) {
      infoBannerElement.classList.remove("slidedown");
      infoBannerElement.classList.add("slideup");
    }
    header.setMenuPosition();
  };

  const infoBannerElement = document.querySelector(".info-banner");
  if (infoBannerElement) {
    infoBannerElement.addEventListener("transitionend", (event) => {
      if (event.target.classList.contains("slideup")) {
        header.setMenuPosition();
      }
    });
  }

  let infoBannerCloseElement;
  if (infoBannerElement) {
    infoBannerCloseElement = infoBannerElement.querySelector(".link-close");
  }
  if (infoBannerCloseElement) {
    infoBannerCloseElement.addEventListener("click", header.closeInfoBanner);
  }

  window.addEventListener("resize", header.setMenuPosition);
  window.addEventListener("scroll", header.setMenuPosition);

  const appLaunchBanner = document.querySelector("app-launch-banner");

  if (appLaunchBanner) {
    appLaunchBanner.addEventListener("dismissed", handleBannerDismiss);
    customElements.whenDefined("app-launch-banner").then(() => {
      appLaunchBanner.componentOnReady().then(() => {
        header.setMenuPosition();
      });
    });
  }

  function handleBannerDismiss(event) {
    if (event.target.hasAttribute("open")) {
      setTimeout(() => {
        handleBannerDismiss(event);
      }, 1);
    } else {
      header.setMenuPosition();
    }
  }

  window.alleanza.header = header;
})();
// End Module: Header
