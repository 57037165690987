(function () {
    // Start Carousel instant win
    let carouselInstantWin = window.alleanza.carouselInstantWin || {};
    carouselInstantWin.init = () => {
        $('.carousel-instantwin').hide();
        if ($(".carousel-instantwin .swiper-container").length) {
            const swiperInstantWin = new Swiper(".carousel-instantwin .swiper-container", {
                centeredSlides: true,
                slidesPerView: 1,
                spaceBetween: 24,
                updateOnWindowResize: true,
                observer: true,
                observeParents: true,
                speed: 1200,
                allowTouchMove: false,
                breakpoints: {
                    1440: {
                        slidesPerView: 1.4,
                    },
                    990: {
                        slidesPerView: 1.2,
                    },
                    768: {
                        slidesPerView: 1.3,
                        spaceBetween: 12,
                    },
                    375: {
                        slidesPerView: 1,
                        spaceBetween: 12,
                        centeredSlides: true,
                    }
                },
            });
            $(".carousel-instantwin .carousel-instantwin-card1 a").on("click", function(){
                swiperInstantWin.slideNext();
                if(swiperInstantWin.activeIndex > 0 && $(".carousel-instantwin a.link").hasClass("hidden")){
                    $(".carousel-instantwin a.link").removeClass("hidden");
                }
            });
            $(".carousel-instantwin a.link").on("click", function(){
                swiperInstantWin.slidePrev();
                if(swiperInstantWin.activeIndex == 0){
                    $(this).addClass("hidden");
                }
            });
        }
        $('.carousel-instantwin').show();
    };
    // End carousel instant win

    window.alleanza.carouselInstantWin = carouselInstantWin;
})();
