(function () {
    let archivioProd = window.alleanza.archivioProd || {};

    archivioProd.init = () => {
        if ($(".archivio-prodotti").length) {
            let hasName, hasDate = false;
            $(".date-big .alleanza-select-container").addClass("disabled");
            $(".name .alleanza-select-container a").not(".date-mobile").on("click", function (e) {
                hasName = true;
                $(".date-big .alleanza-select-container").removeClass("disabled");
                if (hasName && hasDate) {
                    $(".archivio-prodotti-empty").addClass('hidden');
                    $(".archivio-prodotti-cards").removeClass('hidden');
                }
            });
            $(".date-big .alleanza-select-container a").on("click", function (e) {
                hasDate = true;
                if (hasName && hasDate) {
                    $(".archivio-prodotti-empty").addClass('hidden');
                    $(".archivio-prodotti-cards").removeClass('hidden');
                }
            });
        }
    }

    window.alleanza.archivioProd = archivioProd;
})();