(function () {
  let preventivatoriPrev = window.alleanza.preventivatoriPrev || {};

  preventivatoriPrev.init = () => {
    if ($('.welfare-quote-step').length) {
      // id per percentuali
      let id_pane;
      let years = 0;
      let ids_percentuali = [];
      let isCustomized = false;
      let isCheckedPrivacy = false;
      

      let validationError1="Campo obbligatorio";
      let validationError2="Inserisci un valore valido";
      let validationError3=" Email non valida";
      let validationError4="Età minima 18 anni";
      let validationError5="Inserisci una data valida";
      let validationError6="Formato non valido";

      // bottone step 2+6 abilitato, 1+3 disabilitati
      $('.welfare-quote-step.previdenza-step-3 .alleanza-button').addClass("disabled");
      $('.welfare-quote-step.previdenza-step-1 .alleanza-button').addClass("disabled");

      // nascondo le dropdown alla scelta di tipo lavoratore autonomo/dipendente
      $(".welfare-quote-step.previdenza-step-1 .alleanza-select-container #numFondoAUTONOMO").closest(".alleanza-select-container").addClass("hidden");
      $(".welfare-quote-step.previdenza-step-1 .alleanza-select-container #numFondoDIPENDENTE").closest(".alleanza-select-container").addClass("hidden");
      $(".welfare-quote-step.previdenza-step-1 .fondo").addClass("hidden");

      $('.welfare-quote-step.previdenza-step-1 #data-check').on("change", function (e) {
        let value = checkDataNascita($(this).val(), false, true);
        checkFullInputs(1);
        if (value && $('.welfare-quote-step.previdenza-step-1 #anni-lavoro').val()) {
          checkAnniLavoro($('.welfare-quote-step.previdenza-step-1 #anni-lavoro').val(), false, true);
        }
      });

      $('.welfare-quote-step.pip-quote .input-container input').on("focusout", function(e){
        e.preventDefault();
        abilitaButtonStep1();
        if($(this).val()==""){
          $(this).parents(".alleanza-input-container").find(".alert-pip .text-alert-pip").html(validationError1);
          $(this).parents(".alleanza-input-container").find(".alert-pip").addClass("alert-pip-hidden");
          $(this).parents(".alleanza-input-container").find(".input-container").removeClass("error");
        }
      })

      $(document).on("click", function (e) {
        $('.welfare-quote-step.pip-quote .selected-container.strong-underline').each(function(e){
          $(this).removeClass("close-arrow").addClass("open-arrow");
          if ($(this).parents(".alleanza-select-container").find(".alleanza-select-dropdown").hasClass("alleanza-select-dropdown--hidden")) {
            $(this).removeClass("open-arrow").addClass("close-arrow");
          }
          // setTimeout(() => {
          //   if (elem.parents(".alleanza-select-container").find(".alleanza-select-dropdown").hasClass("alleanza-select-dropdown--hidden")) {
          //     elem.removeClass("open-arrow").addClass("close-arrow");
          //   }
          // }, 50);
        });
        $('.welfare-quote-step.pip-quote .selected-container:not(.strong-underline)').each(function(e){
          $(this).removeClass("open-arrow").addClass("close-arrow");
        });
      });

      $('.welfare-quote-step.pip-quote #data-check').on("change", function (e) {
        let value = checkDataNascita($(this).val(), true, true);
        if (value && $('.welfare-quote-step.pip-quote #anni-lavoro').val()) {
          checkAnniLavoroPip();
        }
      });

      // mostro le dropdown per scelta lavoratore dipendente/autonomo
      ($(".welfare-quote-step.previdenza-step-1 .alleanza-select-container input#tipo-lavoratore").closest(".alleanza-select-container").find(".dropdown-item")).on("click", function () {
        if ($(".welfare-quote-step.previdenza-step-1 .fondo").hasClass("hidden")) {
          $(".welfare-quote-step.previdenza-step-1 .fondo").removeClass("hidden");
        }
        if (this.id.includes("indipendente")) {
          $(".welfare-quote-step.previdenza-step-1 .alleanza-select-container #numFondoAUTONOMO").closest(".alleanza-select-container").removeClass("hidden");
          if (!$(".welfare-quote-step.previdenza-step-1 .alleanza-select-container #numFondoDIPENDENTE").closest(".alleanza-select-container").hasClass("hidden")) {
            $(".welfare-quote-step.previdenza-step-1 .alleanza-select-container #numFondoDIPENDENTE").closest(".alleanza-select-container").addClass("hidden");
          }
        } else {
          $(".welfare-quote-step.previdenza-step-1 .alleanza-select-container #numFondoDIPENDENTE").closest(".alleanza-select-container").removeClass("hidden");
          if (!$(".welfare-quote-step.previdenza-step-1 .alleanza-select-container #numFondoAUTONOMO").closest(".alleanza-select-container").hasClass("hidden")) {
            $(".welfare-quote-step.previdenza-step-1 .alleanza-select-container #numFondoAUTONOMO").closest(".alleanza-select-container").addClass("hidden");
          }
        }
        setPlaceholderWidth();
      });

      //Enable button when all input are filled

      $(".welfare-quote-step.pip-quote .alleanza-input input").on("keyup", function(){
        if ($(this).attr("required")==="required"){
          abilitaButtonStep1();
        }
        if ($(this).val() != "") {
          $(this).parents(".alleanza-input.alleanza-input--underline").find(".alert-pip").addClass("alert-pip-hidden");
          $(this).parents(".alleanza-input.alleanza-input--underline .input-container").removeClass("error");
        }
      });

      $(".welfare-quote-step.pip-quote #anni-lavoro").on("focusout", function(){
        if ($('.welfare-quote-step.pip-quote #anni-lavoro').val()) {
          checkAnniLavoroPip();
        }
      });

      $(".welfare-quote-step.pip-quote .alleanza-select-container a.dropdown-item").on("click", function(){

        abilitaButtonStep1();
         $(this).parents(".alleanza-select-container").find(".alleanza-select--underline .selected-container").removeClass("error");
         $(this).parents(".alleanza-select-container").find(".wrong-info").css("display", "none");
         $(this).parents(".alleanza-select-container").find(".selected-container").removeClass("open-arrow").addClass("close-arrow");
      });

      function abilitaButtonStep1() {
        var abilitaButton = true;
        $(".welfare-quote-step.pip-quote .alleanza-input input, .welfare-quote-step.pip-quote .alleanza-select input").each(function(e){
           var element = $(this);
            if (element.attr("required") === "required" && element.val().length == 0) {
            abilitaButton = false;
           }          
           else if (element.val().length > 0 && $(this).attr("id") == "anni-lavoro" && !checkAnniLavoro($(this).val(), true, false)) {
            abilitaButton = false;
           } else if (element.val().length > 0 && $(this).attr("id") == "mail" && element.attr("required") === "required" && !checkEmail($(this), true, false)) {
            abilitaButton = false;
           } else if (element.val().length > 0 && $(this).attr("id") == "data-check" && !checkDataNascita($(this).val(), true, false)) {
            abilitaButton = false;
           }
        });
        if (abilitaButton) {
          $(".welfare-quote-step.pip-quote .btn-alleanza").addClass("btn-alleanza-enabled");
        } else {
          $(".welfare-quote-step.pip-quote .btn-alleanza").removeClass("btn-alleanza-enabled");
        }
      }

      $(".welfare-quote-step.pip-quote .btn-alleanza:not(.btn-alleanza-enabled)").on("click", function(){
        $(".welfare-quote-step.pip-quote .alleanza-input input, .welfare-quote-step.pip-quote .alleanza-select input").each(function(e){
          if ($(this).val().length == 0 && $(this).attr("required")==="required") {
            $(this).parents(".alleanza-input.alleanza-input--underline").find(".alert-pip").removeClass("alert-pip-hidden");
            $(this).parents(".alleanza-input.alleanza-input--underline .input-container, .alleanza-select--underline .selected-container").addClass("error");
            $(this).parents(".alleanza-select-container").find(".wrong-info").css("display", "inline");
            $(this).parents(".alleanza-input-container").find(".wrong-info").css("display", "none");
            $(this).parents(".alleanza-input-container").find(".alert-pip .text-alert-pip").html(validationError1);
          } else if ($(this).attr("id")=="anni-lavoro"){
            checkAnniLavoro($(this).val(), true, true);
          }
       });
      });

      // START slash automatico data
      // bind keydown date
      $('.welfare-quote-step.previdenza-step-1 #data-check, .welfare-quote-step.pip-quote #data-check').on("keyup", function (event) {
        window.alleanza.util.addSlashDate(event);
      });
      $('.welfare-quote-step.pip-quote .alleanza-input input.numeric-text').on("keyup", function (event) {
        $(this).val(window.alleanza.util.numberWithCommas($(this).val()));
      });

      $('.welfare-quote-step.previdenza-step-1 #mail ').on("change", function (e) {
        checkEmail($(this), false, true);
      });

      $('.welfare-quote-step.pip-quote #mail ').on("change", function (e) {
        checkEmail($(this), true, true);
      });


      $('.welfare-quote-step.previdenza-step-1 #anni-lavoro').on("change", function (e) {
        checkAnniLavoro($(this).val(), false, true);
        checkFullInputs(1);
      });

      $('.pip-step-2 #etapensione').on("focusout", function (e) {
        checkAnniPensionamento($(this).val());
      });

      $('.pip-step-2 #form-pip-2').on("submit", function (e){
        e.preventDefault();
      });

      $(".pip-step-2").on("click focusout", function(){
        setTimeout(function() {
        checkAndActivateArrow();
        }, 5);
      })
    
      function checkAndActivateArrow(){
        $(".alleanza-select-dropdown").each(function(){
          var selectContainer = $(this).parents(".alleanza-select-container").find(".selected-container");
          if ($(this).hasClass("alleanza-select-dropdown--hidden")) {
            selectContainer.removeClass("arrow-up");
          } else {
            selectContainer.addClass("arrow-up");
          }
        });
      }

      // select valorizzate
      $(".welfare-quote-step.previdenza-step-1 .alleanza-select-container .alleanza-select-dropdown a.dropdown-item").click("click", function (e) {
        checkFullInputs(1);
      });

      // input valorizzati
      ($(".welfare-quote-step.previdenza-step-1 .checkbox-privacy input, .welfare-quote-step.previdenza-step-1 .alleanza-input-container input").not("#data-check, #mail, #anni-lavoro")).on("change", function (index) {
        if ($(this).attr("id") == "standardCheckbox") {
          isCheckedPrivacy = $(this).prop("checked");
        }
        checkFullInputs(1);
      });

      ($('.welfare-quote-step.previdenza-step-1 .alleanza-input input.numeric-text').not("#anni-lavoro")).keyup(function (e) {
        $(this).val(window.alleanza.util.numberWithCommas($(this).val()));
        window.alleanza.util.underlinedAutoWidth();
        checkFullInputs(1);
      });

      ($('.welfare-quote-step.previdenza-step-3 .alleanza-input input.numeric-text').not("#anni-lavoro")).keyup(function (e) {
        $(this).val(window.alleanza.util.numberWithCommas($(this).val()));
        window.alleanza.util.underlinedAutoWidth();
        checkFullInputs(3);
      });

      // select valorizzate step 3
      $(".welfare-quote-step.previdenza-step-3 .alleanza-select-container .alleanza-select-dropdown a.dropdown-item").on("click", function (e) {
        checkFullInputs(3);
      });

      // input valorizzati step 3
      $(".welfare-quote-step.previdenza-step-3 .alleanza-input-container input").on("change", function (index) {
        checkFullInputs(3);
      });

      // al click del "modifica dati" all'ultimo step, disabilito il button allo step 3
      $(".welfare-quote-step.previdenza-step-6 a.link.right-text").on("click", function () {
        $('.welfare-quote-step.previdenza-step-3 .alleanza-button').addClass('disabled');
      });

      //da Step4 -> Step5
      $(".welfare-quote-step.previdenza-step-3 .group-risk .alleanza-select-container .dropdown-item").on("click", function (e) {
        if ($(this).hasClass("customized") && !isCustomized) {
          isCustomized = true;
          $(".group-customized").removeClass('hidden');
          ids_percentuali = [];
          checkPercent(id_pane);
          // abilitaButton("3");
        } else if (!$(this).hasClass("customized")) {
          if (ids_percentuali.length > 0 && isCustomized) {
            isCustomized = false;
            ids_percentuali = [];
          }
          $(".group-customized").addClass('hidden');
          // abilitaButton("3");
        }
        checkFullInputs(3);
      });

      //Previdenza Step 3 -> 4
      $(".previdenza-step-3 a.card-radio-link").on("click", function (event) {
        $(this).addClass('active');
        id_pane = $(this).attr('href').slice(1);
        $(".card-radio-link").removeClass('active');
        $(".card-radio-link").removeClass('previdenza-step-4');
        setTimeout(window.alleanza.util.underlinedAutoWidth(), 100);
      });

      $(".previdenza-step-3 .group-customized :input").on("change", function (e) {
        // let index = ids_percentuali.indexOf(this.id);
        let index = ids_percentuali.findIndex(x => x.id === this.id);
        var counter = 0;
        var sum = 0;
        if (!isNaN(Number(this.value))) {
          ids_percentuali[index].value = Number(this.value);
        }
        for (var i in ids_percentuali) {
          if (ids_percentuali[i].value == 0) {
            counter++;
          } else {
            sum += ids_percentuali[i].value;
          }
        }
        if (counter == 1) {
          $("#" + id_pane + " #" + (ids_percentuali[ids_percentuali.findIndex(x => x.value === 0)]).id).val(100 - sum);
        }
        checkFullInputs(3);
      });

      // Resize underline 
      $(window).on("resize", function () {
        window.alleanza.util.underlinedAutoWidth();
      });

      $('.alleanza-input  input[type="text"]').each(function (e) {
        window.alleanza.util.underlinedAutoWidth();
      });

      function checkFullInputs(step) {
        let resp;
        if (step == 1) {
          resp = ($(".previdenza-step-1 input").not("#mail, #numFondoAUTONOMO, #numFondoDIPENDENTE, #promotionCheckbox, #standardCheckbox")).filter(function () {
            return $.trim($(this).val()).length == 0
          }).length == 0;
          let fondo = [$(".previdenza-step-1 #numFondoAUTONOMO").val(), $(".previdenza-step-1 #numFondoDIPENDENTE").val()].some(function(e){ return e.length > 0;});
          resp = resp && fondo && isCheckedPrivacy;
        } else if (step == 3) {
          if ($(".previdenza-step-3 .group-customized").hasClass("hidden")) {
            resp = ($(".previdenza-step-3 #" + id_pane + " input").not(".group-customized input")).filter(function () {
              return $.trim($(this).val()).length == 0
            }).length == 0;
          } else {
            resp = ($(".previdenza-step-3 #" + id_pane + " input")).filter(function () {
              return $.trim($(this).val()).length == 0
            }).length == 0;
          }
        }
        abilitaButton(step, resp);
      }

      function checkPercent(id_tab) {
        $("#" + id_tab + " .group-customized .alleanza-input-container input").each(function (e) {
          if (ids_percentuali.findIndex(x => x.id === this.id) < 0) {
            ids_percentuali.push({ "id": this.id, "value": 0 });
          }
        });
      }

      //controllo email
      function checkEmail(element, isPip, applyVisualError) {

        let inputText = element.val();
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (inputText.match(mailformat)) {
          if (applyVisualError){
            if (isPip) {
              hideValidationPipErrors("#container-mail");
            } else {
              hideValidationErrors("#container-mail");
            }
          }
          return true;
        }
        else {
          if (applyVisualError){
            if (isPip) {
                showValidationPipErrors("#container-mail");
                $("#container-mail .alert-pip .text-alert-pip").html(validationError3);
            } else {
              showValidationErrors("#container-mail");
              email.val("");
              email.trigger("focus")
            }
            setPlaceholderWidth();
          }   
          return false;
        }
      }

      function abilitaButton(id, state) {
        id = id ? id : "1";
        if (id == "2" || id == "6") {
          return true;
        }
        if (id == "1" || id == "3") {
          if (!state) {
            $('.welfare-quote-step.previdenza-step-' + id + ' .alleanza-button').addClass('disabled');
            return false;
          } else {
            $('.welfare-quote-step.previdenza-step-' + id + ' .alleanza-button').removeClass("disabled");
            return true;
          }
        }
      }

      function setPlaceholderWidth() { //dopo l'errore underline placeholder
        window.alleanza.util.underlinedAutoWidth();
      }

      //controllo form anni lavoro (anno in corso- anno di nascita)-15
      function checkAnniLavoro(val, isPip, applyVisualError) {
        let lavoro = document.getElementById('anni-lavoro');
        if (years && years > 0 && val) {
          let today = new Date();
          let anno_nascita = today.getFullYear() - years;
          let min_anni = (today.getFullYear() - anno_nascita) - 15;
          if (val > min_anni) {
            if (applyVisualError){
              if (isPip) {
                $("#container-anni-lavoro .alert-pip .text-alert-pip").html(validationError2);
                showValidationPipErrors("#container-anni-lavoro");
              } else {
                showValidationErrors("#container-anni-lavoro");
                lavoro.value = "";
                lavoro.focus();
              }
              setPlaceholderWidth();
            }
            return false;
          } else {
            if (applyVisualError){
              if (isPip) {
                hideValidationPipErrors("#container-anni-lavoro");
              } else {
                hideValidationErrors("#container-anni-lavoro");
              }
              setPlaceholderWidth();
            }
            return true;
          }
        } else {
          return false;
        }
      }

      function checkAnniPensionamento(val) {
         if (val > 89 || val <67) {
          showValidationPipErrors("#container-etapensione");
        }
        else {
          hideValidationPipErrors("#container-etapensione");
        } 
      }


      function checkAnniLavoroPip(){
        let dt = $('.welfare-quote-step.pip-quote #data-check').val().split("/");
        if(dt[2] && dt[2].length==4){
          let birthday = new Date(dt[1] + "/" + dt[0] + "/" + dt[2]);
          let today = new Date();
          years = (today.getFullYear() - birthday.getFullYear());
          checkAnniLavoro($('.welfare-quote-step.pip-quote #anni-lavoro').val(), true, true);
        }
      }

      //controllo Form Data Nascita
      function checkDataNascita(val, isPip,applyVisualError) {
        let nascita = document.getElementById('data-check');
        let RE = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
        let dt = val.split("/");
        if (!RE.test(val)) {
          if(applyVisualError){
            if (isPip) {
              showValidationPipErrors("#container-data-check");
              $("#container-data-check .alert-pip .text-alert-pip").html(validationError6)
            } else {
              showValidationErrors("#container-data-check");
              nascita.value = "";
              nascita.focus();
            }
            setPlaceholderWidth();
          }
          return false;
        } else if (dt[0] > 31) {
          if(applyVisualError){
            if (isPip) {
              showValidationPipErrors("#container-data-check");
              $("#container-data-check .alert-pip .text-alert-pip").html(validationError6)
            } else {
              showValidationErrors("#container-data-check");
              nascita.value = "";
              nascita.focus();
            }
            $("#container-data-checerror");
            setPlaceholderWidth();
          }
          return false;
        } else if (dt[1] > 12) {
          if(applyVisualError){
            if (isPip) {
              showValidationPipErrors("#container-data-check");
              $("#container-data-check .alert-pip .text-alert-pip").html(validationError6)
            } else {
              showValidationErrors("#container-data-check");
              nascita.value = "";
              nascita.focus();
            }
            setPlaceholderWidth();
          }
          return false;
        }
        let birthday = new Date(dt[1] + "/" + dt[0] + "/" + dt[2]);
        let today = new Date();
        years = (today.getFullYear() - birthday.getFullYear());
        if (today.getMonth() < birthday.getMonth() ||
          today.getMonth() == birthday.getMonth() && today.getDate() < birthday.getDate()) {
          years--;
        }
        if (years < 18 || years > 110) {
          if(applyVisualError){
            if (isPip) {
              if (years < 18) {
                showValidationPipErrors("#container-data-check");
                $("#container-data-check .alert-pip .text-alert-pip").html(validationError4)
              } else {
                showValidationPipErrors("#container-data-check");
                $("#container-data-check .alert-pip .text-alert-pip").html(validationError5)
              }
            } else {
              showValidationErrors("#container-data-check");
              nascita.value = "";
              nascita.focus();
            }
            setPlaceholderWidth();
          }
          return false;
        }
        if(applyVisualError){
          if (isPip) {
            hideValidationPipErrors("#container-data-check");
          } else {
            hideValidationErrors("#container-data-check");
          }
        }
        return true;
      }

      function showValidationErrors(containerElementId) {
        $(containerElementId + " .wrong-info").css({ "display": "block" });
        $(containerElementId).addClass("validation-error");
      }

      function hideValidationErrors(containerElementId) {
        $(containerElementId + " .wrong-info").css({ "display": "none" });
        $(containerElementId).removeClass("validation-error");
      }

      function showValidationPipErrors(containerElementId) {
        $(containerElementId + " .alert-pip").removeClass("alert-pip-hidden");
        $(containerElementId + " .input-container").addClass("error");
      }

      function hideValidationPipErrors(containerElementId) {
        $(containerElementId + " .alert-pip").addClass("alert-pip-hidden");
        $(containerElementId + " .input-container").removeClass("error");
        $(containerElementId + " .alert-pip .text-alert-pip").html(validationError1);
      }

      // START form mail ultimo step      
      // checkbox form mail che mostrano i form corrispondenti
      $(".welfare-quote-step.preventivatore-form-mail .alleanza-radio input").on("change", function (e) {
        if ($(this).hasClass("isCliente")) {
          $(".welfare-quote-step.preventivatore-form-mail .box-cliente").removeClass("hidden");
        } else {
          if (!$(".welfare-quote-step.preventivatore-form-mail .box-cliente").hasClass("hidden")) {
            $(".welfare-quote-step.preventivatore-form-mail .box-cliente").addClass("hidden");
          }
        }
      });

      // abilitare il button del form email
      $(".welfare-quote-step.preventivatore-form-mail .alleanza-text-input input").on("change", function (e) {
        abilitaButtonFormMail();
      });

      $(".welfare-quote-step.preventivatore-form-mail .email .alleanza-text-input input").on("change", function (e) {
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!$(this).val().match(mailformat)) {
          $(this).val("");
          $(this).closest(".alleanza-text-input").find(".message").removeClass("hidden");
        } else {
          $(this).closest(".alleanza-text-input").find(".message").addClass("hidden");
        }
        abilitaButtonFormMail();
      });

      function abilitaButtonFormMail() {
        let counter = 0;
        $(".welfare-quote-step.preventivatore-form-mail .alleanza-text-input input").each(function (index) {
          if ($(this).val() != "") {
            counter++;
          }
        });
        if (counter == 3) {
          $('.welfare-quote-step.preventivatore-form-mail .alleanza-button').removeClass('disabled');
        } else {
          $('.welfare-quote-step.preventivatore-form-mail .alleanza-button').addClass('disabled');
        }
      }
      // END form mail ultimo step
    }
  }

  window.alleanza.preventivatoriPrev = preventivatoriPrev;
})();

