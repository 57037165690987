(function () {
$(".download-section.animated").each(function(){
    let tl_download_section = gsap.timeline({
        scrollTrigger:{
            trigger: this,
            markers:false,
            //toggleActions: "restart none none reset",
            start: "top 60%"
        }
    });
    let direction = direction | 1;
    var x = 0,
        y = direction * 200;
    tl_download_section.fromTo(this,
        { x: x, y: y, autoAlpha: 0 },
        {duration: 1.25,
            x: 0,
            y: 0,
            autoAlpha: 1,
            ease: "expo",
            overwrite: "auto",
        });
});
}());
