(function () {
  let hero = window.alleanza.hero || {};

  hero.init = () => {
    /* HERO CAROUSEL */
    $("#hero-carousel").carousel();
    /* END HERO CAROUSEL */

    // Call only underlinedAutoWidth() (not function selectInput() colled on ready in selectInputDefault.init()) for hero fast quote tabs/dropdown on ready
    $(".hero__nav-quotation .dropdown-item .nav-link").on("shown.bs.tab", function () {
      window.alleanza.util.underlinedAutoWidth();
    });
  };

  window.alleanza.hero = hero;
})();
