// Start Module: Object Fit Polyfill
(function() {
  let objectFitPolyfilll = window.alleanza.objectFitPolyfilll || {};

  objectFitPolyfilll.init = () => {
    $(function() {
      objectFitImages();
      //objectFitVideos();
      // objectFitImages("img.object-fit-item");
      // objectFitVideos("video.object-fit-item");
    });
  };

  window.alleanza.objectFitPolyfilll = objectFitPolyfilll;
})();
// End Module: Object Fit Polyfill
